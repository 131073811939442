import "./App.css";
import "./styles/font.scss";
import "./styles/width.scss";
import "./styles/contactus.scss";
import "./styles/colors.scss";
import "./styles/images.scss";
import "./styles/animation.scss";
import "./styles/chatbot.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/homePage.scss"
import { BrowserRouter } from "react-router-dom";
import { WebsiteRoutes } from "./routes/WebsiteRoutes";
import Helplinebutton from "./components/atoms/helplineButton";
import { useState } from "react";
import ChatbotComp from "./chatbot/Chatbot";

function App() {
  const [showChatbot, setshowChatbot] = useState(false)
 
  return (
    <>
      <Helplinebutton right="1%" bottom={"3%"} onChatClick={() => { setshowChatbot(true) }} />

      {showChatbot && <div className="chatbot-container">
        <ChatbotComp onClose={() => { setshowChatbot(false) }} />
      </div>}

      <BrowserRouter>
        <WebsiteRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
