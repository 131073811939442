const FONTS = {
      FS_8: "0.67rem",
      FS_9_5: "0.79rem",
      FS_10: "0.67rem",
      FS_12: "0.79rem",
      FS_13: "0.8125rem",
      FS_14: "0.83rem",
      FS_16: "1rem",
      FS_17: "1.0625rem",
      FS_18: "1.17rem",
      FS_20: "1.33rem",
      FS_22: "1.50rem",
      FS_24: "1.67rem",
      FS_26: "1.83rem",
      FS_28: "2.00rem",
      FS_30: "2.17rem",
      FS_35: "2.33rem",
      FS_40: "2.50rem",
      FS_45: "2.92rem",
      FS_50: "3.33rem",
      FS_55: "3.75rem",
      FS_60: "4.17rem",
      FS_68: "4.58rem",
      FS_75: "5.00rem"
    
  };
  
  export default FONTS;
  