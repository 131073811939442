import React from 'react'
import COLORS from '../../../../constants/colorConstants'

const {BLUE1} = COLORS
function Instgram() {
  return (
    <>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7237 1H6.25672C3.62847 1 1.49023 3.13823 1.49023 5.76649V12.2335C1.49023 14.8618 3.62847 17 6.25672 17H12.7237C15.352 17 17.4902 14.8618 17.4902 12.2335V5.76649C17.4902 3.13823 15.352 1 12.7237 1ZM15.8806 12.2335C15.8806 13.977 14.4672 15.3904 12.7237 15.3904H6.25672C4.51323 15.3904 3.09983 13.977 3.09983 12.2335V5.76649C3.09983 4.02297 4.51323 2.6096 6.25672 2.6096H12.7237C14.4672 2.6096 15.8806 4.02297 15.8806 5.76649V12.2335Z" fill={BLUE1}/>
<path d="M9.48973 4.86182C7.20794 4.86182 5.35156 6.7182 5.35156 8.99996C5.35156 11.2817 7.20794 13.1381 9.48973 13.1381C11.7715 13.1381 13.6279 11.2817 13.6279 8.99996C13.6279 6.71817 11.7715 4.86182 9.48973 4.86182ZM9.48973 11.5286C8.09323 11.5286 6.96116 10.3965 6.96116 8.99999C6.96116 7.60348 8.09326 6.47142 9.48973 6.47142C10.8862 6.47142 12.0183 7.60348 12.0183 8.99999C12.0183 10.3965 10.8862 11.5286 9.48973 11.5286Z" fill={BLUE1}/>
<path d="M13.6361 5.88449C14.1838 5.88449 14.6277 5.44054 14.6277 4.8929C14.6277 4.34526 14.1838 3.90131 13.6361 3.90131C13.0885 3.90131 12.6445 4.34526 12.6445 4.8929C12.6445 5.44054 13.0885 5.88449 13.6361 5.88449Z" fill={BLUE1}/>
</svg>

    </>
  )
}

export default Instgram