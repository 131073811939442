import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";

import ContentSection from "../../molecules/ContentSection";
import Slider from "react-slick";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import EastArrow from "../../../assests/svg/Common/EastArrow";
import WestArrow from "../../../assests/svg/Common/WestArrow";
import COLORS from "../../../constants/colorConstants";
import FONTS from "../../../constants/fontsConstants";
const {BLUE3, WHITE1, WHITE2} = COLORS
const {FS_20}=FONTS
function CarouselComp({
  children,
  title = "Case Study",
  badgeLabel = "RESEARCH",
  sxChip = { backgroundColor: BLUE3 },
  sxTitle = { fontSize: FS_20, color: WHITE1 },
  titleMuiProp = "text-white",
}) {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 1 : 2,
    slidesToScroll: 1,
  };

  return (
    <>
      <Grid container paddingY={5} className="d-flex justify-content-between">
        <Grid item md={4} xs={12} paddingX={5}>
          <ContentSection
            textAlign="left"
            sxTitle={sxTitle}
            badgeLabel={badgeLabel}
            badgeTextColor="text-info"
            sxChip={sxChip}
            title={title}
            titleMuiProp={titleMuiProp}
              chipMuiProps="content-chip1"
          />

          <Box display={"flex"}>
            <FarwarButton
              btnBgColor={WHITE2}
              icon={<WestArrow />}
              onBtnClick={previous}
              btnWidth="20%"
            />
            &nbsp;&nbsp;&nbsp;
            <FarwarButton
              btnBgColor= {WHITE2}
              icon={<EastArrow />}
              onBtnClick={next}
              btnWidth="20%"
            />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {children}
          </Slider>
        </Grid>
      </Grid>
    </>
  );
}

export default CarouselComp;
