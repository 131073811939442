import COLORS from "../../../../constants/colorConstants";
import ContentSection from "../../../molecules/ContentSection";
import ServiceCard from "../../../molecules/ServiceCard";
import { Grid } from "@mui/material";

const { WHITE1, BLUE2, PURPLE2} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const serviceImage1 =
  S3_URL+"/v3/assestsWebp/home/service1.webp";
const serviceImage2 =
  S3_URL+"/v3/assestsWebp/home/service2.webp";
const serviceImage3 =
  S3_URL+"/v3/assestsWebp/home/service3.webp";
const background =
  S3_URL+"/v3/assestsWebp/home/blueBackground.webp";
function HomePageServices() {
  const services = [
    {
      imageURL: serviceImage1,
      heading: "INNOVATE",
      title: "Managed Services",
      link: "managed-services",
    },
    {
      imageURL: serviceImage2,
      heading: "COLLABORATE",
      title: "Consulting Services",
      link: "consulting-services",
    },
    {
      imageURL: serviceImage3,
      heading: "ACCELERATE",
      title: "Learning Services",
      link: "learning-services",
    },
  ];
  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          paddingTop: {md:"15%", xs:"5%"},
          paddingBottom: "5%",
          marginTop: "3%",
        }}
      >
        <Grid item xs={1} md={2}></Grid>
        <Grid item container xs={10} md={8}>
          <ContentSection
            badgeLabel="SERVICES"
            title="Services We Offer"
            badgeBgColor="bg-white"
            badgeTextColor={BLUE2}
            sxChip={{ backgroundColor: WHITE1 }}
            chipMuiProps="content-chip2"
          >
            {services.map((service,index) => (
              <Grid item sm={4} xs={10} key={index}>
                <ServiceCard
                  link={`/services/${service.link}`}
                  imageURL={service.imageURL}
                  title={service.title}
                  heading={service.heading}
                  showIcon={true}
                  titleMuiProps="fw-700 Services-title-text"
                  headingMuiProps="fs-10 heading-color"
                  sxHeading={{ color: PURPLE2 }}
                    contentMuiprops="Services-Offer-content"
                />
              </Grid>
            ))}
          </ContentSection>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
}
export default HomePageServices;
