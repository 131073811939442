import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ConsultingImageSection from "../ConsultingImageSection";
import ConsultingContentSection from "../ConsultingContentSection";
const S3_URL = process.env.REACT_APP_S3_URL;
const oneTime =
  S3_URL+"/v3/assestsWebp/ConsultingServices/oneTime.webp";
const OperateTransfer =
  S3_URL+"/v3/assestsWebp/ConsultingServices/OperateTransfer.webp";
const contractStaffing =
  S3_URL+"/v3/assestsWebp/ConsultingServices/contractStaffing.webp";
const deployBill =
  S3_URL+"/v3/assestsWebp/ConsultingServices/deployBill.webp";
const hireStaffing =
  S3_URL+"/v3/assestsWebp/ConsultingServices/hireStaffing.webp";
const permanantHire =
  S3_URL+"/v3/assestsWebp/ConsultingServices/permanantHire.webp";

const ForecastBased =
  S3_URL+"/v3/assestsWebp/ConsultingServices/ForecastBased.webp";

function ConsultingServices() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  let cardData = [
    {
      image: permanantHire,
      heading: "Permanent Hire",
      description:
        "Our permanent hiring services ensure one-time placements. Our seasoned recruitment team comprehensively understands customer needs, actively sourcing and placing the right talent to fulfill specific requirements seamlessly.",
      id: "permanent-hire",
    },
    {
      image: contractStaffing,
      heading: "Contract Staffing ",
      description:
        "We excel in contract staffing, offering a dedicated account manager to source the right talent from our bench or the market, ensuring a seamless and tailored staffing solution for our clients.",
      id: "contract-staffing",
    },
    {
      image: hireStaffing,
      heading: "Contract to Hire Staffing",
      description:
        "Dyashin specializes in Contract-to-Hire Staffing, offering a dedicated account manager, access to our bench, and seamless transition to the client's payroll system after the contract period.",
      id: "contract-to-hire-staffing",
    },
    {
      image: OperateTransfer,
      heading: "Build Operate Transfer",
      description:
        "We facilitate the 'Build, Operate & Transfer' model, assisting clients in team development from juniors to managers, operating seamlessly, and eventually transferring the entire team to the client's payroll.",
      id: "build-operate-transfer",
    },
    {
      image: ForecastBased,
      heading: "Forecast Based Hire",
      description:
        "For recurring resource needs, our forecast-based hiring aligns resources for permanent, contract, or contract-to-hire staffing, ensuring readiness to meet customer demand.",
      id: "forecast-based-hire",
    },
    {
      image: oneTime,
      heading: "Just in Time Hiring",
      description:
        "We excel in Just-in-Time Hiring, ensuring swift and efficient recruitment. Our agile team and readily available bench enable us to meet customer demands promptly within agreed timelines.",
      id: "just-in-time-hire",
    },

    {
      image: deployBill,
      heading: "Deploy Observe Bill",
      description:
        "With Deploy Observe Bill, we place our bench resources at client place for a trial week. Billing begins post- observation, minimizing interview time.",
      id: "deploy-observe-bill",
    },
  ];
  return (
    <Grid
      container
      className="mt-5 d-flex justify-content-center"
      sx={{ marginTop: 5 }}
    >
      <Grid
        container
        item
        xs={12}
        className="d-flex align-items-center justify-content-between"
      >
        {cardData.map((val, idx) =>
          isSmallScreen ? (
            <Grid container key={idx} className="mb-5">
              <Grid item xs={12} className="d-flex justify-content-center mb-5">
                <ConsultingImageSection imageURL={val.image} />
              </Grid>
              <Grid item xs={12}>
                <ConsultingContentSection
                  link="/contact-us"
                  footer={true}
                  badgetext="Services"
                  heading={val.heading}
                  description={val.description}
                />
              </Grid>
            </Grid>
          ) : (
            <div id={val.id}>
              <Grid
                container
                item
                xs={12}
                key={idx}
                className="d-flex align-items-center justify-content-between "
                marginBottom={cardData.length - 1 !== idx && 8}
              >
                {idx % 2 === 0 ? (
                  <>
                    <Grid item xs={5}>
                      <ConsultingImageSection imageURL={val.image} />
                    </Grid>
                    <Grid item xs={6.5}>
                      <ConsultingContentSection
                        link="/contact-us"
                        footer={true}
                        badgetext="Services"
                        heading={val.heading}
                        description={val.description}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6.5}>
                      <ConsultingContentSection
                        link="/contact-us"
                        footer={true}
                        badgetext="Services"
                        heading={val.heading}
                        description={val.description}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <ConsultingImageSection imageURL={val.image} />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          )
        )}
      </Grid>
    </Grid>
  );
}

export default ConsultingServices;
