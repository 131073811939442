import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const S3_URL = process.env.REACT_APP_S3_URL;
const DyashinLogo =
  S3_URL+"/v3/assestsWebp/logo/dyashinLogo 1.webp";
const USFlag =
  S3_URL+"/v3/assestsWebp/common/usFlag.webp";
const IndiaFlag =
  S3_URL+"/v3/assestsWebp/common/indiaFlag.webp";
const Linkedin =
  S3_URL+"/v3/assestsWebp/icons/linkedInFooter.webp";
const Facebook =
  S3_URL+"/v3/assestsWebp/icons/facebookFooter.webp";
const Youtube =
  S3_URL+"/v3/assestsWebp/icons/youtubeFooter.webp";
const Instagram =
  S3_URL+"/v3/assestsWebp/icons/instagramFooter.webp";
const Pinterest =
  S3_URL+"/v3/assestsWebp/icons/pinterestFooter.webp";
const Quora =
  S3_URL+"/v3/assestsWebp/icons/quoraFooter.webp";
const Xicon =
  S3_URL+"/v3/assestsWebp/icons/XlogoFooter.webp";
const callIcon =
  S3_URL+"/v3/assestsWebp/icons/callFooter.webp";
const EmailIcon =
  S3_URL+"/v3/assestsWebp/icons/emailFooter.webp";

function FooterComponent({ address }) {
  const footerItems = {
    COMPANY: [
      { title: "About Us", link: "/our-company/about-us" },
      { title: "Vision & Mission", link: "/our-company/vision-and-mission" },
      { title: "Leadership Team", link: "/our-company/leadership-team" },
      { title: "Core values", link: "/our-company/core-values" },
      { title: "CSR Activities", link: "/our-company/csr-activities" },
      { title: "Blogs & Articles", link: "/resources/blogs" },
    ],
    SERVICES: [
      { title: "Managed IT Services", link: "/services/managed-services" },
      {
        title: "Consulting IT Services",
        link: "/services/consulting-services",
      },
      { title: "Learning IT Services", link: "/services/learning-services" },
    ],
    SOLUTIONS: [
      {
        title: "Software Development",
        link: "/solutions/software-development",
      },
      {
        title: "Software QA & Testing",
        link: "/solutions/software-qa-and-testing",
      },
      {
        title: "Engineering Services",
        link: "/solutions/engineering-services",
      },
      {
        title: "Semiconducter & Embeded System",
        link: "/solutions/semiconducter-and-embedded-system",
      },
    ],
    INDUSTRIES: [
      { title: "BFSI", link: "/industries/bsfi" },
      { title: "Manufacturing", link: "/industries/manufacturing" },
      { title: "Telecom", link: "/industries/telecom" },
      { title: "Healthcare", link: "/industries/healthcare" },
      {
        title: "Retail & Ecommerce",
        link: "/industries/retail-and-e-commerce",
      },
      {
        title: "Travel & Hospitality",
        link: "/industries/travel-and-hospitality",
      },
      { title: "Energy & Utilities", link: "/industries/energy-and-utilities" },
      { title: "Education", link: "/industries/education" },
    ],
    CASESTUDY: [{ title: "Case Study", link: "/case-study" }],
    PRODUCTS: [
      { title: "DS Jobby", link: "/products/job-portal" },
      { title: "DS Edify", link: "/products/lms" },
      { title: "DS Pristine", link: "/products/erp" },
      { title: "Sigo", link: "/products/ecommerce" },
    ],
   
  };
  const socialMediaicons = [
    {
      name: "Linkedin",
      icon: Linkedin,
      link: `${process.env.REACT_APP_LINKEDIN_URL}`,
    },
    {
      name: "facebook",
      icon: Facebook,
      link: `${process.env.REACT_APP_FACEBOOK_URL}`,
    },
    {
      name: "Youtube",
      icon: Youtube,
      link: `${process.env.REACT_APP_YOUTUBE_URL}`,
    },
    {
      name: "instagram",
      icon: Instagram,
    link: `${process.env.REACT_APP_INSTAGRAM_URL}`,
    },
    {
      name: "Pinterest",
      icon: Pinterest,
      link: `${process.env.REACT_APP_PINTEREST_URL}`,
    },
    { name: "xicon", icon: Xicon, link: `${process.env.REACT_APP_X_URL}` },
    {
      name: "Quora",
      icon: Quora,
      link: `${process.env.REACT_APP_QUORA_URL}`,
    },
  ];

  return (
    <>
      <Grid container spacing={2} paddingTop={10} paddingBottom={5}>
        <Grid item md={2} sm={12}>
          <Grid container spacing={2}>
            <Grid item>
              <img
                src={DyashinLogo}
                alt="company-logo"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" color="white" className="py-3">
                Great Experience for Building Customers & Businesses
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              padding={2}
              sx={{ borderTop: "0.1px solid #81808C" }}
            >
              {socialMediaicons.map((item, index) => (
                <Grid key={index} item md={3} sm={1} xs={3} padding={0.5}>
                  <a href={item.link} target="blank">
                    <Box
                      component="img"
                      src={item.icon}
                      alt={item.name}
                      className="footer-icons"
                    />
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={10} xs={12} spacing={2}>
          {Object.entries(footerItems).map(([key, values], idx) => (
            <Grid key={idx} item md={2} sm={3} xs={6}>
              <Typography className="fw-700 fs-16" color="white">
                {key}
              </Typography>
              {values.map((item, index) => (
                <Link
                  to={item.link}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    className="fw-400 fs-14"
                    sx={{ color: "#81808C" }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ))}
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography className="fw-700 fs-16" color="white">
              CONTACT US
            </Typography>
          </Grid>
          <Grid item sm={5} md={6}>
            <Grid container>
              <Grid item xs={1} className="d-flex align-items-center">
                <img src={USFlag} alt="" />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className="fw-400 fs-14 py-2"
                  sx={{ color: "#81808C" }}
                >
                  Dyashin Technosoft LLC <br /> 151 Irongable Drive,
                  Middletown,Delaware <br /> DE - 19709, USA
                </Typography>
              </Grid>
              <Grid item xs={1} className="d-flex align-items-center">
                <img src={callIcon} alt="" />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className="fw-400 fs-14 py-1"
                  sx={{ color: "#24ABE3" }}
                >
                  +1 302 279 0052
                </Typography>
              </Grid>
              <Grid item xs={1} className="d-flex align-items-center">
                <img src={EmailIcon} alt="" />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className="fw-400 fs-14 py-1"
                  sx={{ color: "#24ABE3" }}
                >
                  info@dyashin.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sm={7} md={6}>
            <Grid item xs={1} className="d-flex align-items-center">
              <img src={IndiaFlag} alt="" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                className="fw-400 fs-14 py-2"
                sx={{ color: "#81808C" }}
              >
                Dyashin Technosoft Private Limited <br />
                #32/33/5B, 1st Floor, Annapurna Block II, Kanakapura Road,J.P.
                Nagar, <br />
                Bengaluru-560 078, India
              </Typography>
            </Grid>
            <Grid item xs={1} className="d-flex align-items-center">
              <img src={callIcon} alt="" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                className="fw-400 fs-14 py-1"
                sx={{ color: "#24ABE3" }}
              >
                +91 960 691 5923
              </Typography>
            </Grid>
            <Grid item xs={1} className="d-flex align-items-center">
              <img src={EmailIcon} alt="" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                className="fw-400 fs-14 py-1"
                sx={{ color: "#24ABE3" }}
              >
                info@dyashin.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FooterComponent;
