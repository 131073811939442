
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;


const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";
const ExpertLed =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img1.webp";
const card2 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img2.webp";
const card3 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img3.webp";
const card4 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img7.webp";
const card5 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img4.webp";
const mainPost = S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage10/img6.webp";

const BlogsArticlesPage10 = () => {
  const description = (
    <>
      <a
        href="https://dyashin.com/"
        target="blank"
        className="content-links fw-600"
      >
        Dyashin Technosoft
      </a>{" "}
      Private Limited excels in project services, delivering end-to-end
      solutions with precision. From planning to execution, the team ensures
      seamless implementation, achieving client goals efficiently and
      effectively. In this article, we delve into the significance of migration
      and re-engineering in enhancing the business quality and fostering
      sustainable growth.
    </>
  );
  const cardData = [
    {
      image: ExpertLed,
      heading: "Latest technology to boot business",
      description:
        "Migration, in the context of business, refers to the process of transferring data, applications, or other business elements from one environment to another. This could involve moving from legacy systems to modern platforms, transitioning to cloud-based solutions, or upgrading infrastructure to accommodate changing needs. The primary goal of migration is to leverage advancements in technology to improve scalability, reliability, and performance.One of the key drivers behind migration is the need to adapt to changing business requirements. As companies expand, diversify, or pivot their strategies, their IT infrastructure must evolve accordingly. Legacy systems, while once reliable, can become barriers to innovation and hinder agility. By migrating to more flexible and scalable platforms, organizations can streamline operations, reduce costs, and better serve their customers.",
      link: "",
    },
    {
      image: card2,
      heading: "Enhanced cybersecurity and regulatory compliance",
      description:
        "Moreover, migration plays a crucial role in enhancing cybersecurity and compliance. With cyber threats becoming increasingly sophisticated, outdated systems are more vulnerable to attacks. By migrating to modern, secure platforms, businesses can mitigate risks and ensure data integrity and confidentiality. Additionally, compliance requirements are constantly evolving, and migrating to compliant solutions helps organizations stay ahead of regulatory changes and avoid potential penalties.",
      link: "",
    },
    {
      image: card3,
      heading: "Process re-engineering",
      description:
        "Migration alone is not sufficient to achieve sustainable quality improvement. Re-engineering, or the redesign of business processes, is equally essential. Re-engineering involves analyzing existing workflows, identifying inefficiencies, and implementing strategic changes to optimize performance and enhance value delivery.The primary objective of re-engineering is to eliminate redundant tasks, automate repetitive processes, and enhance collaboration across departments. By leveraging emerging technologies such as artificial intelligence, machine learning, and robotic process automation, businesses can streamline operations, reduce errors, and accelerate time-to-market. Furthermore, re-engineering fosters a culture of continuous improvement, where employees are encouraged to innovate and contribute ideas for process optimization.",
      link: "",
    },
    {
      image: card4,
      heading: "Habits die hard",
      description: `One of the critical success factors in migration and re-engineering initiatives is effective change management. Resistance to change is natural, and overcoming it requires strong leadership, clear communication, and stakeholder involvement. Employees must understand the rationale behind the migration and re-engineering efforts and be provided with the necessary training and support to adapt to new systems and processes.`,
      link: "",
    },
    {
      image: card5,
      heading: "Conclusion",
      description: (
        <>
          Migration and re-engineering are integral components of a holistic
          approach to enhancing business quality and driving sustainable growth.{" "}
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>{" "}
          Private Limited is the leading organization embracing technological
          advancements and optimizing workflows that can improve operational
          efficiency, foster innovation, and deliver greater value to customers.
          However, successful migration and re-engineering initiatives require
          careful planning, stakeholder engagement, and a commitment to
          continuous improvement. By investing in these efforts, businesses can
          position themselves for long-term success in an ever-changing market
          landscape.
        </>
      ),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Migrate & Re-engineer</title>
        <meta
          property="og:title"
          content="Boost Biz Quality: Migrate & Re-engineer"
        />
        <meta
          name="description"
          content="Unlock business potential! Learn how migration & re-engineering can elevate your quality & drive success."
        />
        <meta
          property="og:description"
          content="Unlock business potential! Learn how migration & re-engineering can elevate your quality & drive success."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Awareness about the migration and re-engineering for enhancing quality in business"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage10;
