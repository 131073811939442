import React from 'react'
import COLORS from '../../../../constants/colorConstants'

const {BLUE1} = COLORS
function Pinterest() {
  return (
<>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.91357 1.05351C5.25478 1.35032 2.60536 3.50141 2.49601 6.57417C2.42727 8.45032 2.95997 9.85782 4.74551 10.253C5.52034 8.88616 4.49557 8.58466 4.33623 7.59582C3.68169 3.54359 9.0102 0.78013 11.7987 3.6092C13.7279 5.56814 12.4579 11.5949 9.34607 10.9685C6.36547 10.3702 10.8051 5.57283 8.42596 4.63085C6.49201 3.86539 5.46411 6.97252 6.38109 8.51593C5.84371 11.17 4.68615 13.671 5.1548 17C6.67478 15.8971 7.18717 13.7851 7.60739 11.5824C8.37128 12.0464 8.779 12.5291 9.75379 12.6041C13.3483 12.8822 15.3557 9.01582 14.8652 5.44942C14.4293 2.28761 11.2738 0.67859 7.91357 1.05351Z" fill={BLUE1}/>
</svg>

</>
  )
}

export default Pinterest