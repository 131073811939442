import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import COLORS from "../../../constants/colorConstants";

const {WHITE1, BLACK1, BLUE2, BLUE11} = COLORS

function TabsCard({
  icon = "",
  iconContent = "Product Design",
  selected = false,
  index = 0,
  sxcard = {},
  sxtext = {},
  onCardClick = () => {},
}) {
  const [hover, setHover] = useState(false);

  const cardStyles = {
    cursor: "pointer",
    backgroundColor: selected
      ? BLUE2
      : hover
      ? BLUE11
      : WHITE1,
    transition: "all 0.3s ease",
    ...sxcard,
  };

  const textColor = selected || hover ? WHITE1 : BLACK1;
  const iconColor = selected || hover ? WHITE1 : BLUE2;

  return (
    <Grid container className="h-100">
      <Grid
        container
        item
        sm={12}
        paddingY={1.5}
        onClick={() => {
          onCardClick(index);
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={cardStyles}
      >
        <Grid item xs={12} className="d-flex justify-content-center">
          {icon && (
            <Box>{React.cloneElement(icon, { svgcolor: iconColor })}</Box>
          )}
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center">
          <Typography
            className="fw-700 fs-12 text-center py-2"
            color={textColor}
            sx={{ ...sxtext }}
          >
            {iconContent}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TabsCard;
