import React from "react";
import { Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ChipComponent from "../../atoms/ChipComponent";

function ContentSection({
  title = "",
  description = "",
  badgeTextColor = "color-white",
  badgeLabel = "",
  badgeVariant = "contained",
  badgeBgColor = "bg-btn",
  textAlign = "center",
  children,
  sxDescription = {},
  sxTitle = {},
  sxChip = {},
  titleMuiProp = "",
  descriptionMuiProp = "fs-12",
  descriptionColor = "grey",
  chipMuiProps = "",
  sx = {},
  backbtn = false,
  backbutton,
  sxChild = { display: "flex", justifyContent: "center" },
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let sizeClass = isSmallScreen ? "fs-26" : "fs-40";

  return (
    <Grid container spacing={1} sx={{ ...sx }}>
      {backbtn ? (
        <>
          <Grid container item xs={1}>
            {backbutton}
          </Grid>
          {(chipMuiProps || badgeLabel) && (
            <Grid item xs={10}>
              <Box
                sx={{
                  textAlign: { textAlign },
                  height: "100%",
                  
                }}
                className='me-2'
              >
                <ChipComponent
                  label={badgeLabel}
                  variant={badgeVariant}
                  bgColor={badgeBgColor}
                  textColor={badgeTextColor}
                  chipMuiProps={chipMuiProps}
                  sx={{ ...sxChip, cursor: "auto" }}
                />
              </Box>
            </Grid>
          )}

        </>
      ) : (
        (chipMuiProps || badgeLabel) && (
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: { textAlign },
                height: "100%",
              }}
            >
              <ChipComponent
                label={badgeLabel}
                variant={badgeVariant}
                bgColor={badgeBgColor}
                textColor={badgeTextColor}
                chipMuiProps={chipMuiProps}
                sx={{ ...sxChip, cursor: "auto" }}
              />
            </Box>
          </Grid>
        )
      )}

      {title && (
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: { textAlign },
              height: "100%",
            }}
          >
            <Typography
              className={`fw-700 m-0 ${sizeClass} text-capitalize ${titleMuiProp} `}
              sx={{ ...sxTitle }}
            >
              {title}
            </Typography>
          </Box>
        </Grid>
      )}
      {description && (
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: "justify",
              height: "100%",
            }}
          >
            <Typography
              className={`fw-400 ${descriptionMuiProp}`}
              sx={{ color: descriptionColor, ...sxDescription }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item container sx={{ ...sxChild }}>
        {children}
      </Grid>
    </Grid>
  );
}

export default ContentSection;
