import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ButtonComponent from "../../atoms/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import COLORS from "../../../constants/colorConstants";

const {BLACK1} = COLORS 

function CVfrom({
  message = "",
  onSubmit = () => {},
  onModelClose = () => {},
  inputsFields = [],
  showcloseIcon = true,
  heading = "Send Us Your",
  subheading = " CV",
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid container>
        <Grid container item xs={12} className="d-flex justify-content-center">
          <Grid container item xs={12}>
            {isSmallScreen ? (
              <>
                {showcloseIcon && (
                  <Grid
                    item
                    xs={12}
                    className="d-flex justify-content-end  p-2"
                  >
                    <Button
                      onClick={onModelClose}
                      className="btn-hover-background"
                      disableRipple
                    >
                      <CloseIcon sx={{ color: BLACK1 }} />
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography className="fw-700 fs-md-45  fs-xs-26 text-center my-1">
                    {heading}
                    <span className="chip-title-blue">{subheading}</span>
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={11}>
                  <Typography className="fw-700 fs-45 text-center my-1">
                    {heading}{" "}
                    <span className="chip-title-blue">{subheading}</span>
                  </Typography>
                </Grid>

                {showcloseIcon && (
                  <Grid item xs={1} className="d-flex justify-content-end  p-2">
                    <Button
                      onClick={onModelClose}
                      className="btn-hover-background"
                      disableRipple
                    >
                      <CloseIcon sx={{ color: BLACK1 }} />
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            columnGap={1}
            rowGap={1}
            className="d-flex justify-content-center"
          >
            {inputsFields.map((val, idx) => (
              <Grid
                item
                xs={10.3}
                md={5}
                key={idx}
                className="d-flex flex-column  m-md-2 m-xs-1 "
              >
                {val.filed}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={10.3} className="mt-2 d-flex flex-column justify-content-center m-xs-1 ">
            {message}
          </Grid>
          <Grid item xs={12}>
            <Typography className=" text-center fw-400 fs-12 txt-description mt-2">
              Our team will review your submission and respond to you at the
              earliest
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex justify-content-center mt-4 mb-4"
          >
            <ButtonComponent
              label="Submit"
              fullWidth={false}
              size="large"
              bgColor="bg-info"
              sx={{ paddingX: 3, paddingY: 2 }}
              onBtnClick={onSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CVfrom;
