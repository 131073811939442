import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ConsultingImageSection from "../ConsultingImageSection";

function BolgsAndArticles1({ cardData = [] }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container className="d-flex justify-content-center py-3 ">
      <Grid container item xs={12} className="d-flex justify-content-between">
        {cardData.map((val, idx) =>
          isSmallScreen ? (
            <Grid container key={idx} className="mb-5">
              <Grid item xs={12} className="d-flex justify-content-center mb-3">
                <ConsultingImageSection imageURL={val.image} isSmallScreen />
              </Grid>
              <Grid item xs={12}>
                <Typography className="fw-700 fs-24">{val.heading}</Typography>
                <Typography
                  className="fw-400 fs-12 txt-description"
                  textAlign={"justify"}
                >
                  {val.description}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              key={idx}
              className="d-flex  justify-content-between mt-4"
              marginBottom={cardData.length - 1 !== idx && 2}
            >
              {idx % 2 === 0 ? (
                <Grid item sm={5} xs={12}>
                  <ConsultingImageSection imageURL={val.image} />
                </Grid>
              ) : (
                <Grid item sm={6.5} xs={12}>
                  <Typography className="fw-700 fs-24">
                    {val.heading}
                  </Typography>
                  <Typography
                    className="fw-400 fs-12 txt-description"
                    textAlign={"justify"}
                  >
                    {val.description}
                  </Typography>
                </Grid>
              )}

              {idx % 2 === 0 ? (
                <Grid item sm={6.5} xs={12}>
                  <Typography className="fw-700 fs-24">
                    {val.heading}
                  </Typography>
                  <Typography
                    className="fw-400 fs-12 txt-description"
                    textAlign={"justify"}
                  >
                    {val.description}
                  </Typography>
                </Grid>
              ) : (
                <Grid item sm={5} xs={12}>
                  <ConsultingImageSection imageURL={val.image} />
                </Grid>
              )}
            </Grid>
          )
        )}
        <Grid item xs={12} className="mt-5">
          <Typography
            className="fw-400 fs-12 txt-description
            d-flex align-items-center justify-content-center"
            textAlign={"justify"}
          >
            To explore our Learning Services further, contact us at
            contact@dyashin.com Learn more, achieve more with Dyashin.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BolgsAndArticles1;
