import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Drawer,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import Linkedin from "../../../assests/svg/SocailMediaIcons/Linkedin/index";
import FaceBook from "../../../assests/svg/SocailMediaIcons/FaceBook/index";
import Instagram from "../../../assests/svg/SocailMediaIcons/Instagram/index";
import Youtube from "../../../assests/svg/SocailMediaIcons/Youtube/index";
import Pinterest from "../../../assests/svg/SocailMediaIcons/Pinterest/index";
import Twitter from "../../../assests/svg/SocailMediaIcons/Twitter/index";
import Quora from "../../../assests/svg/SocailMediaIcons/Quora/index";
import dyashinLogo from '../../../assests/navbar/DyashinLogo.png'
import COLORS from "../../../constants/colorConstants";

const {WHITE1, WHITE5} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const Call =
  S3_URL+"/v3/assestsWebp/icons/callIcon.webp";
// const dyashinLogo =
//   S3_URL+"/v3/assests/logo/dyashinLogo.png";
const indiaFlag =
  S3_URL+"/v3/assestsWebp/common/indiaFlag.webp";
const usFlag =
  S3_URL+"/v3/assestsWebp/common/usFlag.webp";

function NavbarComponent() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const socialMediaicons = [
    {
      name: "Linkedin",
      icon: <Linkedin />,
      link: `${process.env.REACT_APP_LINKEDIN_URL}`,
    },
    {
      name: "facebook",
      icon: <FaceBook />,
      link: `${process.env.REACT_APP_FACEBOOK_URL}`,
    },
    {
      name: "Youtube",
      icon: <Youtube />,
      link: `${process.env.REACT_APP_YOUTUBE_URL}`,
    },
    {
      name: "instagram",
      icon: <Instagram />,
      link: `${process.env.REACT_APP_INSTAGRAM_URL}`,
    },

    { name: "xicon", icon: <Twitter />, link: `${process.env.REACT_APP_X_URL}` },

    {
      name: "Pinterest",
      icon: <Pinterest />,
      link:`${process.env.REACT_APP_PINTEREST_URL}`,
    },
    {
      name: "Quora",
      icon: <Quora />,
      link: `${process.env.REACT_APP_QUORA_URL}`,
    },
  ];

  const navlinks = [
    { name: "HOME", link: "/" },
    { name: "OUR COMPANY", link: "/our-company" },
    { name: "SERVICES", link: "/services" },
    { name: "PRODUCTS", link: "/products" },
    { name: "SOLUTIONS", link: "/solutions" },
    { name: "INDUSTRIES", link: "/industries" },
    { name: "CONTACT US", link: "/contact-us" },
    { name: "CAREERS", link: "/careers" },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <header>
      <Grid
        container
        sx={{
          backgroundColor: WHITE1,
          opacity: "100%",
        
          borderTop: ` 1px solid ${WHITE5}`,
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <Grid item xs={1} ></Grid>
        <Grid item container xs={10} className=" align-items-center" >
          <Grid item sm={2.5} xs={3} className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <img
                src={dyashinLogo}
                alt="dyashin-logo"
                className="navbar-company-logo w-90p "

              />
            </Link>
          </Grid>

          {isSmallScreen ? (
            <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                color="inherit"
                aria-label="menu"
                sx={{ display: "flex", justifyContent: "flex-end" }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          ) : (
            <>
              <Grid item xs={5.5} className=" d-flex align-items-center ms-2">
                {socialMediaicons.map((val, idx) => (
                  <Grid
                    item
                    padding={1}
                    className="d-flex align-items-center"
                    key={idx}
                  >
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {val.icon}
                    </a>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={3.5} alignItems={"center"}>
                <Grid
                  container
                  className="align-items-center justify-content-between"
                >
                  <Grid item xs={1}>
                    <img src={Call} alt="call Icon" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={9}
                    className="align-items-center text-start"
                    sx={{ paddingLeft: { md: 2, lg: 0 } }}
                  >
                    <Grid item xs={12}>
                      <Typography className="fs-12 fw-400  ">
                        Need help? Talk to an expert
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      className="align-items-center my-1"
                    >
                      <Grid
                        item
                        xs={1}
                        className="align-items-center justify-content-center"
                      >
                        <Box
                          height={"16px"}
                          width={"16px"}
                          className=" d-flex align-items-center justify-content-center"
                        >
                          <img src={usFlag} alt="" className="h-100 w-100" />
                        </Box>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="fs-14 fw-700 ps-2 chip-title-blue text-start">
                          +1 &nbsp;&nbsp;302 279 0052
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className="align-items-center my-1"
                    >
                      <Grid
                        item
                        xs={1}
                        className="align-items-center justify-content-center"
                      >
                        <Box
                          height={"16px"}
                          width={"16px"}
                          className=" d-flex align-items-center justify-content-center"
                        >
                          <img src={indiaFlag} alt="" className="h-100 w-100" />
                        </Box>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="fs-14 fw-700 ps-2 chip-title-blue text-start">
                          +91 960 691 5923
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {isSmallScreen && (
          <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
              <List>
                {navlinks.map((links, index) => (
                  <Link
                    to={links.link}
                    key={index}
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <ListItem>
                      <Typography className="fw-700 fs-14 p-2 text-black">
                        {links.name}
                      </Typography>
                    </ListItem>
                  </Link>
                ))}
                <ListItem>
                  <Grid
                    item
                    className="navbar-icons d-flex align-items-center justify-content-center"
                  >
                    {socialMediaicons.map((val, idx) => (
                      <Grid
                        item
                        padding={1}
                        className="d-flex align-items-center"
                        key={idx}
                      >
                        <a
                          href={val.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {val.icon}
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container className="p-2">
                    <Grid item xs={3}>
                      <img src={Call} alt="call Icon" />
                    </Grid>
                    <Grid item xs={9} className="text-end">
                      <Typography className="fs-12 fw-400 pe-1 pb-0 mb-0">
                        Need help? Talk to an expert
                      </Typography>
                      <Typography className="fs-14 fw-700 pe-1 chip-title-blue">
                        +91 960 691 5923
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        )}
      </Grid>
    </header>
  );
}

export default NavbarComponent;
