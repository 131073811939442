import { Grid, Typography } from "@mui/material";
import React from "react";

function SocialResponsibilitySubContent({ heading = "", description = "" }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className="fw-700 fs-30 ">{heading}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className="fw-400 fs-16 txt-description mt-2" textAlign={'justify'}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SocialResponsibilitySubContent;
