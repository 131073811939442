import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SocialResponsibilitySubImage from "../SocialResponsibilitySubImage";
import SocialResponsibilitySubContent from "../SocialResponsibilitySubContent";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import EastArrow from "../../../assests/svg/Common/EastArrow";
import WestArrow from "../../../assests/svg/Common/WestArrow";
import ChipComponent from "../../atoms/ChipComponent";
const S3_URL = process.env.REACT_APP_S3_URL;
const social1 =
  S3_URL+"/v3/assestsWebp/company/social1.webp";
const EmpathyAction =
  S3_URL+"/v3/assestsWebp/SocialResponsibility/EmpathyAction.webp";
const AddressingBasic =
  S3_URL+"/v3/assestsWebp/SocialResponsibility/AddressingBasic.webp";
const Community =
  S3_URL+"/v3/assestsWebp/SocialResponsibility/Community.webp";
const EthicalSourcing =
  S3_URL+"/v3/assestsWebp/SocialResponsibility/EthicalSourcing.webp";

function SocialResponsibilityCard() {
  let SocialResponsibility = [
    {
      cardheading: "Sharing warmth this winter with blankets",
      description:
        "In a world that often seems driven by profit margins and bottom lines, Dyashin TechnoSoft Pvt. Ltd is stepping forward to make a difference in the lives of those less fortunate. Embracing the spirit of Dyashin’s Social Commitment, we believe in giving back to the community that has supported us. One such initiative that holds the power to warm both bodies and hearts is our blanket-sharing program.",
      position: true,
      ConclusionHeading: "Conclusion",
      ConclusionDiscription: `Dyashin TechnoSoft Pvt Ltd's commitment to social responsibility extends beyond business goals. Through initiatives like the blanket-sharing program, we aim to create a positive ripple effect in the communities we serve. By addressing basic needs and fostering a culture of empathy, we believe in making a lasting difference in the lives of those less fortunate. Together, we can warm hearts and build a brighter future for all.`,

      data: [
        {
          heading: "1. Empathy in Action:",
          description:
            "    At Dyashin TechnoSoft Pvt Ltd, we recognize the importance of empathy and compassion. As part of our Social commitment, we have initiated a program to share blankets with those in need. We understand that for many individuals, especially during harsh weather conditions, a simple blanket can be a source of warmth, comfort, and hope.",
          image: EmpathyAction,
        },
        {
          heading: "2. Addressing Basic Needs:",
          description:
            "In our pursuit of Social excellence, we have identified the basic needs of the less privileged as a priority. By distributing blankets, we aim to provide a tangible solution to the challenges faced by individuals struggling to stay warm in adverse weather conditions. This small gesture can have a significant impact on their overall well-being.",
          image: AddressingBasic,
        },
        {
          heading: "3. Community Engagement:",
          description:
            "Dyashin TechnoSoft Pvt Ltd actively involves its employees in the Social initiatives, fostering a sense of community and shared responsibility. Our employees volunteer their time and effort in the distribution process, creating a bond that goes beyond the workplace. This engagement not only benefits the recipients but also enhances the workplace culture within our organization.",
          image: Community,
        },
        {
          heading: "4. Sustainability and Ethical Sourcing:",
          description:
            "In our blanket-sharing program, we prioritize sustainability and ethical sourcing. We ensure that the blankets distributed are made from eco-friendly materials, contributing to a greener planet. By aligning our Social efforts with ethical practices, we strive to make a positive impact on both people and the environment.",
          image: EthicalSourcing,
        },
      ],
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className="justify-content-between mt-5 mb-3"
      >
        <Grid item xs={12} className="d-flex justify-content-end mb-3 me-5">
          <FarwarButton
            icon={<WestArrow />}
            className="me-2"
          />
          <FarwarButton icon={<EastArrow />} />
        </Grid>
        <Grid item xs={12}>
          <div className="slider-container">
            
              {SocialResponsibility.map((val, idx) => (
                <div key={idx} className="h-100 w-100">
                  {isSmallScreen ? (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-center p-5"
                      >
                        <Box
                          height={"45vh"}
                          width={isSmallScreen ? "90%" : "35vw"}
                        >
                          <img src={social1} alt="" className="h-100 w-100" />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        className="d-flex justify-content-center p-5"
                      >
                        
                           <ChipComponent label="Dyashin Cares"/>
                         <SocialResponsibilitySubContent
                                heading={val.heading}
                                description={val.description}
                              />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      key={idx}
                      className="mb-4 d-flex justify-content-center"
                    >
                      <Grid item xs={6} className="ms-4 p-3">
                      
                        <ChipComponent label="Dyashin Cares"/>
                         <SocialResponsibilitySubContent
                                heading={val.cardheading}
                                description={val.description}
                              />
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        className="me-4 d-flex justify-content-center ms-4"
                      >
                        <Box
                          height={"45vh"}
                          width={isSmallScreen ? "90%" : "35vw"}
                        >
                          <img src={social1} alt="" className="h-100 w-100" />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} className="my-3">
                    <Grid container>
                      {val.data.map((val, idx) =>
                        isSmallScreen ? (
                          <Grid container key={idx} className="mb-5">
                            <Grid
                              item
                              xs={12}
                              className="d-flex justify-content-center mb-3"
                            >
                              <SocialResponsibilitySubImage img={val.image} />
                            </Grid>
                            <Grid item xs={12} className="p-5">
                              <SocialResponsibilitySubContent
                                heading={val.heading}
                                description={val.description}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            item
                            xs={12}
                            key={idx}
                            className="mb-4 d-flex justify-content-center"
                          >
                            {idx % 2 === 0 ? (
                              <Grid item xs={5} className="me-4">
                                <SocialResponsibilitySubImage img={val.image} />
                              </Grid>
                            ) : (
                              <Grid item xs={6} className="ms-4">
                                <SocialResponsibilitySubContent
                                  heading={val.heading}
                                  description={val.description}
                                />
                              </Grid>
                            )}

                            {idx % 2 === 0 ? (
                              <Grid item xs={6} className="me-4">
                                <SocialResponsibilitySubContent
                                  heading={val.heading}
                                  description={val.description}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={5} className="ms-4">
                                <SocialResponsibilitySubImage img={val.image} />
                              </Grid>
                            )}
                          </Grid>
                        )
                      )}
                      <Grid
                        container
                        xs={12}
                        className="d-flex justify-content-center"
                      >
                        <Grid item xs={11} padding={isSmallScreen && 5}>
                          <SocialResponsibilitySubContent
                            heading={val.ConclusionHeading}
                            description={val.ConclusionDiscription}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default SocialResponsibilityCard;
