import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CVfrom from "../../../molecules/CVfrom";
import { useToasts } from "react-toast-notifications";
import { postFormData } from "../../../../services/contactus";
import InputBoxComponent from "../../../atoms/InputBoxComponent";

function ContactUsFormLayout() {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    msg: "",
  });
  const { addToast } = useToasts();

  let handelChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const [fNameError, setfNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [meaasageError, setMeaasageError] = useState("");

  let { fname, lname, email, mobile, msg } = formData;


let validateFName = () => {
  const trimmedFName = fname.trim();

  let regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

  if (trimmedFName) {
    if (regex.test(trimmedFName)) {
      setfNameError("");
      return true;
    } else {
      setfNameError("Invalid First Name (Only one space allowed between words)");
      return false;
    }
  } else {
    setfNameError("Required First Name");
    return false;
  }
};


let validateLName = () => {
  
  const trimmedLName = lname.trim();

  let regex = /^[A-Za-z]+$/;

  if (trimmedLName) {
    if (regex.test(trimmedLName)) {
      setlastNameError("");
      return true;
    } else {
      setlastNameError("Invalid Last Name (No spaces allowed)");
      return false;
    }
  } else {
    setlastNameError("Required Last Name");
    return false;
  }
};


let validateNumber = () => {
  let regex = /^\+?([1-9]{1,3})[-. ]?(\(?\d{1,4}\)?)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/;

  if (mobile) {
    if (regex.test(mobile)) {
      setNumberError("");
      return true;
    } else {
      setNumberError("Invalid Phone Number (check country code and number)");
      return false;
    }
  } else {
    setNumberError("Required Phone Number");
    return false;
  }
};


let validateEamil = () => {

  const trimmedEmail = email.trim();

  let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (trimmedEmail) {
    if (regex.test(trimmedEmail)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Invalid Email Address");
      return false;
    }
  } else {
    setEmailError("Required Email");
    return false;
  }
};


let validateMeaasage = () => {
  const trimmedMsg = msg.trim();

  if (trimmedMsg) {
    
    let regex = /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>]{1,200}$/;
    if (regex.test(trimmedMsg)) {
      setMeaasageError("");
      return true;
    } else {
      setMeaasageError("Accepts alphabets, numbers, and special characters (1-200)");
      return false;
    }
  } else {
    setMeaasageError("Required Message");
    return false;
  }
};

  let handleValidate = () => {
    
    const isValidFName = validateFName();
    const isValidLName = validateLName();
    const isValidNumber = validateNumber();
    const isValidEmail = validateEamil();
    const isValidMessage = validateMeaasage();
  
    if (isValidFName && isValidLName && isValidNumber && isValidEmail && isValidMessage) {
      submitFormData();
    }
  };
  
  const submitFormData = async () => {
  
    const trimmedFormData = {
      fname: formData.fname.trim(),
      lname: formData.lname.trim(),
      email: formData.email.trim(),
      mobile: formData.mobile.trim(),
      msg: formData.msg.trim()
    };
  
  
    let { data, errRes } = await postFormData(trimmedFormData);
    
    if (data) {
      if (data.error) {
        addToast(data.message, { appearance: "error" });
      } else {
        addToast(data.message, { appearance: "success" });
        
        setFormData({
          fname: "",
          lname: "",
          email: "",
          mobile: "",
          msg: "",
        });
      }
    } else if (errRes) {
      addToast(errRes.message, { appearance: "error" });
    }
  };
  
  

  const inputsFields = [
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter First Name"
          textLabel="First Name"
          name="fname"
          value={formData.fname}
          required={true}
          autoComplete="off"
          onChange={handelChange}
          onBlur={validateFName}
          fullWidth={true}
          errorText={fNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Last Name"
          textLabel="Last Name"
          name="lname"
          autoComplete="off"
          value={formData.lname}
          required={true}
          onChange={handelChange}
          onBlur={validateLName}
          fullWidth={true}
          errorText={lastNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Mobile Number"
          textLabel="Mobile Number"
          name="mobile"
          autoComplete="off"
          value={formData.mobile}
          onBlur={validateNumber}
          required={true}
          onChange={handelChange}
          fullWidth={true}
          errorText={numberError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Email ID"
          textLabel="Email ID"
          name="email"
          autoComplete="off"
          value={formData.email}
          onBlur={validateEamil}
          required={true}
          onChange={handelChange}
          fullWidth={true}
          errorText={emailError}
        />
      ),
    },
  ];

  let extrainput = (
    <InputBoxComponent
      placeholder="Enter Message..."
      textLabel="Message"
      name="msg"
      autoComplete="off"
      value={formData.msg}
      onBlur={validateMeaasage}
      onChange={handelChange}
      required={true}
      fullWidth={true}
      errorText={meaasageError}
    />
  );

  return (
    <>
      <Grid
        item
        container
        className="mt-5"
        paddingBottom={2}
        position={"relative"}
      >
        <Grid item xs={1} md={1}></Grid>
        <Grid item container xs={10} md={10} height={"70%"}>
          <Grid
            item
            xs={12}
            className="p-3 my-auto  rounded-1 z-3"
            bgcolor={"white"}
          >
            <Paper elevation={3}>
              <CVfrom
                showcloseIcon={false}
                inputsFields={inputsFields}
                message={extrainput}
                heading="Have any question? Feel free to"
                subheading="Contact us"
                onSubmit={handleValidate}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={1} md={1}></Grid>
      </Grid>
    </>
  );
}

export default ContactUsFormLayout;
