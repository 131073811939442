import { Grid, Typography } from "@mui/material";
import React from "react";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import { useNavigate } from "react-router-dom";


function SocialResponsibilityContent({
  heading = "Dyashin Cares",
  Subheading = "Beyond Tech: Dyashin’s Social Commitment",
  Description = (
    <>
      Dyashin Cares, the Corporate social responsibility (CSR) initiative of
      Dyashin Technosoft Pvt Ltd, embodies our commitment to making a positive
      impact on society. With a mission to uplift communities, Dyashin Cares
      focuses on education, healthcare, and environmental sustainability.
      Through strategic partnerships and hands-on initiatives, we aim to create
      lasting change and contribute to the well-being of the less privileged.
      From supporting educational programs to providing healthcare resources and
      promoting environmental stewardship, Dyashin Cares is dedicated to
      fostering a better future. We believe in the transformative power of
      collective action, and <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Dyashin </a> Cares reflects our dedication 
      to social responsibility and philanthropy.
    </>
  ),
}) {
  const navigate = useNavigate();
  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography className="fw-700 fs-30 lh-sm chip-title-blue">
          {heading}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className="fw-700 fs-20 lh-sm">{Subheading}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className="fw-400 fs-14 txt-description"
          textAlign={"justify"}
        >
          {Description}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={2}
          className="d-flex justify-content-start align-items-center"
        >
          <Typography className="fw-400 fs-14 chip-title-blue">
            See more
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <FarwarButton onBtnClick={() => navigate("/social-responsibility")} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SocialResponsibilityContent;
