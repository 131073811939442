import ContentSection from "../../../molecules/ContentSection";
import {  Grid, } from "@mui/material";
import TeamsCard from "../../../molecules/TeamsCard";
import React from "react";
import COLORS from "../../../../constants/colorConstants";

const {BLUE2} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const RanganathaSir = S3_URL+"/v3/assestsWebp/company/team/RanganathaSir.webp";
const PraveenSir = S3_URL+"/v3/assestsWebp/company/team/PraveenSir.webp";
const MadanSir = S3_URL+"/v3/assestsWebp/company/team/MadanSir.webp";
const RajuSir = S3_URL+"/v3/assestsWebp/company/team/RajuSir1.webp";
const BalaSir = S3_URL+"/v3/assestsWebp/company/team/BalaSir.webp";
const PramodSir = S3_URL+"/v3/assestsWebp/company/team/PramodSir.webp";


function Team() {
  const teamInfo = [
    {
      image: BalaSir,
      name: "S M Balasubramanian",
      designation: "Board Member",
      Linkedin: "https://www.linkedin.com/in/balasubramaniyan-s-m-9713753/",
    },
    {
      image: RanganathaSir,
      name: "Mr. Ranganatha M",
      designation: "Board Member",
      Linkedin: "https://www.linkedin.com/in/ranganath-mahapatra-a23b79/",
    },
    {
      image: PramodSir,
      name: "Mr. Pramod K S",
      designation: "Board Member",
      Linkedin: " https://www.linkedin.com/in/pramod-k-s-06a3a1300/",
    },
    {
      image: MadanSir,
      name: "Mr. Madan K S",
      designation: "C E O",
      Linkedin: "https://www.linkedin.com/in/madan-shamachar/",
    },
    {
      image: PraveenSir,
      name: "Mr. Praveen D",
      designation: "C T O",
      Linkedin: "https://www.linkedin.com/in/praveen-dyamappa/",
    },
    {
      image: RajuSir,
      name: "Mr. Raju K S",
      designation: "C I O",
      Linkedin: "https://www.linkedin.com/in/rajukestur/ ",
    },
  ];
  return (
      <Grid container sx={{ position: "relative", marginBottom:"4%" }} >
        <Grid item md={2}></Grid>
        <Grid item container md={8}>
          <div id="leadership-team">
          <ContentSection
            badgeLabel="MEET THE TEAM"
            title="Our Leadership"
            badgeBgColor="bg-light"
            textAlign="center"
            badgeTextColor={BLUE2}
              chipMuiProps="content-chip1"
          >
            <Grid
              container
              sx={{  zIndex: "0", opacity: "200%"}}
            >
              
              <Grid container item xs={12} rowGap={3} >
                {teamInfo.map((team) => (
                  <Grid item md={4} sm={6} xs={12} className="d-flex justify-content-center">
                    <TeamsCard
                      imageUrl={team.image}
                      title={team.name}
                      position={team.designation}
                      linkedinLink={team.Linkedin}
                    />
                  </Grid>
                ))}
              </Grid>
             
            </Grid>
          </ContentSection>
          </div>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
   
  );
}
export default Team;
