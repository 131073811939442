import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CounterCard from "../../../molecules/CounterCard/Index";
import Developers from "../../../../assests/svg/CounterCard/Developer";
import Client from "../../../../assests/svg/CounterCard/Client";
import Locations from "../../../../assests/svg/CounterCard/Location";
import Domain from "../../../../assests/svg/CounterCard/Domain";
import Projects from "../../../../assests/svg/CounterCard/Projects";
import Technologies from "../../../../assests/svg/CounterCard/Technologies";

import CountUp from "react-countup";

function CounterSection() {
  let counterdata = [
    {
      icon: <Locations />,
      titles: "Locations",
      counts: <CountUp start={0} end={2} duration={2} suffix="" />,
    },
    {
      icon: <Technologies />,
      titles: "Technologies",
      counts: <CountUp start={0} end={60} duration={3} suffix="+" />,
    },
    {
      icon: <Client />,
      titles: "Clients",
      counts: <CountUp start={0} end={40} duration={2.5} suffix="+" />,
    },
    {
      icon: <Domain />,
      titles: "Domains",
      counts: <CountUp start={0} end={8} duration={2} suffix="+" />,
    },
    {
      icon: <Projects />,
      titles: "Projects",
      counts: <CountUp start={0} end={25} duration={3} suffix="+" />,
    },
    {
      icon: <Developers />,
      titles: "Engineers",
      counts: (
        <CountUp
          start={50}
          end={120}
          increment={10}
          duration={2.5}
          suffix="+"
        />
      ),
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container position={!isSmallScreen && "absolute"} top={{md:"95%"}}>
      <Grid item md={2} xs={1}></Grid>
      <Grid item container md={8} xs={10}>
        {counterdata.map((countdata, index) => (
          <Grid item md={2} xs={6} sm={4} key={index} padding={1}>
            <CounterCard
              title={countdata.titles}
              counter={countdata.counts}
              icon={countdata.icon}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item md={2} xs={1}></Grid>
    </Grid>
  );
}

export default CounterSection;
