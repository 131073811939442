
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/img2.webp";
const card2 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/img3.webp";
const card3 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/img5.webp";
const card4 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/img6.webp";
const card5 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/img7.webp";

const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage13/IMG1.webp";

const BlogsArticlesPage13 = () => {
  const description = (
    <>
      <a
        href="https://dyashin.com/"
        target="blank"
        className="content-links fw-600"
      >
        Dyashin Techno Soft
      </a>{" "}
      offers thorough services and training on mobile applications. What is
      Mobile Application Development? Mobile application development involves
      creating software for smartphones and tablets, using programming languages
      and tools for platforms like iOS, Android, and Windows.
    </>
  );
  const cardData = [
    {
      image: card1,
      heading: "Mobile app development process: -",
      description:
        "Start with your idea - Start by defining your app's purpose, target audience, and distinctive features.Select the appropriate platform - Based on the target audience and business objectives, choose between iOS, Android, or both platforms for app development.Programming basics - Learn a few programming languages, such as Java or Kotlin for Android development, Swift or Objective-C for iOS development, and C# for Windows development.",
      link: "",
    },
    {
      image: card2,
      heading: "",
      description:
        "Choose your development tools - Build, test, and debug your app with different development tools and platforms, such as Visual Studio (Windows), Android Studio (Android), and XCode (iOS).Create UI and UX - Provide a user-friendly, aesthetically pleasing interface to increase user happiness and engagement.Build Backend Services - Develop backend services like databases, servers, and APIs to enhance app functionality and facilitate data exchange.",
      link: "",
    },
    {
      image: card3,
      heading: "",
      description:
        "Test & debug – Thoroughly test to find and address any defects or performance issues to provide a flawless user experience. Install & Launch - Prepare your app for deployment to the App Store for iOS and Google Play Store for Android and launch it to the public.Dyashin Techno Soft offers a comprehensive range of services to handle the complexities of mobile app development. Regardless of your size, start up, SME, or major enterprise, our team of skilled designers, developers, and strategists are here to help you at every stage of the procedure.",
      link: "",
    },
    {
      image: card4,
      heading: "Dyashin provide services in -",
      description:
        "Development of Custom Mobile Apps - Our expertise lies in developing customized mobile applications that complement your company's aims and ambitions.Cross-Platform Development - Develop apps that can run seamlessly across multiple platforms using frameworks like React Native and Flutter to reach a wider audience.UI/UX Design - Our skilled designers create visually stunning and intuitive user interfaces to enhance user satisfaction and engagement.Quality Testing - We utilize rigorous testing methods to guarantee your app meets the highest quality standards and performance.",
      link: "",
    },
    {
      image: card5,
      heading: "",
      description: (
        <>
          Tech support - We offer regular maintenance and upgrades to keep your
          app functioning smoothly and effectively.Training -{" "}
          <a href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Techno Soft
          </a>{" "}
          also provides comprehensive training programs for mobile application
          development, catering to beginners and experienced programmers,
          focusing on fundamentals, advanced techniques, and best
          practices.Thus, set out to explore the fascinating field of developing
          mobile applications.{" "}
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Techno Soft
          </a>{" "}
          is a trusted partner in mobile application development, offering
          services and training to help businesses build ground-breaking apps
          and enhance developers' skills.
        </>
      ),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Mobile App Developer Guide</title>
        <meta
          property="og:title"
          content="2024 Mobile App Developer Guide for Beginners"
        />
        <meta
          name="description"
          content="Build your dream app in 2024! This beginner's guide unlocks mobile app development secrets & gets you started."
        />
        <meta
          property="og:description"
          content="Build your dream app in 2024! This beginner's guide unlocks mobile app development secrets & gets you started."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "A beginner’s guide to mobile application development in 2024"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage13;
