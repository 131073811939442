import React, { Component } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import userAvatar from '../assests/UserAvatar1.png'
import ChatSteps from "./ChatSteps";
import ChatbotHeader from "./ChatbotHeader";
import ChatBotProfile from '../assests/botAvatar.png'
import { postChatbotData } from "../services/chatbot";
import COLORS from '../constants/colorConstants'
const {WHITE1,BLUE2} = COLORS

const theme = {
    background: '#f5f8fb',
    botBubbleColor: BLUE2,
    botFontColor: WHITE1,
    userBubbleColor: WHITE1,
    userFontColor: BLUE2,
};
class ChatbotComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conversation: [],
            userInfo: {},
            isChatEnded: false,
        };
    }
    submitChatData = async (chatData) => {
        let { data } = await postChatbotData(chatData);
        console.log("data", data);
    };

    handleEnd = ({ steps, values }) => {
        const storeUserInfo = (userInfo) => {
            this.setState({ userInfo }, () => {
                console.log('Conversation stored:', this.state.userInfo);
                this.submitChatData(this.state.userInfo);
            });
        };

        if (values[0] === 'project-services') {
            const [intrestedService, userName, phNo, email, hasCompanyName, companyName] = values;
            const userInfo = {
                intrestedService,
                userName,
                phNo,
                email,
                hasCompanyName,
                ...(hasCompanyName === 'yes' && { companyName })
            };
            storeUserInfo(userInfo)
        }

        if (values[0] === 'consulting-services') {
            const [intrestedService, serviceSubCategory, userName, phNo, email] = values;
            const userInfo = {
                intrestedService,
                serviceSubCategory,
                userName,
                phNo,
                email,
            };
            storeUserInfo(userInfo)
        }

        if (values[0] === 'learning-services') {
            const [intrestedService, isStudent, selectedProgram, userName, phNo, email] = values;
            const userInfo = {
                intrestedService,
                isStudent,
                selectedProgram,
                userName,
                phNo,
                email,
            };
            storeUserInfo(userInfo)
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <ChatBot
                    botAvatar={ChatBotProfile}
                    userAvatar={userAvatar}
                    headerComponent={<ChatbotHeader onClose={this.props.onClose} />}
                    steps={ChatSteps}
                    handleEnd={this.handleEnd}
                />
            </ThemeProvider>
        );
    }
}

export default ChatbotComp;
