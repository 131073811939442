import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>In the fast-paced world of mobile app creation, the discussion around native versus hybrid apps has been a major point of interest. As technology progresses, the distinction between these two methods is becoming more and more indistinct, and hybrid apps are increasingly popular. Dyashin Technology is leading the charge in this area, using hybrid apps to provide flexible, efficient, and affordable options for both businesses and consumers. </>
  );
  const cardData = [
    {
      image: card1,
      heading: "What are hybrid apps",
      description:"Hybrid apps combine elements of native and web-based applications. Unlike native apps, which are designed for a single platform (such as iOS or Android), hybrid apps are developed using web technologies like HTML, CSS, and JavaScript. They are then packaged in a native format, allowing them to be installed on a device and run in a native view.This special mixture provides the ultimate of both realms. Hybrid apps can utilize device features and operate offline, similar to native apps while being easier and quicker to create, similar to web apps. This versatility makes hybrid apps a compelling choice for developers and businesses aiming to expand their reach and efficiency.",
      link: "",
    },
    {
      image: card2,
      heading: "The benefits of hybrid apps",
      description:"1. Versatility across platforms: A key benefit of hybrid apps is their ability to run on various platforms. With a single codebase, developers can deploy their apps on both iOS and Android, significantly reducing the time and cost of development. 2. Cost-efficient development: Creating separate native apps for different platforms can be costly. Hybrid apps, with their unified codebase, eliminate the need for multiple development teams, making the process more economical. ",
      link: "",
    },
    {
      image: card3,
      heading: "",
      description:"3. Faster to market: The streamlined development process of hybrid apps allows for quicker release to the market. This quick turnaround gives businesses a competitive advantage. 4. Easier upkeep: Handling one codebase is simpler than managing various codebases for different platforms. Updates and bug repairs can be uniformly implemented across all platforms, guaranteeing a consistent experience for users. 5. Improved user experience: Hybrid apps can provide a user experience that is as good as that of native apps. With the use of advanced frameworks like React Native and Flutter, hybrid apps can achieve smooth performance and native-like interfaces.",
      link: "",
    },
    {
      image: card4,
      heading: "Dyashin Technology: Guiding the hybrid app revolution",
      description:"Dyashin Technology is at the vanguard of the hybrid app revolution, offering innovative solutions that meet the varied needs of contemporary businesses. Dyashin Technology harnesses the strength of mixed app development platforms to create applications that are not just strong and expandable but also attractive and easy to use. Innovation in development Dyashin Technology's team of experienced developers employs the latest frameworks such as React Native, Flutter, and Ionic to create high-performance hybrid apps. These frameworks enable the development of applications that can seamlessly interact with various device functionalities, ensuring a native-like experience for users.",
      link: "",
    },
    {
      image: card5,
      heading: "Customized solutions",
      description:"Recognizing that every business has unique requirements, Dyashin Technology provides tailored hybrid app development services. Their solutions are designed to align with specific business objectives, ensuring that each application delivers optimal value.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>The rise of hybrid apps marks a significant shift in the mobile app development landscape. By merging the advantages of native and web applications, hybrid apps provide an attractive option for companies aiming to enhance their app creation procedures. Dyashin Technology stands at the forefront of this revolution, delivering hybrid apps that are efficient, cost-effective, and poised for the future. As the demand for versatile and scalable mobile solutions continues to grow, hybrid apps, powered by Dyashin Technology, are set to lead the way</>
      ),
      link: "",
    },

  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Hybrid-Apps </title>
        <meta
          property="og:title"
          content="The Rise of Hybrid Apps: Bridging Native and Web Experiences"
        />
        <meta
          name="description"
          content="Explore the rise of hybrid apps, combining the best of native and web technologies to deliver versatile, cost-effective, and high-performing mobile experiences."
        />
        <meta
          property="og:description"
          content="Explore the rise of hybrid apps, combining the best of native and web technologies to deliver versatile, cost-effective, and high-performing mobile experiences."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;