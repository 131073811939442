import CarouselReusableComponent from "../../organisms/CarouselReusableBackground";
import CarouselComp from "../../molecules/CarouselComp";
import AboutAgencyCard from "../AboutAgencyCard";
import COLORS from "../../../constants/colorConstants";

const {WHITE1, BLUE5} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const TestimonialImage1 = S3_URL+"/v3/assestsWebp/home/testimonialImage1.webp";
const TestimonialImage2 = S3_URL+"/v3/assestsWebp/home/testimonialImage2.webp";
const TestimonialImage3 = S3_URL+"/v3/assestsWebp/home/testimonialImage3.webp";
const TestimonialImage4 = S3_URL+"/v3/assestsWebp/home/testimonialImage4.webp";
const TestimonialImage5 = S3_URL+"/v3/assestsWebp/home/testimonialImage5.webp";
const TestimonialImage6 = S3_URL+"/v3/assestsWebp/home/testimonialImage6.webp";
const TestimonialImage7 = S3_URL+"/v3/assestsWebp/home/testimonialImage7.webp";
const TestimonialImage9 = S3_URL+"/v3/assestsWebp/home/testimonialImage9.webp";
const TestimonialImage10 = S3_URL+"/v3/assestsWebp/home/testimonialImage10.webp";

function TestimonialsCarousel() {
  const articles = [
    {
      description: `Dyashin Technosoft played a crucial role in developing a custom inventory management system, streamlining operations and boosting efficiency. Their expertise and dedication were invaluable throughout the project.`,
      personName: "Ramesh Poudel",
      iconImage: TestimonialImage2,
      designation: " VP of Operations",
      link: "",
    },
    {
      description: `Partnering with Dyashin Technosoft for our new patient portal was a seamless experience. They delivered a user-friendly and secure platform that has significantly improved patient engagement and satisfaction`,
      personName: "Alessandro Ricci",
      iconImage: TestimonialImage5,
      designation: "Chief Medical Officer",
      link: "",
    },
    {
      description:
        "Dyashin Technosoft's team helped us develop a robust and scalable mobile banking application. Their agile approach ensured that the app met our evolving needs and was launched on time and within budget.",
      personName: "Sharath D L",
      iconImage: TestimonialImage4,
      designation: "Head of Digital Innovation",
      link: "",
    },
    {
      description:
        "Dyashin Technosoft developed our e-learning platform, exceeding expectations. Their expertise in learning management systems resulted in an engaging and effective platform for students.",
      personName: "Sofia García",
      iconImage: TestimonialImage1,
      designation: "C E O",
      link: "",
    },
    {
      description: `Dyashin Technosoft developed our logistics tracking system, transforming our supply chain management. We now have real-time shipment visibility, resulting in improved efficiency and customer satisfaction.`,
      personName: "Chiara Conti",
      iconImage: TestimonialImage7,
      designation: "Chief Sales Officer",
      link: "",
    },
    {
      description: `Dyashin Technosoft developed our content management system, streamlining our content creation and publishing. Their understanding of the media industry ensured the platform met our needs.`,
      personName: "Vikram Reddy",
      iconImage: TestimonialImage9,
      designation: "C T O",
      link: "",
    },
    {
      description: `Dyashin Technosoft's staffing services transformed our FinTech startup by providing skilled developers who seamlessly integrated into our team, meeting deadlines and accelerating development.`,
      personName: "Punith",
      iconImage: TestimonialImage6,
      designation: "C T O",
      link: "",
    },
    {
      description: `Dyashin Technosoft is our preferred IT staff augmentation partner, offering skilled consultants with strong communication and problem-solving abilities, invaluable for our projects.`,
      personName: "Kunal Joshi",
      iconImage: TestimonialImage10,
      designation: "IT Project Manager",
      link: "",
    },
    {
      description: `During peaks, Dyashin Technosoft's contract staffing scales our IT team for online traffic, ensuring smooth operations. Skilled in e-commerce tech, their consultants manage critical periods effectively.`,
      personName: "Sandra Jansen",
      iconImage: TestimonialImage3,
      designation: "Director of IT Operations",
      link: "",
    },
  ];
  return (
    <CarouselReusableComponent
      sx={{ backgroundColor: BLUE5 }}
      capsuleBackgroundColor= {WHITE1}
    >
      <CarouselComp
        title="What Our Client's Say About Us"
        badgeLabel="OUR TESTIMONIALS"
        titleMuiProp="text-black"
       
      >
        {articles.map((article, index) => (
          <AboutAgencyCard
            personName={article.personName}
            iconImage={article.iconImage}
            description={article.description}
            key={index}
            personDesignation={article.designation}
          />
        ))}
      </CarouselComp>
    </CarouselReusableComponent>
  );
}
export default TestimonialsCarousel;
