import { Grid, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CaseStudycard from "../../../molecules/CaseStudyCard";
import ContentSection from "../../../molecules/ContentSection";
import ChipComponent from "../../../atoms/ChipComponent";
import Box from "@mui/material/Box";
import FONTS from "../../../../constants/fontsConstants";
const S3_URL = process.env.REACT_APP_S3_URL;
const AiAndMl = S3_URL + "/v3/assestsWebp/solutions/aiAndML.webp";
const reEngineering = S3_URL + "/v3/assestsWebp/solutions/reEngineering.webp";
const middlewareIntegration =
  S3_URL + "/v3/assestsWebp/solutions/middlewareIntegration.webp";
const eCommerceDevelopment =
  S3_URL + "/v3/assestsWebp/solutions/eCommerceDevelopment.webp";
const mobileAppDevelopment =
  S3_URL + "/v3/assestsWebp/solutions/mobileAppDevelopment.webp";
const chatbotImplementation =
  S3_URL + "/v3/assestsWebp/solutions/chatbotImplementation.webp";
const cloudMigration = S3_URL + "/v3/assestsWebp/solutions/cloudMigration.webp";
const sapImplementation =
  S3_URL + "/v3/assestsWebp/solutions/sapImplementation.webp";
const DigitalMarketing =
  S3_URL + "/v3/assestsWebp/solutions/DigitalMarketing.webp";
const ErpImplementation =
  S3_URL + "/v3/assestsWebp/solutions/ErpImplementation.webp";
const salesforce = S3_URL + "/v3/assestsWebp/solutions/salesforce.webp";
const webAppDevelopment =
  S3_URL + "/v3/assestsWebp/solutions/webAppDevelopment.webp";
const supportAndMaintenance =
  S3_URL + "/v3/assestsWebp/solutions/supportAndMaintenance.webp";

  const {FS_16, FS_14} = FONTS

function SoftwareDevelopment() {
  const chipdata = [
    {
      iconTitle: "AI & ML",
      imageURL: AiAndMl,
      link: "/ai-ml",
      description: (
        <>
          At{" "}
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>
          , we harness the power of Artificial Intelligence and Machine Learning
          to deliver innovative solutions. Our AI & ML expertise drives
          intelligent automation, data-driven insights, and transformative
          experiences, propelling businesses into a future of unparalleled
          efficiency and innovation.
        </>
      ),
    },
    {
      iconTitle: "Migration & Re-Engineering",
      imageURL: reEngineering,
      description: (
        <>
          We specialize in Migration & Re-engineering services, seamlessly
          navigating your{" "}
          <a
            href=" https://dyashin.com/case-study/"
            target="blank"
            className="content-links fw-600"
          >
            digital transformation
          </a>{" "}
          journey. Our expert team ensures a smooth transition, leveraging
          cutting-edge technologies to revitalize and optimize your systems for
          enhanced performance, efficiency, and innovation.
        </>
      ),
    },
    {
      iconTitle: "Middleware Integration",
      imageURL: middlewareIntegration,
      description: (
        <>
          We excel in Middleware Integration, seamlessly connecting diverse
          systems and optimizing data flow. Our expert solutions will enhancing
          business processes and enable a unified digital ecosystem which will
          elevate your operations with our cutting-edge{" "}
          <a
            href="https://dyashin.com/resources/blogs/future-proofing-with-cloud"
            target="blank"
            className="content-links fw-600"
          >
            Middleware Integration services
          </a>
          .
        </>
      ),
    },
    {
      iconTitle: "ERP Implementation",
      imageURL: ErpImplementation,
      description: (
        <>
          Revolutionize your business operations with our seamless{" "}
          <a
            href="https://dyashin.com/resources/blogs/erp-implementation"
            target="blank"
            className="content-links fw-600"
          >
            ERP Implementation
          </a>{" "}
          services. We specialize in deploying robust solutions tailored to your
          unique needs, ensuring streamlined processes, enhanced efficiency, and
          improved decision-making. Let Dyashin Technosoft be your trusted
          partner in embracing cutting-edge technology for a transformative and
          future-ready business experience.
        </>
      ),
    },
    {
      iconTitle: "E-Commerce Development",
      imageURL: eCommerceDevelopment,
      description: (
        <>
          Elevate your business with our comprehensive{" "}
          <a
            href="https://dyashin.com/resources/blogs/empowering-learning"
            target="blank"
            className="content-links fw-600"
          >
            E-Commerce Development
          </a>{" "}
          services. We specialize in creating robust, user-friendly online
          platforms tailored to your unique needs. From seamless transactions to
          captivating user experiences, we ensure your digital storefront
          exceeds the evolving demands of the online marketplace.
        </>
      ),
    },
    {
      iconTitle: "Cloud Migration",
      imageURL: cloudMigration,
      description: (
        <>
          Empower your business with seamless Cloud Migration solutions. Elevate
          performance, scalability, and security as we expertly transition your
          operations to the cloud. Trust us to optimize your digital
          infrastructure, ensuring a smooth and efficient migration that aligns
          with your goals, at{" "}
          <a
            href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>
          .
        </>
      ),
    },
    {
      iconTitle: "Salesforce",
      imageURL: salesforce,
      description: (
        <>
          Dyashin Technosoft Introduces Dynamic Salesforce Solutions, Seamlessly
          Integrating Innovative Technologies To Elevate Your Business
          Processes. Our Salesforce Expertise Ensures Tailored{" "}
          <a
            href="https://dyashin.com/resources/blogs/sap-unlock-business"
            target="blank"
            className="content-links fw-600"
          >
            CRM Systems
          </a>
          , Empowering Your Organization With Streamlined Workflows, Enhanced
          Customer Engagement, And Strategic Insights. Unlock The Potential Of
          Salesforce With{" "}
          <a
            href={process.env.REACT_APP_BLOGSPOT_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>{" "}
          For Unparalleled Efficiency And Growth.
        </>
      ),
    },
    {
      iconTitle: "Mobile/Hybrid App Development",
      imageURL: mobileAppDevelopment,
      description: (
        <>
          We specialize in Mobile and Hybrid{" "}
          <a
            href="https://dyashin.com/resources/blogs/mobile-app-development"
            target="blank"
            className="content-links fw-600"
          >
            Application development
          </a>
          , seamlessly blending native and web technologies. Our innovative
          solutions ensure a dynamic and engaging user experience, harnessing
          the power of cutting-edge technology to propel your business forward.
        </>
      ),
    },
    {
      iconTitle: "Chatbot Implementation",
      imageURL: chatbotImplementation,
      description: (
        <>
          Elevate customer engagement with our cutting-edge{" "}
          <a
            href="https://dyashin.com/resources/blogs/chatbot-implementation"
            target="blank"
            className="content-links fw-600"
          >
            Chatbot Implementation services
          </a>
          . Seamlessly integrate AI-powered chatbots into your systems,
          enhancing user experience, streamlining interactions, and providing
          efficient solutions tailored to your business needs. Transform
          communication with{" "}
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>
          .
        </>
      ),
    },
    {
      iconTitle: "SAP Implementation",
      imageURL: sapImplementation,
      description: (
        <>
          Experience seamless business transformation with our{" "}
          <a
            href="https://dyashin.com/resources/blogs/sap-unlock-business"
            target="blank"
            className="content-links fw-600"
          >
            SAP Implementation
          </a>{" "}
          services. Our experts tailor solutions to optimize your processes,
          enhance efficiency, and unlock the full potential of SAP, ensuring a
          smooth transition and empowering your organization for sustained
          success.
        </>
      ),
    },
    {
      iconTitle: "Support & Maintenance",
      imageURL: supportAndMaintenance,
      description:
        "For seamless operations, our Support & Maintenance services ensure the continual health and efficiency of customer applications. Our dedicated team provides timely updates, resolves issues promptly, and implements preventive measures to uphold the applications performance, allowing customer to focus on your core business without interruptions or concerns about technical stability.",
    },
    {
      iconTitle: "Digital Marketing",
      imageURL: DigitalMarketing,
      description: (
        <>
          Elevate your brand's online presence with our dynamic{" "}
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            Digital Marketing solutions
          </a>
          . We leverage strategic insights, cutting-edge tools, and industry
          expertise to drive targeted traffic, enhance engagement, and boost
          conversions. Let's navigate the digital landscape, amplifying your
          brand's reach and impact for success in the competitive market.
        </>
      ),
    },
    {
      iconTitle: "Web App Development",
      imageURL: webAppDevelopment,
      description: (
        <>
          Experience the epitome of{" "}
          <a
            href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600"
          >
            Web Application
          </a>{" "}
          Development with our proficiency in Full Stack Technologies. Elevate
          your digital presence as we harness cutting-edge tools and expertise
          to craft dynamic, responsive, and scalable web solutions tailored to
          meet your unique business needs. Transform your vision into reality
          with us.
        </>
      ),
    },
  ];

  const [index, setIndex] = useState(0);
  const [conditionStop, setConditionStop] = useState(true);

  useEffect(() => {
    let interval;
    if (conditionStop) {
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % chipdata.length);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [index, conditionStop, chipdata.length]);

  const handleChipClick = (idx) => {
    setIndex(idx);
    setConditionStop(false);
  };

  const getObject = chipdata[index];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div id="software-development">
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <ContentSection
            badgeLabel={"SOLUTIONS"}
            title={"Software Development"}
            sxTitle={{ fontSize: FS_16 }}
            textAlign={"center"}
            chipMuiProps="content-chip1"
          />
        </Grid>
        <Grid item md={2} xs={1}></Grid>
        <Grid
          item
          container
          md={8}
          xs={10}
          className={
            isSmallScreen
              ? "py-3"
              : "d-flex justify-content-center align-items-center"
          }
          sx={{ height: { md: 500, xs: 900 } }}
        >
          <Grid item md={4} xs={12}>
            <Tabs
              orientation= "vertical"
              // orientation={isSmallScreen ? "horizontal" : "vertical"}
              variant="scrollable"
              value={index}
              aria-label="Horizontal tabs example"
              sx={{ height: { md: 470 } }}
            >
              {chipdata.map((val, idx) => (
                <ChipComponent
                  key={idx}
                  sx={{ margin: "0.5%" }}
                  label={val.iconTitle}
                  index={idx}
                  borderRadius={"2px"}
                  onBtnClick={() => handleChipClick(idx)}
                  selected={idx === index}
                  chipMuiProps="mb-2 chip-component"
                />
              ))}
            </Tabs>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            sx={{ paddingLeft: { md: 3 } }}
            className={isSmallScreen && "d-flex align-items-start"}
          >
            <Grid container item xs={12} alignContent={"start"}>
              <Grid item xs={12}>
                <Box className="cursor-pointer">
                  <img
                    src={getObject.imageURL}
                    alt={getObject?.iconTitle}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <CaseStudycard
                  link="/case-study"
                  sxDescription={{ fontSize: FS_14 }}
                  descriptionContent={getObject.description}
                  showfooter={true}
                  cardmuiprop="decrease-scale"
                  imageMuiProp="increase-scale"
                  isCardNavigate={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default SoftwareDevelopment;
