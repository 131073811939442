import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>The gap between industry and academia has long been a topic of concern. While educational institutions strive to impart theoretical knowledge, industries often seek practical skills that new graduates might lack. Bridging this gap is crucial for creating a workforce that is ready to meet the challenges of the modern workplace. At the forefront of this initiative are campus programs designed to align academic learning with industry needs, with companies like Dyashin Technosoft playing a pivotal role. </>
  );
  const cardData = [
    {
      image: card1,
      heading: "Collaborative curriculum development",
      description:"Collaborative curriculum creation stands as a highly effective strategy for connecting academia with industry. By engaging in close collaboration with educational institutions, firms such as Dyashin Technosoft guarantee that their courses are in tune with the latest trends in the industry. This partnership frequently includes contributions from industry experts, who may deliver guest lectures, co-create course materials, and share knowledge on the newest technological breakthroughs.",
      link: "",
    },
    {
      image: card2,
      heading: "",
      description:"For instance, Dyashin Technosoft has formed alliances with various universities to develop specialized courses in cutting-edge technologies like artificial intelligence, machine learning, and blockchain. These courses are designed to not only impart theoretical knowledge but also to equip students with practical skills through hands-on projects and real-world applications. Internships and cooperative education (co-op) programs are crucial for providing students with practical experience. These programs allow students to engage with industries relevant to their academic pursuits, offering them a glimpse into the professional world and enabling them to apply their theoretical knowledge in practical settings",
      link: "",
    },
    {
      image: card3,
      heading: "",
      description:"Dyashin Technosoft offers a comprehensive internship program that allows students to work on active projects under the guidance of experienced professionals. This experience not only improves their technical abilities but also fosters the development of essential soft skills like teamwork, communication, and problem-solving. Many interns at Dyashin Technosoft are well-prepared for full-time employment upon graduation, with some even receiving job offers from the company itself.",
      link: "",
    },
    {
      image: card4,
      heading: "",
      description:"Companies like Dyashin Technosoft also participate in industry-driven research projects with universities, funding initiatives that tackle real-world issues and explore innovative technologies. These research collaborations often lead to significant breakthroughs that benefit both the academic community and the industry. For example, a joint research effort between Dyashin Technosoft and a premier engineering institution resulted in the creation of a new software algorithm that greatly enhanced data processing speeds. Such innovations not only bolster the company's competitive position but also contribute to the advancement of academic knowledge.",
      link: "",
    },
    {
      image: card5,
      heading: "",
      description:"Skill development workshops and seminars serve as another effective method for connecting academia with industry. These events, typically led by industry experts, provide students with insights into current industry practices, trends, and technologies. They also offer a platform for students to engage with professionals, seek clarification on topics, and gain a more profound understanding of their chosen field. Dyashin Technosoft regularly hosts workshops and seminars on a variety of topics, from software development methodologies to cybersecurity. These sessions are open to both students and faculty, ensuring that academic knowledge remains current with the latest industry trends. Campus recruitment drives play a pivotal role in facilitating the transition from academic to professional life for many students. By actively engaging in these drives, companies like Dyashin Technosoft play a significant role in ensuring a seamless transition for students into their careers.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>
          Bridging the industry-academia gap requires a multifaceted approach, and campus programs are at the heart of this effort. Through collaborative curriculum development, internships, research initiatives, workshops, and recruitment drives, companies like Dyashin Technosoft are playing a crucial role in preparing students for the demands of the modern workplace. By fostering closer ties between educational institutions and industries, these programs ensure that the next generation of professionals is well-equipped to contribute to and thrive in their chosen fields.
        </>
      ),
      link: "",
    }
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content="How Campus Programs Bridge the Industry-Academia Gap"
        />
        <meta
          name="description"
          content="Discover how campus programs are bridging the industry-academia gap by aligning education with real-world skills, fostering collaboration, and enhancing career readiness."
        />
        <meta
          property="og:description"
          content="Discover how campus programs are bridging the industry-academia gap by aligning education with real-world skills, fostering collaboration, and enhancing career readiness."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;