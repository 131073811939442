import axios from "axios";

const baseURL =
  "https://ixqkyqsi2z.ap-south-1.awsapprunner.com/api/v1/dyashin/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 1 * 60 * 1000,
  timeoutErrorMessage:
    "We are unable to connect to the server. Please try again later.",
});

axiosInstance.interceptors.request.use(async (config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };
