import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import Products from "../../molecules/Products";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner =
  S3_URL + "/v3/assestsWebp/products/Banner.webp";

function ProductsPage() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  });
  return (
    <>
      <Helmet>
        <title>Dyashin : Products</title>
        <meta property="og:title" content="Innovative Products by Dyashin - Technology to Transform Your Business" />
        <meta name="description" content="Browse Dyashin's innovative product lineup designed to streamline your business operations and enhance technological capabilities. Discover our solutions." />
        <meta property="og:description" content="Browse Dyashin's innovative product lineup designed to streamline your business operations and enhance technological capabilities. Discover our solutions." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Products">
        <ReusableBackground1>
          <Products />
        </ReusableBackground1>
      </PageLayout>
    </>
  );
}

export default ProductsPage;
