import * as React from "react";
import { Box, Grid } from "@mui/material";

function TwoSectionGridComponent({ children, textAlign = "center" }) {
  const [firstChild, secondChild] = React.Children.toArray(children);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} sx={{display:"flex",justifyContent:{md:"left",xs:"center"}, alignItems:{md:"center"}}}>
        <Box p={2} sx={{width:{md:"100%", sm:"70%"}, mb:{sm:"15%", xs:"20%"}}}>
          {firstChild}
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{display:"flex",justifyContent:{sm:"center"}}}>
        <Box p={2} textAlign={textAlign} >
          {secondChild}
        </Box>
      </Grid>
    </Grid>
  );
}

export default TwoSectionGridComponent;
