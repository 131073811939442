import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import JoinOurTeamCard from "./JoinOurTeamCard";
import OpenPosition from "../../molecules/OpenPosition";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/jobVacancy/banner.webp";

function JobVacancyPage() {
  useEffect(() => window.scrollTo(0, 0), []);
  let JobData = [
    {
      jobPosition: "FullStack Developer",
      jobExperience: "Experience :3+ year",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are looking for experienced developers who are eager to solve complex technical problems while creating high-quality software systems with cutting-edge technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "BE/BTech/Any Technical Graduation",
            },
          ],
        },
        {
          title: "Requirements",
          deatils: [
            {
              list: "3+ years of experience in IT.",
            },
            {
              list: "Experience with Java 8, Data Structure & Algorithm, Hibernate with JPA, Spring Framework (Spring-Boot),HTML,CSS,Bootstrap,React-js.",
            },
            {
              list: "Experience with database (SQL).",
            },
          ],
        },
        {
          title: "Job Description",
          deatils: [
            {
              list: "Create functional web - based applications by writing clean code.",
            },
            {
              list: "Create new user interface features.",
            },
            {
              list: "Collect and respond to technical and functional requirements.",
            },
            {
              list: "Develop reusable code and libraries for later use.",
            },
            {
              list: "Translating designs and wireframes into high-quality code",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "React Developer",
      jobExperience: "Experience :3+ year",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are looking for experienced developers who are eager to solve complex technical problems while creating high-quality software systems with cutting-edge technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "BE/BTech/Any Technical Graduation",
            },
          ],
        },
        {
          title: "Requirements",
          deatils: [
            {
              list: "3+ years of experience in IT.",
            },
            {
              list: "Experience with HTML, CSS, Bootstrap, JavaScript(ES6), React, Redux.",
            },

          ],
        },
        {
          title: "Job Description",
          deatils: [
            {
              list: "Using React JS to create new user interface features.",
            },
            {
              list: "Creating reusable front-end components and libraries for future use.",
            },
            {
              list: "Translating designs and wireframes into high-quality code.",
            },
            {
              list: "Focusing on improving components for enhanced efficiency across a wide range of web-capable devices and browsers.",
            },
            {
              list: "Expertise in agile development methodologies.",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "Angular Developer",
      jobExperience: "Experience :3+ year",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are looking for experienced developers who are eager to solve complex technical problems while creating high-quality software systems with cutting-edge technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "BE/BTech/Any Technical Graduation",
            },
          ],
        },
        {
          title: "Requirements",
          deatils: [
            {
              list: "3+ years of experience in IT.",
            },
            {
              list: "Experience with HTML, CSS, Bootstrap, JavaScript(ES6), TypeScript, Angular.",
            },
          ],
        },
        {
          title: "Job Description",
          deatils: [
            {
              list: "Using Angular to create new user interface features.",
            },
            {
              list: "Creating reusable front-end components and libraries for future use.",
            },
            {
              list: "Translating designs and wireframes into high-quality code.",
            },
            {
              list: "Focusing on improving components for enhanced efficiency across a wide range of web-capable devices and browsers.",
            },
            {
              list: "Expertise in agile development methodologies.",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "BackEnd Developer",
      jobExperience: "Experience :3+ year",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are looking for experienced developers who are eager to solve complex technical problems while creating high-quality software systems with cutting-edge technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "BE/BTech/Any Technical Graduation",
            },
          ],
        },
        {
          title: "Requirements",
          deatils: [
            {
              list: "3+ years of experience in IT.",
            },
            {
              list: "Experience with Java 8, Data Structure & Algorithm, Hibernate with JPA, Spring Framework (Spring IoC, Spring-MVC, Spring-REST, Spring-JPA, Spring-Boot).",
            },
            {
              list: "Experience with database (SQL).",
            },
          ],
        },
        {
          title: "Job Description",
          deatils: [
            {
              list: "Create functional web - based applications by writing clean code.",
            },
            {
              list: "To optimize effectiveness, run UI tests.",
            },
            {
              list: "Collect and respond to technical and functional requirements.",
            },
            {
              list: "Develop reusable code and libraries for later use.",
            },
            {
              list: "Software troubleshooting and debugging.",
            },
          ],
        },
      ],
    },
  ];

  let InternshipData = [
    {
      jobPosition: "FullStack Developer",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are seeking enthusiastic interns who are passionate about diving into challenging technical issues while contributing to the development of top-notch software solutions using the latest technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "Pursuing/Graduated",
            },
          ],
        },
        {
          title: "Technologies",
          deatils: [
            {
              list: "HTML,CSS,Bootstrap,React-js,Java 8, Hibernate with JPA, Spring Framework (Spring-Boot),SQL.",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "React Developer",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are seeking enthusiastic interns who are passionate about diving into challenging technical issues while contributing to the development of top-notch software solutions using the latest technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "Pursuing/Graduated",
            },
          ],
        },
        {
          title: "Technologies",
          deatils: [
            {
              list: "HTML,CSS,JavaScript,Bootstrap,React-js,",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "Angular Developer",
      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are seeking enthusiastic interns who are passionate about diving into challenging technical issues while contributing to the development of top-notch software solutions using the latest technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "Pursuing/Graduated",
            },
          ],
        },
        {
          title: "Technologies",
          deatils: [
            {
              list: "HTML,CSS,JavaScript,Bootstrap,Angular-js,.",
            },
          ],
        },
      ],
    },
    {
      jobPosition: "BackEnd Developer",

      jobTimes: "FULL TIME",
      btnName: "APPLY NOW",
      JobDetails: [
        {
          title: "Description",
          deatils: [
            {
              list: "We are seeking enthusiastic interns who are passionate about diving into challenging technical issues while contributing to the development of top-notch software solutions using the latest technologies.",
            },
          ],
        },
        {
          title: "Qualification",
          deatils: [
            {
              list: "Pursuing/Graduated",
            },
          ],
        },
        {
          title: "Technologies",
          deatils: [
            {
              list: "Java 8, Hibernate with JPA, Spring Framework (Spring-Boot),SQL.",
            },
          ],
        },
      ],
    },
  ];
  return (
    <>
      <Helmet>
      <title>Dyashin : Careers</title>
        <meta property="og:title" content="Join Our Team - Career Opportunities at Dyashin" />
        <meta property="og:description" content="Explore career opportunities at Dyashin and become part of a team that values innovation, growth, and community. Find your place in a company that's shaping the future." />
        <meta name="description" content="Explore career opportunities at Dyashin and become part of a team that values innovation, growth, and community. Find your place in a company that's shaping the future." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Careers">
        <ReusableBackground1>
          <JoinOurTeamCard />
          <OpenPosition
            positionType={"Open Positions"}
            positionData={JobData}
            onBtnclick={(index) => {
              console.log(index);
            }}
          />

          <OpenPosition
            positionType={"Open Position - Internship"}
            positionData={InternshipData}
            onBtnclick={(index) => {
              console.log(index);
            }}
          />
        </ReusableBackground1>
      </PageLayout>
    </>
  );
}

export default JobVacancyPage;
