import { Grid, useMediaQuery, useTheme } from "@mui/material";
import RoundedRectangleBox from "../../atoms/RoundedRectangleBox";
import COLORS from "../../../constants/colorConstants";

const {BLUE1, BLUE2} = COLORS
function ReusableBackground1({ children }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container overflow="hidden">
      <Grid
        item
        xs={1}
        md={2}
        sx={{ paddingTop: "0%", position: "relative", overflowY: "hidden" }}
      >
        {!isSmallScreen && (
          <RoundedRectangleBox
            animationClassName="grey-capsule"
            height="20%"
            width="88%"
            top="20%"
            left="-35%"
            zIndex={2}
            backgroundColor={BLUE1}
            sx={{ transform: "rotate(-45deg)" }}
          />
        )}
      </Grid>
      <Grid item xs={10} md={8}>
        <Grid container className="d-flex justify-content-center">
          {children}
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} sx={{ paddingTop: "0%", position: "relative" }}>
        {!isSmallScreen && (
          <RoundedRectangleBox
            animationClassName="triangle-animation"
            height="20%"
            width="70%"
            top="75%"
            left="65%"
            backgroundColor= {BLUE2}
            sx={{ transform: "rotate(-45deg)" }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default ReusableBackground1;
