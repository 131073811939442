import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";

import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import { useNavigate } from "react-router-dom";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const mainpost = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage3/mainpost.webp'
const card1 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage3/card1.webp'
const card2 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage3/card2.webp'
const card3 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage3/card3.webp'
const card4 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage3/card4.webp'


const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";


const BlogsArticlesPage3 = () => {
  const description = (<>AI is revolutionizing talent acquisition, with organizations utilizing AI recruitment tools to streamline hiring processes and stay ahead in the fast-paced job market. As technology evolves, so does the recruitment landscape, making it crucial for businesses and job seekers. Keeping up with changing times, <a href={process.env.REACT_APP_FACEBOOK_URL} target="blank" className="content-links fw-600">Dyashin TechnoSoft </a> offers <a href="https://dyashin.com/products" target="blank" className="content-links fw-600">DSJobby</a>, a groundbreaking AI-enabled product revolutionizing talent acquisition.</>);
  const cardData = [
    {
      image: card1,
      heading: "Empowers with AI technology:",
      description:
      (<><a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600">Dyashin's DSJobby </a> is a leading software product in the job search industry, providing a comprehensive solution for job seekers and providers. DSJobby empowers recruitment teams with advanced AI technology, which gives them efficient tools to manage the challenging talent acquisition landscape.</>),
      link: "",
    },
    {
      image: card2,
      heading: "Streamlines the process with precision:",
      description:
      (<>Streamlining the entire process effortlessly, <a href={process.env.REACT_APP_PINTEREST_URL} target="blank" className="content-links fw-600">DSJobby </a> transforms candidate tracking with precision, removing the necessity for manual monitoring and unwieldy spreadsheets. It makes the hiring process more efficient by streamlining it and giving recruiters real-time access to candidates' applications, interactions and interviews.</>),
      link: "",
    },
    {
      image: card3,
      heading: "Sophisticated AI algorithms for accurate results:",
      description:
      (<>However, the most innovative feature of <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">DSJobby from Dyashin </a> is its sophisticated AI algorithms, which guarantee accurate candidate-job matching.  It provides results that are incredibly relevant and accurate, going beyond simple keyword matching by using the latest advancements in machine learning and data analysis.</>),
      link: "",
    },
    {
      image: card4,
      heading: "Perfect choice for job seekers:",
      description: (<>For job seekers, <a href="https://dyashin.com/products" target="blank" className="content-links fw-600">DSJobby </a> provides a unique job search experience by utilizing an intelligent matching system that matches candidates with opportunities that align with their skills, experience, and career aspirations. Leveraging AI, the software expedites job seekers' job search, empowering them to find their desired roles and advance their careers.
Employers see DSJobby as a paradigm shift in hiring practices. Businesses may increase the quality of their hiring and drastically cut down on the time it takes to hire new employees by utilizing AI. The recruiting teams can concentrate on interacting with exceptional candidates using the tool, knowing that each match is supported by insights derived from data.
Hence, DSJobby from Dyashin Techno Soft is revolutionizing talent acquisition with AI, empowering job seekers and providers with advanced technology and intelligent algorithms. As the job market evolves, software stands as an innovation beacon, helping companies and candidates navigate the ever-changing landscape. <a href="https://dyashin.com/" target="blank" className="content-links fw-600">Dyashin TechnoSoft </a> is a software development and engineering services company offering managed IT services.</>),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : AI in Recruitment</title>
        <meta property="og:title" content="AI in Recruitment: Unveiling DSJobby's Impact on Talent Acquisition" />
        <meta property="og:description" content="Explore how DSJobby by Dyashin Techno Soft leverages AI to transform recruitment, offering precise candidate-job matching and streamlining the hiring process for a future-ready job market." />
        <meta name="description" content="Explore how DSJobby by Dyashin Techno Soft leverages AI to transform recruitment, offering precise candidate-job matching and streamlining the hiring process for a future-ready job market." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "AI in Recruitment: Unveiling DSJobby's Impact on Talent Acquisition"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainpost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage3;
