import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const card7 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img4.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>Lately, the world of education has experienced a major transformation, mainly due to technological progress. Among these innovations, Artificial Intelligence (AI) has emerged as a game-changer in modern Learning Management Systems (LMS). AI's integration into LMS platforms has not only revolutionized traditional educational paradigms but also enhanced the learning experience for students and educators alike. For companies like Dyashin Technosoft, which offers some of the best software development courses and full-stack web development courses, the application of AI in LMS is particularly pertinent.</>
  );
  const cardData = [
    {
      image: card1,
      heading: "Personalized learning experiences",
      description:"One of the most significant contributions of AI to modern LMS is its ability to personalize learning experiences. Traditional educational models often follow a one-size-fits-all approach, which can be inefficient for addressing individual learner needs. Machine learning models examine huge quantities of information from how students engage, spotting trends and likes. This allows for the development of customized educational routes designed to suit the individual abilities and areas for improvement of every student. For instance, Dyashin Technosoft's LMS can adapt its software development courses to focus on areas where a student may be struggling, thus ensuring a more effective and engaging learning experience.",
      link: "",
    },
    {
      image: card2,
      heading: "Intelligent tutoring systems",
      description:"AI-powered intelligent tutoring systems are another transformative aspect of modern LMS. These systems provide real-time feedback and assistance to students, emulating the experience of having a personal tutor. They can answer questions, provide explanations, and even offer hints when students encounter difficulties. This is particularly valuable for complex subjects such as full-stack web development, where students often require immediate clarification to progress. By incorporating intelligent tutoring systems, Dyashin Technosoft ensures that its students receive timely support, enhancing their learning outcomes.",
      link: "",
    },
    {
      image: card3,
      heading: "Predictive analytics for student performance",
      description:"Predictive analytics, powered by artificial intelligence, is essential in tracking and forecasting student achievement. AI algorithms analyze historical and real-time data to predict future performance trends. This enables teachers to spot students in danger early on and provide specific assistance. For institutions like Dyashin Technosoft, this capability is invaluable. By predicting which students might struggle with specific aspects of their software development courses, instructors can proactively offer additional resources or personalized attention, thereby improving overall course completion rates.",
      link: "",
    },
    {
      image: card4,
      heading: "Enhanced course content creation",
      description:"Creating engaging and relevant course content is a challenge for any educational institution- AI helps with this task by examining present trends, feedback from students, and performance information to recommend enhancements to the content. It can recommend updates, new materials, or different teaching methods to keep the curriculum dynamic and effective. Dyashin Technosoft leverages AI to continuously refine its course offerings, ensuring that its full-stack web development courses remain at the cutting edge of industry standards and student expectations.",
      link: "",
    },
    {
      image: card5,
      heading: "Automating administrative tasks",
      description:"AI also brings efficiency to administrative tasks within LMS platforms. Grading assignments, managing enrollments, and tracking attendance are time-consuming activities that can be automated through AI. This allows educators to focus more on teaching and interacting with students rather than getting bogged down by administrative work. For Dyashin Technosoft, this means instructors can dedicate more time to mentoring students in their software development journey, fostering a more interactive and supportive learning environment.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Adaptive learning environments",
      description: (
        <>
          Adaptive learning environments, powered by AI, adjust the learning experience in real-time based on student interactions. These environments can modify the difficulty level of exercises, offer additional resources, or alter the pace of instruction to suit individual learning speeds. This adaptability ensures that students are neither bored with overly simple tasks nor overwhelmed by excessively challenging ones. At Dyashin Technosoft, this approach is instrumental in maintaining student engagement and motivation throughout their full-stack web development courses.
        </>
      ),
      link: "",
    },
    {
      image: card7,
      heading: "Conclusion",
      description:"The integration of AI in modern Learning Management Systems has ushered in a new era of personalized, efficient, and effective education. For institutions like Dyashin Technosoft, which is dedicated to providing top-tier software development courses and full-stack web development courses, AI's capabilities are invaluable. By leveraging AI, Dyashin Technosoft ensures that its students receive a tailored, supportive, and cutting-edge educational experience, preparing them to excel in the ever-evolving tech industry.",
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content="The Role of AI in Transforming Modern Learning Management Systems"
        />
        <meta
          name="description"
          content="Discover how AI enhances modern learning management systems by personalizing education, improving engagement, and streamlining administrative tasks."
        />
        <meta
          property="og:description"
          content="Discover how AI enhances modern learning management systems by personalizing education, improving engagement, and streamlining administrative tasks."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;