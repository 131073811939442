import {  Grid, Typography } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import COLORS from "../../../constants/colorConstants";

const {WHITE1} = COLORS

function TeamsCard({
  imageUrl = "",
  title = "",
  position = "",
  linkedinLink = "",
}) {
  return (
    <Grid
      container
      width={"80%"}
      boxShadow={"1px 1px 10px lightgrey"}
      className="justify-content-center h-100"
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        paddingTop={2}
        justifyContent={"center"}
        bgcolor={"white"}
      >
        <img src={imageUrl} alt="blog1" width={200} height={230} />
      </Grid>
      <Grid item container xs={12} padding={"5%"} backgroundColor={WHITE1}>
        <Grid item paddingTop={"5%"} xs={12} paddingX={2}>
          <Typography className="fw-700 fs-16">{title}</Typography>
          <Typography className="fw-700 fs-12 chip-title-blue">
            {position}
          </Typography>
        </Grid>
        <Grid item xs={12} paddingY={"5%"} className="d-flex px-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={linkedinLink}
            className="text-decoration-none d-flex"
          >
            <Typography className="fw-700 fs-10  pe-1">LINKEDIN</Typography>{" "}
            <LinkedInIcon className="fs-16" />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TeamsCard;
