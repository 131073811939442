import React from 'react'
import COLORS from '../../../../constants/colorConstants'

const {BLUE1} = COLORS
function Youtube() {
  return (
    <>
    <svg width="25" height="25"  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.84231 10.6956L12.1621 8.47174L7.84231 6.21585V10.6956ZM9.49023 3C12.8501 3 15.09 3.15999 15.09 3.15999C15.394 3.20799 16.0819 3.20799 16.6899 3.84796C16.6899 3.84796 17.1859 4.32794 17.3299 5.43189C17.5059 6.72782 17.4899 8.02376 17.4899 8.02376V9.2397C17.4899 9.2397 17.5059 10.5356 17.3299 11.8316C17.1859 12.9195 16.6899 13.4155 16.6899 13.4155C16.0819 14.0555 15.394 14.0555 15.09 14.0875C15.09 14.0875 12.8501 14.2635 9.49023 14.2635C5.33043 14.2155 4.05049 14.0875 4.05049 14.0875C3.69851 14.0235 2.89855 14.0555 2.29058 13.4155C2.29058 13.4155 1.7946 12.9195 1.65061 11.8316C1.47461 10.5356 1.49061 9.2397 1.49061 9.2397V8.02376C1.49061 8.02376 1.47461 6.72782 1.65061 5.43189C1.7946 4.32794 2.29058 3.84796 2.29058 3.84796C2.89855 3.20799 3.58651 3.20799 3.8905 3.15999C3.8905 3.15999 6.13039 3 9.49023 3Z" fill={BLUE1}/>
</svg>

    </>
  )
}

export default Youtube