
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner =
  S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const ExpertLed =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card2 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img2.webp";
const card3 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img1.webp";
const card4 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card5 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const mainPost =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img5.webp";

const BlogsArticlesPage20 = () => {
  const description = (<>Added to the basic features of the Learning Management System (LMS), DSEdify by Dyashin Technosoft includes coding challenges that boost hands-on learning. Its strong test modules provide thorough evaluations. The comprehensive approach enhances the educational experience, positioning DSEdify as an unmatched platform for learning and skill enhancement. Equip your team with a dynamic LMS that transcends limits, created by Dyashin Technosoft for a perfect fusion of innovation and knowledge sharing.</>);
  const cardData = [
    {
      image: ExpertLed,
      heading: "Augmenting Learning",
      description:"DSEdify, created by Dyashin Technosoft, offers an advanced Learning Management System (LMS) tailored to meet the varied needs of learners in three main areas:Campus: Provide students with the necessary skills to excel in their studies.Off-Campus: Support individuals looking to progress in their careers by acquiring skills that are relevant and current in the industry.Corporate: Conduct training and enhance the employees' skills through cross-training, upskilling, or reskilling to remain competitive.DSEdify is more than just a conventional learning platform. Dedicated to creating an interactive learning atmosphere that encourages personal growth, DSEdify offers a variety of engaging courses, customized learning journeys, and robust evaluation tools.",
      link:"",
    },
    {
      image: card2,
      heading: "How Does DSEdify Assist in a Smooth Education?",
      description:"DSEdify provides an extensive collection of tools to ensure a smooth educational experience: •Interactive Programs: We offer a broad selection of creative educational programs tailored to meet the specific requirements of different groups of learners. •Progress Monitoring: Easily keep tabs on your educational path. Be aware of your selected courses and monitor your advancement at every stage. •Current Information: Find the newest course materials and updates at your fingertips through the platform. •Detailed Evaluations: Our thorough evaluation sections enable you to precisely measure your technical skills and pinpoint areas that need work.",
      link:"",
    },
    {
      image: card3,
      heading: "The Ultimate Learning Platform",
      description:"DSEdify integrates multimedia content, including videos, interactive simulations, and gamified elements, to cater to diverse learning preferences. This multimedia strategy not only enhances the learning experience but also aids in remembering intricate details. Additionally, the platform supports collaborative learning through virtual classrooms and discussion forums, allowing students to connect and learn from each other, thereby fostering a sense of community and shared knowledge.For educators, DSEdify offers robust analytics tools that provide insights into student progress and performance trends. These tools enable teachers to make data-driven decisions, tailor their teaching strategies, and deliver targeted interventions to support students who may be struggling.",
      link:"",
    },
    {
      image: card4,
      heading: "Reach",
      description:"Moreover, DSEdify is designed to be accessible on multiple devices, ensuring that learning is not confined to the classroom. Whether students are at home, on the go, or in a traditional classroom setting, they can seamlessly continue their educational journey.",
      link:"",
    },
    {
      image: card5,
      heading: "Conclusion",
      description:"In summary, DSEdify enhances learning by offering a personalized, adaptive, and interactive educational experience. By integrating advanced technology with pedagogical best practices, DSEdify empowers both students and educators to achieve their full potential.",
      link:"",
    },
    
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin :   DS-Edify</title>
        <meta property="og:title" content="Enhance Learning with DSEdify: A Dynamic LMS by Dyashin Technosoft" />
        <meta name="description" content="Discover how DSEdify, the innovative LMS by Dyashin Technosoft, elevates education with interactive programs, detailed evaluations, and cross-platform accessibility for learners and educators." />
        <meta property="og:description" content="Discover how DSEdify, the innovative LMS by Dyashin Technosoft, elevates education with interactive programs, detailed evaluations, and cross-platform accessibility for learners and educators." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Enhancing Learning with DSEdify: The Future of Education by Dyashin Technosoft"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage20;