import { Navigate, useRoutes } from "react-router-dom";
import ContactUsPage from "../../components/pages/ContactUsPage";
import HomePage from "../../components/pages/HomePage";
import IndustriesPage from "../../components/pages/IndustriesPage";
import JobVacancyPage from "../../components/pages/JobVacancyPage";
import OurCompanyPage from "../../components/pages/OuCompanyPage";
import ServicesPage from "../../components/pages/ServicesPage";
import SolutionPage from "../../components/pages/SoluctionPage";
import ProductsPage from "../../components/pages/ProductsPage";
import ConsultingServicesPage from "../../components/pages/ConsultingServicesPage";
import LearningServicesPage from "../../components/pages/LearningServicesPage";
import BlogsAndArticles from "../../components/pages/BlogsAndArticlesPage";
import BlogsArticlesPage1 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage1";
import CaseStudyPageSubPage from "../../components/pages/CaseStudyPageSubPage";
import BlogsArticlesPage02 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage2";
import BlogsArticlesPage3 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage3";
import BlogsArticlesPage4 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage4";
import BlogsArticlesPage5 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage5";
import BlogsArticlesPage6 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage06";
import BlogsArticlesPage07 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage07";
import BlogsArticlesPage08 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage08";
import BlogsArticlesPage09 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage09";
import BlogsArticlesPage10 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage10";
import BlogsArticlesPage11 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage11";
import BlogsArticlesPage12 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage12";
import BlogsArticlesPage13 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage13";
import BlogsArticlesPage14 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage14";
import BlogsArticlesPage15 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage15";
import BlogsArticlesPage16 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage16";
import BlogsArticlesPage17 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage17";
import BlogsArticlesPage18 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage18";
import BlogsArticlesPage19 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage19";
import BlogsArticlesPage20 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage20";
import BlogsArticlesPage21 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage21";
import BlogsArticlesPage22 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage22";
import BlogsArticlesPage23 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage23";
import BlogsArticlesPage24 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage24";
import BlogsArticlesPage25 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage25";
import BlogsArticlesPage26 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage26";
import BlogsArticlesPage27 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage27";
import BlogsArticlesPage28 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage28";
import BlogsArticlesPage29 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage29";
import BlogsArticlesPage30 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage30";
import BlogsArticlesPage31 from "../../components/pages/BlogsArticlesSubPage/BlogsArticlesPage31";
import SocialResponsibilityPage from "../../components/pages/SocialResponsibility";
import CaseStudyPage from "../../components/pages/CaseStudyPage";

const WebRoutesObject = [
  {
    element: <HomePage />,
    path: "/",
    label: "home",
  },
  {
    element: <OurCompanyPage />,
    path: "/our-company",
    label: "our-company",
    children: [
      {
        element: <OurCompanyPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <ServicesPage />,
    path: "/services",
    label: "services",
    children: [
      {
        element: <ServicesPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <ProductsPage />,
    path: "/products",
    label: "products",
    children: [
      {
        element: <ProductsPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <SolutionPage />,
    path: "/solutions",
    label: "solutions",
    children: [{ element: <SolutionPage />, path: ":section?" }],
  },
  {
    element: <SocialResponsibilityPage />,
    path: "/social-responsibility",
    label: "social-responsibility",
  },
  {
    element: <IndustriesPage />,
    path: "/industries",
    label: "industries",
    children: [
      {
        element: <IndustriesPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <ContactUsPage />,
    path: "/contact-us",
    label: "contact-us",
  },
  {
    element: <JobVacancyPage />,
    path: "/careers",
    label: "careers",
  },
  {
    element: <CaseStudyPage />,
    path: "/case-study",
    label: "case-study",
  },
  {
    element: <CaseStudyPageSubPage />,
    path: "/case-study/:caseStudySub",
  },
  {
    element: <ConsultingServicesPage />,
    path: "/consulting-services",
    label: "consulting-services",
    children: [
      {
        element: <ConsultingServicesPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <LearningServicesPage />,
    path: "/learning-services",
    label: "learning-services",
    children: [
      {
        element: <LearningServicesPage />,
        path: ":section?",
      },
    ],
  },
  {
    element: <BlogsAndArticles />,
    path: "/resources/blogs",
    label: "blogs-and-articles",
  },
  {
    element: <BlogsArticlesPage1 />,
    path: "/resources/blogs/empowering-learning",
    label: "empowering-learning",
  },
  {
    element: <BlogsArticlesPage02 />,
    path: "/resources/blogs/embracing-change",
    label: "embracing-change",
  },
  {
    element: <BlogsArticlesPage3 />,
    path: "/resources/blogs/ai-in-recruitment",
    label: "ai-in-recruitment",
  },
  {
    element: <BlogsArticlesPage4 />,
    path: "/resources/blogs/future-proofing-with-cloud",
    label: "future-proofing-with-cloud",
  },
  {
    element: <BlogsArticlesPage5 />,
    path: "/resources/blogs/augmented-reality-in-engineering",
    label: "augmented-reality-in-engineering",
  },
  {
    element: <BlogsArticlesPage6 />,
    path: "/resources/blogs/revolutionizing-customer-engagement",
    label: "revolutionizing-customer-engagement",
  },
  {
    element: <BlogsArticlesPage07 />,
    path: "/resources/blogs/managed-services-revolution",
    label: "managed-services-revolution",
  },
  {
    element: <BlogsArticlesPage08 />,
    path: "/resources/blogs/empowering-education",
    label: "empowering-education",
  },
  {
    element: <BlogsArticlesPage09 />,
    path: "/resources/blogs/transforming-business",
    label: "",
  },
  {
    element: <BlogsArticlesPage10 />,
    path: "/resources/blogs/migrate-and-reengineer",
    label: "transforming-business",
  },
  {
    element: <BlogsArticlesPage11 />,
    path: "/resources/blogs/chatbot-implementation",
    label: "chatbot-implementation",
  },
  {
    element: <BlogsArticlesPage12 />,
    path: "/resources/blogs/sap-unlock-business",
    label: "sap-unlock-business",
  },
  {
    element: <BlogsArticlesPage13 />,
    path: "/resources/blogs/mobile-app-development",
    label: "mobile-app-development",
  },
  {
    element: <BlogsArticlesPage14 />,
    path: "/resources/blogs/empower-education-dsedify",
    label: "empower-education-dsedify",
  },
  {
    element: <BlogsArticlesPage15 />,
    path: "/resources/blogs/ai-implementation",
    label: "ai-implementation",
  },
  {
    element: <BlogsArticlesPage16 />,
    path: "/resources/blogs/erp-implementation",
    label: "erp-implementation",
  },
  {
    element: <BlogsArticlesPage17 />,
    path: "/resources/blogs/fpga-implementation",
    label: "fpga-implementation",
  },
  {
    element: <BlogsArticlesPage18 />,
    path: "/resources/blogs/hrms-implementation",
    label: "hrms-implementation",
  },
  {
    element: <BlogsArticlesPage19 />,
    path: "/resources/blogs/sap-implementation",
    label: "sap-implementation",
  },
  {
    element: <BlogsArticlesPage20 />,
    path: "/resources/blogs/ds-edify",
    label: "learn-ds_edify",
  },
  {
    element: <BlogsArticlesPage21 />,
    path: "/resources/blogs/learn-tech",
    label: "learn-tech",
  },
  {
    element: <BlogsArticlesPage22 />,
    path: "/resources/blogs/remote-work",
    label: "remote-work",
  },
  {
    element: <BlogsArticlesPage23 />,
    path: "/resources/blogs/supplychains-with-ai",
    label: "supplychains-with-ai",
  },
  {
    element: <BlogsArticlesPage24 />,
    path: "/resources/blogs/hybrid-apps",
    label: "hybrid-apps",
  },
  {
    element: <BlogsArticlesPage25 />,
    path: "/resources/blogs/middleware-integration",
    label: "middleware-integration",
  },
  {
    element: <BlogsArticlesPage26 />,
    path: "/resources/blogs/corporate-training",
    label: "corporate-training",
  },
  {
    element: <BlogsArticlesPage27 />,
    path: "/resources/blogs/industry-academia",
    label: "industry-academia",
  },
  {
    element: <BlogsArticlesPage28 />,
    path: "/resources/blogs/career-readiness",
    label: "career-readiness",
  },
  {
    element: <BlogsArticlesPage29 />,
    path: "/resources/blogs/modern-business",
    label: "modern-business",
  },
  {
    element: <BlogsArticlesPage30 />,
    path: "/resources/blogs/leveraging-tech",
    label: "leveraging-tech",
  },
  {
    element: <BlogsArticlesPage31 />,
    path: "/resources/blogs/ai-lms",
    label: "ai-lms",
  },
  {
    element: <Navigate to="/" />,
    path: "*",
  },
];

const WebsiteRoutes = () => {
  const routesObj = WebRoutesObject;
  const routes = useRoutes([...routesObj]);
  return routes;
};

export { WebsiteRoutes };
