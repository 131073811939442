import React from "react";

function SecurityTestingIcon({ svgcolor = "white" }) {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.1777 5.84961H22.832C22.2656 5.84961 21.7969 6.31836 21.7969 6.88477C21.7969 7.45117 22.2656 7.91992 22.832 7.91992H27.1777C27.7441 7.91992 28.2129 7.45117 28.2129 6.88477C28.2129 6.31836 27.7441 5.84961 27.1777 5.84961Z"
          fill={svgcolor}
        />
        <path
          d="M43.1152 5.84961H35.1465C34.5801 5.84961 34.1113 6.31836 34.1113 6.88477C34.1113 7.45117 34.5801 7.91992 35.1465 7.91992H43.1152C43.6816 7.91992 44.1504 7.45117 44.1504 6.88477C44.1504 6.31836 43.6816 5.84961 43.1152 5.84961Z"
          fill={svgcolor}
        />
        <path
          d="M43.1152 2.95898H6.88477C4.7168 2.95898 2.95898 4.72656 2.94922 6.89453V34.4238C2.94922 36.5918 4.7168 38.3496 6.88477 38.3594H11.6504V43.125C11.6504 45.293 13.418 47.0508 15.5859 47.0605H34.4238C36.5918 47.0605 38.3496 45.293 38.3594 43.125V38.3594H43.125C45.293 38.3594 47.0508 36.5918 47.0605 34.4238V6.88477C47.041 4.72656 45.2734 2.95898 43.1152 2.95898ZM34.7266 27.5977V20.4688C34.7461 17.627 32.4512 15.293 29.6094 15.2637H20.3906C17.5488 15.293 15.2539 17.627 15.2734 20.459V27.5879C13.252 27.7441 11.6504 29.4434 11.6504 31.5039V36.2695H6.88477C5.85938 36.2695 5.01953 35.4297 5.01953 34.4043V10.8203H44.9707V34.4141C44.9707 35.4395 44.1309 36.2793 43.1055 36.2793H38.3398V31.5137C38.3496 29.4629 36.7481 27.7637 34.7266 27.5977ZM13.7207 31.5234C13.7207 30.498 14.5605 29.6582 15.5859 29.6582H34.4238C35.4492 29.6582 36.2891 30.498 36.2891 31.5234V43.1152C36.2891 44.1406 35.4492 44.9805 34.4238 44.9805H15.5859C14.5605 44.9805 13.7207 44.1406 13.7207 43.1152V31.5234ZM17.3438 20.4688C17.3242 18.7695 18.6914 17.3633 20.3906 17.3438H29.6094C31.3086 17.3633 32.6855 18.7695 32.666 20.4785V27.5977H31.8359V20.4883C31.8555 19.248 30.8691 18.2129 29.6191 18.1836H20.3906C19.1504 18.2129 18.1543 19.248 18.1738 20.4883V27.6074H17.3438V20.4688ZM20.2344 20.4492C20.2246 20.3418 20.3027 20.2539 20.4102 20.2344H29.5898C29.6973 20.2539 29.7656 20.3418 29.7656 20.4492V27.5879H20.2441V20.4688L20.2344 20.4492ZM44.9707 6.88477V8.75H5.0293V6.88477C5.0293 5.85938 5.86914 5.01953 6.89453 5.01953H43.1152C44.1406 5.0293 44.9707 5.85938 44.9707 6.88477Z"
           fill={svgcolor}
        />
        <path
          d="M6.88477 5.84961C6.31836 5.84961 5.84961 6.31836 5.84961 6.88477C5.84961 7.45117 6.31836 7.91992 6.88477 7.91992C7.45117 7.91992 7.91992 7.45117 7.91992 6.88477C7.91992 6.31836 7.46094 5.84961 6.88477 5.84961Z"
            fill={svgcolor}
        />
        <path
          d="M9.78516 5.84961C9.21875 5.84961 8.75 6.31836 8.75 6.88477C8.75 7.45117 9.21875 7.91992 9.78516 7.91992C10.3516 7.91992 10.8203 7.45117 10.8203 6.88477C10.8203 6.31836 10.3613 5.84961 9.78516 5.84961Z"
           fill={svgcolor}
        />
        <path
          d="M12.6855 5.84961C12.1191 5.84961 11.6504 6.31836 11.6504 6.88477C11.6504 7.45117 12.1191 7.91992 12.6855 7.91992C13.252 7.91992 13.7207 7.45117 13.7207 6.88477C13.7207 6.31836 13.252 5.84961 12.6855 5.84961Z"
            fill={svgcolor}
        />
        <path
          d="M6.88477 14.4434H8.33008C8.89648 14.4434 9.36523 13.9746 9.36523 13.4082C9.36523 12.8418 8.89648 12.373 8.33008 12.373H6.88477C6.31836 12.373 5.84961 12.8418 5.84961 13.4082C5.84961 13.9746 6.31836 14.4434 6.88477 14.4434Z"
          fill={svgcolor}
        />
        <path
          d="M17.7539 12.373H11.2305C10.6641 12.373 10.1953 12.8418 10.1953 13.4082C10.1953 13.9746 10.6641 14.4434 11.2305 14.4434H17.7539C18.3203 14.4434 18.7891 13.9746 18.7891 13.4082C18.7891 12.8418 18.3301 12.373 17.7539 12.373Z"
            fill={svgcolor}
        />
        <path
          d="M12.6855 15.2734H6.88477C6.31836 15.2734 5.84961 15.7422 5.84961 16.3086C5.84961 16.875 6.31836 17.3438 6.88477 17.3438H12.6758C13.2422 17.3438 13.7109 16.875 13.7109 16.3086C13.7109 15.7422 13.252 15.2734 12.6855 15.2734Z"
           fill={svgcolor}
        />
        <path
          d="M41.6602 14.4434H43.1055C43.6719 14.4434 44.1406 13.9746 44.1406 13.4082C44.1406 12.8418 43.6719 12.373 43.1055 12.373H41.6602C41.0938 12.373 40.625 12.8418 40.625 13.4082C40.625 13.9746 41.0938 14.4434 41.6602 14.4434Z"
          fill={svgcolor}
        />
        <path
          d="M39.7949 13.4082C39.7949 12.8418 39.3262 12.373 38.7598 12.373H32.2363C31.6699 12.373 31.2012 12.8418 31.2012 13.4082C31.2012 13.9746 31.6699 14.4434 32.2363 14.4434H38.7598C39.3359 14.4434 39.7949 13.9746 39.7949 13.4082Z"
           fill={svgcolor}
        />
        <path
          d="M44.1504 16.3086C44.1504 15.7422 43.6816 15.2734 43.1152 15.2734H37.3242C36.7578 15.2734 36.2891 15.7422 36.2891 16.3086C36.2891 16.875 36.7578 17.3438 37.3242 17.3438H43.1152C43.6816 17.3438 44.1504 16.875 44.1504 16.3086Z"
           fill={svgcolor}
        />
        <path
          d="M25 44.1504C28.7695 44.1504 31.8262 41.084 31.8262 37.3242C31.8262 33.5645 28.7598 30.498 25 30.498C21.2305 30.498 18.1738 33.5645 18.1738 37.3242C18.1738 41.084 21.2305 44.1504 25 44.1504ZM20.2344 37.3145C20.2344 34.6875 22.373 32.5488 25 32.5488C27.627 32.5488 29.7656 34.6875 29.7656 37.3047C29.7656 39.9219 27.627 42.0605 25 42.0703C22.373 42.0801 20.2344 39.9414 20.2344 37.3145Z"
           fill={svgcolor}
        />
        <path
          d="M25 41.25C25.5664 41.25 26.0352 40.7812 26.0352 40.2148V39.7559C26.4551 39.6191 26.7578 39.2285 26.7578 38.7695V35.8691C26.7578 35.4102 26.4551 35.0098 26.0352 34.8828V34.4238C26.0352 33.8574 25.5664 33.3887 25 33.3887C24.4336 33.3887 23.9648 33.8574 23.9648 34.4238V35.8691C23.9648 36.3281 24.2676 36.7285 24.6875 36.8555V37.7832C24.2676 37.9199 23.9648 38.3105 23.9648 38.7695V40.2148C23.9648 40.7812 24.4336 41.25 25 41.25Z"
           fill={svgcolor}
        />
        <path
          d="M15.5859 34.0039C16.1523 34.0039 16.6211 33.5352 16.6211 32.9687V32.2461C16.6211 31.6797 16.1523 31.2109 15.5859 31.2109C15.0195 31.2109 14.5508 31.6797 14.5508 32.2461V32.9687C14.5508 33.5352 15.0098 34.0039 15.5859 34.0039Z"
         fill={svgcolor}
        />
        <path
          d="M15.5859 43.418C16.1523 43.418 16.6211 42.9492 16.6211 42.3828V35.8594C16.6211 35.293 16.1523 34.8242 15.5859 34.8242C15.0195 34.8242 14.5508 35.293 14.5508 35.8594V42.3828C14.5508 42.959 15.0098 43.418 15.5859 43.418Z"
           fill={svgcolor}
        />
      </svg>
    </>
  );
}

export default SecurityTestingIcon;
