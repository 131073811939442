import serviceUtil from "../utils";

const postJobData = (payload) => {
  return serviceUtil
    .post(`/career/send`, payload)
    .then((res) => {
      const data = res?.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err?.response?.data ||
        err || {
          message: "ERROR",
        };
      return { errRes };
    });
};

export { postJobData };
