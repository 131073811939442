import CarouselReusableComponent from "../CarouselReusableBackground";
import CaseStudycard from "../../molecules/CaseStudyCard";
import CarouselComp from "../../molecules/CarouselComp";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import COLORS from "../../../constants/colorConstants";

import FONTS from "../../../constants/fontsConstants";
const {WHITE1} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const caseStudy1 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy1.webp";
const caseStudy2 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy2.webp";
const caseStudy3 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy3.webp";
const caseStudy4 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy4.webp";
const caseStudy5 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy5.webp";
const caseStudy6 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy6.webp";
const caseStudy7 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy7.webp";
const caseStudy8 = S3_URL+"/v3/assestsWebp/casestudy/caseStudy8.webp";

const {FS_40, FS_17} = FONTS

const articles = [
  {
    title: "Enhancing Productivity and Efficiency with VBA IDE",
    description:
      "An office suite software developer company approached Dyashin Technosoft seeking a solution for constant optimization of its operations to maintain competitiveness and client satisfaction.",
    image: caseStudy1,
    link: "/1",
  },
  {
    title: "Revolutionizing Education with School Expert System(SES)",
    description:
      "The client is a leading educational institution approached Dyashin Technosoft seeking to leverage cutting-edge technologies to address the evolving needs of students and educators alike.",
    image: caseStudy2,
    link: "/2",
  },
  {
    title: "Empowering Fitness Journey ",
    description:
      "The client is a fitness enthusiast and entrepreneur approached Dyashin Technosoft for creating a platform that offers personalized workout plans, nutrition guidance, and community support to help users achieve their fitness goals effectively.",
    image: caseStudy3,
    link: "/3",
  },
  {
    title:
      "Revolutionizing Insurance Management: Vehicle and Home Policies App",
    description:
      "The client is a prominent insurance company approached Dyashin Technosoft seeking to provide customers with a user-friendly and efficient way to manage their insurance policies on mobile devices.",
    image: caseStudy4,
    link: "/4",
  },
  {
    title: "Education Technology",
    description:
      "The client is a prominent Indian Multinational Corporation (MNC) with a focus on education services. They cater to a wide range of educational institutions, including schools affiliated with boards like CBSE, ICSE, and various state boards.",
    image: caseStudy5,
    link: "/5",
  },
  {
    title: "HMOS library development ",
    description:
      "The client is a leading Chinese mobile development Research and Development (R&D) company specializing in the creation of innovative mobile operating system (OS) libraries. With a focus on Java and JavaScript technologies, they aim to provide comprehensive solutions catering to various mobile features and functionalities.",
    image: caseStudy6,
    link: "/6",
  },
  {
    title: "Cloud Infrastructure and Mobility Solutions",
    description:
      "Our client is a leading US-based mobility company specializing in commercial vehicle fleet management solutions. They provide advanced technology platforms for real-time tracking and management of commercial vehicles, primarily focusing on the transportation and logistics sector. With operations spanning across the United States and Europe, they required a robust and scalable infrastructure to support their rapidly growing user base and expanding business operations.",
    image: caseStudy7,
    link: "/7",
  },
  {
    title: "E-commerce Platform Development",
    description:
      "Our client is a prominent Indian-based company looking to establish a robust B2C e-commerce platform with a unique reseller feature. As a leading player in their industry, they aimed to leverage digital channels to expand their market reach, enhance customer engagement, and drive sales growth. The reseller feature would enable individuals or businesses to sign up as resellers and promote/sell products from the platform, creating additional revenue streams and fostering a sense of community among users.",
    image: caseStudy8,
    link: "/8",
  },
];

function CaseStudyCarousel() {
  const navigate=useNavigate();

  useEffect(() => window.scrollTo(0, 0));

  return (
    <CarouselReusableComponent >
      <CarouselComp sxTitle={{fontSize:FS_40, fontWeight:"bold", color:WHITE1}}>
        {articles.map((article, index) => (
          <div onClick={()=>{navigate(`/case-study${article.link}`)}} key={index}>
            <CaseStudycard
              descriptionContent={article.description}
              headingContent={article.title}
              imageURL={article.image}
              showfooter={false}
              sxTitle={{ color: WHITE1, fontSize: FS_17,height:'7vh' }}
              sxDescription={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
               overflowY:'auto',
                textOverflow: "ellipsis",
                textAlign: "start"
              }}
            />
          </div>
        ))}
      </CarouselComp>
    </CarouselReusableComponent>
  );
}
export default CaseStudyCarousel;
