
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const ExpertLed =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img4.webp";

const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img6.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img7.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img5.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img8.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img2.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage12/Img3.webp";

const BlogsArticlesPage12 = () => {
  const description = `For many businesses, SAP implementation has become a key enabler for achieving operational excellence and increasing efficiency across different functions. Let's explore how SAP implementation can help transform businesses and drive long-term growth.`;
  const cardData = [
    {
      image: ExpertLed,
      heading: "Uniting various data sources",
      description:
        "One of the biggest advantages of SAP is that it consolidates multiple data sources into one single platform. Integrating different business processes like finance, HR, supply chain and CRM, SAP gives you a 360-degree view of your business. Unified data management allows you to make real-time decisions based on insights, breaking down silos and increasing productivity.",
      link: "",
    },
    {
      image: card2,
      heading: "Operations and SAP",
      description:
        "Whether it's automating routine tasks, standardizing processes, or enabling cross-departmental collaboration, SAP's modules are designed to streamline processes and workflows across the enterprise. From managing procurement and inventory to planning and forecasting production and sales, SAP empowers businesses to become more efficient and agile in their daily operations. With SAP implementation, businesses gain enhanced visibility into their operations at every level. Through customizable dashboards, reporting tools, and analytics capabilities, stakeholders can monitor key performance indicators in real time and identify areas for improvement. Moreover, robust security features and access controls ensure data integrity and compliance with regulatory requirements, giving organizations greater control over their processes and mitigating risks",
      link: "",
    },
    {
      image: card3,
      heading: "SAP & resource management",
      description:
        "By leveraging SAP's advanced planning and optimization tools, businesses can optimize resource allocation and utilization across their operations. Whether it's workforce scheduling, inventory management, or asset utilization, SAP helps organizations align resources with demand more effectively, reducing waste and maximizing productivity. This proactive approach to resource management enables businesses to respond swiftly to changing market dynamics and customer demands",
      link: "",
    },
    {
      image: card4,
      heading: "Upward-scalability",
      description: `As businesses grow and evolve, scalability becomes a critical factor in maintaining efficiency. SAP's modular architecture allows organizations to scale their implementation according to their changing needs, whether expanding into new markets, adding new product lines, or accommodating increased transaction volumes. Furthermore, cloud-based deployment options offer flexibility and agility, enabling businesses to adapt quickly to market trends and emerging technologies without significant infrastructure investments.`,
      link: "",
    },
    {
      image: card5,
      heading: "Phased roll-out",
      description: `SAP transformation isn't a one-off event, it's a continuous journey toward operational excellence. Through regular monitoring, analysis, and optimization, businesses can identify areas for improvement and fine-tune their processes accordingly. Whether through system upgrades, process reengineering, or employee training, organizations can continuously enhance their efficiency and competitiveness in the market.`,
      link: "",
    },
    {
      image: card6,
      heading: "Conclusion",
      description: (
        <>
          To sum up, SAP implementation represents a paradigm shift in how
          businesses operate and compete in today's digital age. By leveraging
          SAP's integrated suite of solutions, organizations can streamline
          their processes, gain actionable insights, and drive greater
          efficiency across their operations. Whether it's improving resource
          allocation, enhancing visibility, or fostering innovation, SAP
          empowers businesses to achieve their goals more effectively and
          sustainably. As businesses embark on their digital transformation
          journey, SAP stands as a trusted partner in realizing their full
          potential and staying ahead of the curve. Experience seamless business
          transformation with SAP Implementation services from{" "}
          <a
            href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>
          . Experts of
          <a
            href="https://dyashin.com/"
            target="blank"
            className="content-links fw-600"
          >
            Dyashin Technosoft
          </a>{" "}
          tailor solutions to optimize your processes, enhance efficiency, and
          unlock the full potential of SAP, ensuring a smooth transition and
          empowering your organization for sustained success.
        </>
      ),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : SAP-Unlock Business Efficiency </title>
        <meta property="og:title" content="SAP: Unlock Business Efficiency" />
        <meta
          name="description"
          content="Boost efficiency & soar ahead! Learn how SAP implementation empowers businesses to streamline & optimize."
        />
        <meta
          property="og:description"
          content="Boost efficiency & soar ahead! Learn how SAP implementation empowers businesses to streamline & optimize."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"SAP implementation enhances the efficiency of businesses."}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage12;
