import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";

import WestArrow from "../../../../assests/svg/Common/WestArrow";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const stayingAhead = S3_URL + "/v3/assestsWebp/blogsArticlesSub/stayingAhead.webp";
const card2 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage5/card2.webp'
const card3 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage5/card3.webp'
const card4 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage5/card4.webp'
const card5 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage5/card5.webp'
const mainPost = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage5/mainPost.webp'



const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";


const BlogsArticlesPage5 = () => {
  const description = (<>AR and VR technologies are increasingly used in modern society, transcending futuristic projections and becoming more prevalent in everyday life. <a href="https://www.linkedin.com/company/100918843/admin/dashboard/" target="blank" className="content-links fw-600">Dyashin Technosoft </a>  is a leading provider of augmented reality solutions, delivering immersive and interactive experiences to users across various industries. Leading in simulation and analysis, we offer comprehensive engineering services by leveraging cutting-edge technologies to maximize product performance and spur industry innovation.</>);
  const cardData = [
    {
      image: card3,
      heading: "",
      description:
      (<><a href={process.env.REACT_APP_X_URL} target="blank" className="content-links fw-600">AR integration </a>in engineering solutions enhances the visualization of complex data and models, enabling engineers to overlay 3D models onto real-world structures for structural analysis, providing a detailed understanding of stress points and potential failure zones. The analysis's accuracy is improved, enabling real-time adjustments and collaborative troubleshooting, thereby enhancing the efficiency and effectiveness of engineering projects.</>),
      link: "",
    },
    {
      image: card2,
      heading: "",
      description:
      (<>AR offers a unique platform for engineers to visualize and interact with fluid dynamics simulations, enabling them to identify potential issues and tangibly optimize designs. The immersive experience is especially helpful in sectors like aerospace, automotive, and energy, where fluid dynamics are vital to product performance and safety.</>),
      link: "",
    },
    {
      image: card5,
      heading: "",
      description:
        (<>Tailored solutions from Dyashin empower industries with precise insights, fostering innovation and enhancing overall engineering efficiency.  For instance, AR technology aids in manufacturing by creating interactive simulations of production processes, enabling engineers and operators to visualize assembly lines, identify bottlenecks, and experiment with different configurations without disrupting operations. <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Dyashin Technosoft </a> provides AR solutions in manufacturing systems to enhance problem-solving and decision-making processes by enabling the visualization and manipulation of digital twins.</>),
      link: "",
    },
    {
      image: stayingAhead,
      heading: "",
      description: (<><a href="https://dyashin.com/" target="blank" className="content-links fw-600">Dyashin Technosoft’s </a> integration of AR extends beyond traditional engineering applications. Our AR-based simulations enhance training and education by providing immersive learning experiences, allowing trainees to interact with complex machinery in a safe environment, and preparing the workforce for real-world challenges.  Further, in the healthcare sector, Dyashin’s AR surgical simulations of complex procedures for surgeons help reduce the risks associated with and enhance the training and operation of medical professionals.</>),
      link: "",
    },
    {
      image: card4,
      heading: "",
      description: (<>Hence, <a href={process.env.REACT_APP_PINTEREST_URL} target="blank" className="content-links fw-600">Dyashin Technosoft </a> is transforming industries by incorporating augmented reality into its solutions, enabling users to visualize, analyze, and optimize complex systems more effectively. Our commitment to leveraging cutting-edge technologies and expertise ensures that we deliver tailored solutions that drive innovation, enhance efficiency, and empower industries to achieve new heights of performance.</>),
      link: "",
    },

  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Augmented Reality in Engineering</title>
        <meta property="og:title" content="Augmented Reality in Engineering: Dyashin Technosoft's Visionary Integration" />
        <meta name="description" content="Step into the future with Dyashin Technosoft's augmented reality solutions, enhancing engineering, manufacturing, and healthcare with immersive, interactive experiences." />
        <meta property="og:description" content="Step into the future with Dyashin Technosoft's augmented reality solutions, enhancing engineering, manufacturing, and healthcare with immersive, interactive experiences." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Augmented Reality in Engineering: Dyashin Technosoft's Visionary Integration"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage5;
