import { Grid, Typography } from "@mui/material";
import React from "react";
import ContentSection from "../ContentSection";

function CaseStudySubContent({
  heading = "Challenge:",
  maindescription = "Prior to implementing VBA IDE solutions, the firm encountered several challenges:",
  badgetext = "",
  lists = [],
}) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <ContentSection
          badgeLabel={badgetext}
          title={heading}
          titleMuiProp="fs-24"
          textAlign="start"
        />
      </Grid>
      <Grid item xs={12} className="mb-2">
        <Typography className="fw-400 fs-12 " textAlign={"justify"}>
          {maindescription}
        </Typography>
      </Grid>
      <Grid item xs={12} className="mb-2 overflow-y-auto">
        <ol>
          {lists.map((val, idx) => (
            <li key={idx}>
              <Typography
                className="fw-400 fs-12 txt-description"
                textAlign={"justify"}
              >
                <span className="chip-title-blue">{val.heading}</span>
                {val.subheading}
              </Typography>
            </li>
          ))}
        </ol>
      </Grid>
    </Grid>
  );
}

export default CaseStudySubContent;
