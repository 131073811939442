import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const card7 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img4.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>In the fast-moving world of business today, continuous education and growth are crucial for staying ahead and being innovative. The way corporate training is conducted has changed a lot in recent times, with a growing emphasis on customized, targeted, and tech-focused methods. This article delves into the latest trends in corporate training, highlighting the innovative strategies that are transforming the landscape of learning and development.</>
  );
  const cardData = [
    {
      image: card1,
      heading: "Customized training",
      description:"The popularity of customized learning in corporate settings has skyrocketed. Thanks to improvements in data analysis and artificial intelligence, companies can now customize training programs to meet the unique needs and learning preferences of their employees. This method ensures more effective skill development and higher levels of engagement. Customized learning plays a key role in boosting employee engagement.",
      link: "",
    },
    {
      image: card2,
      heading: "Need-based training",
      description:"The one-size-fits-all model is becoming outdated. Organizations now focus on need-based training, which targets specific skill gaps and job-related needs. This approach leads to a more adaptable and skilled workforce. A survey by Deloitte found that 78% of employees view career development as a top priority, making need-based training a key factor in keeping them engaged and motivated.",
      link: "",
    },
    {
      image: card3,
      heading: "Game-based learning",
      description:"Game-based learning is a potent method that boosts involvement and the retention of information. By incorporating gaming elements into training programs, employees are more engaged and driven to finish their courses. Augmented Reality (AR) : Augmented Reality (AR) technology is widely utilized in business training to offer engaging and interactive experiences. It's particularly effective for practical training, like technical and hands-on skills.",
      link: "",
    },
    {
      image: card4,
      heading: "Learning by doing",
      description:"Learning by doing places employees in real-life scenarios where they can apply their knowledge. This practical method helps in using theories in actual-world scenarios. The American Psychological Association states that learning by doing can enhance retention rates from 20% to 90%.  Learning in small chunks : Dividing learning into smaller parts means splitting up knowledge into manageable, easy-to-understand segments. This method is gaining popularity due to its ability to fit into busy schedules and its appeal to those with short attention spans. ",
      link: "",
    },
    {
      image: card5,
      heading: "Learning apps on mobile devices",
      description:"The use of educational apps on smartphones and tablets is increasing, enabling workers to get learning materials whenever and wherever they want. This ease and availability simplify the learning process. HTML5 : Following the fall of Flash, HTML5 has risen to become the favoured medium for distributing digital education materials. It provides improved protection and adaptability on different gadgets.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>Corporate training is evolving quickly. The use of personalized learning, need-based training, gamification, AR, experiential learning, microlearning, mobile learning apps, and the shift to HTML5 are not just trends but critical elements for building a workforce that's prepared to face the challenges of the future.
        As you plan your training strategies for the upcoming year, keep in mind that the success of your organization hinges on the knowledge and skills of your employees.
        </>
      ),
      link: "",
    },
    {
      image: card7,
      heading: "",
      description:"Dyashine Technosoft is here to support you on this journey. Our tailored programs are designed to empower your team and foster innovation within your company. Discover how we can assist in enhancing your employees' skills and maintain your leadership position in these revolutionary movements. Contact us today! The achievements of your staff directly reflect your own, and we're dedicated to helping you tap into their complete capabilities within the constantly changing business environment.",
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content="The Future of Corporate Training: Key Trends to Watch"
        />
        <meta
          name="description"
          content="Explore the future of corporate training with emerging trends like AI, personalized learning, and virtual platforms that are transforming employee development."
        />
        <meta
          property="og:description"
          content="Explore the future of corporate training with emerging trends like AI, personalized learning, and virtual platforms that are transforming employee development."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;