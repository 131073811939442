import ImageSectionComponent from "../../molecules/ImageSectionComponent";
import TwoSectionGridComponent from "../../organisms/TwoSectionGridComponent";
import ContentSection from "../../molecules/ContentSection";
import { Grid, Typography } from "@mui/material";
import ManagedServices from "../ManagedServices";

function ServicesManagedServices({
  children,
  VerticalImg = "",
  HorizontalImg = "",
  description = ``,
  heading = "",
  badgeText = "",
  textAlignment = "left",
}) {
  return (
    <>
      <Grid
        container
        position={"relative"}
      >
        <Grid xs={12} sx={{ marginTop: 5 }}>
          <TwoSectionGridComponent>
            <ImageSectionComponent
              verticalImg={VerticalImg}
              horizontalImg={HorizontalImg}
            />
            <ContentSection
              badgeLabel={badgeText}
              title={heading}
              textAlign={textAlignment}
                chipMuiProps="content-chip1"
            >
              <Typography
                className="fs-12 fw-400 txt-description text-start"
                
              >
                {children}
              </Typography>
            </ContentSection>
          </TwoSectionGridComponent>
          <ManagedServices />
        </Grid>
      </Grid>
    </>
  );
}

export default ServicesManagedServices;
