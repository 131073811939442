import serviceUtil from "../utils";

const postChatbotData = (payload) => {
    console.log("service executed",payload);
    return serviceUtil
        .post(`/chat/send`, payload)
        .then((res) => {
            const data = res;
            return data;
        })
        .catch((err) => {
            const errRes = (err && err.response && err.response.data) || {
                message: "ERROR",
            };
            return errRes;
        });
};

export { postChatbotData };
