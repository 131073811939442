import { Grid } from "@mui/material";
import React from "react";
import ContactUsCrads from "../../../molecules/ContactUsCrads";

function ContentUsAddressSection() {
  return (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={10} className="py-3">
        <ContactUsCrads />
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}

export default ContentUsAddressSection;
