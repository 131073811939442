import { Grid } from "@mui/material";
import React from "react";


function IndustriesCard({
  imageURL = "",
  iconPositionStart = false,
  heading = "",
  icon = "",
  description = "",
}) {
 
  return (
    <>
      <Grid container className="h-100 inudtries-card">
        <Grid item xs={12} alignContent={"end"}>
          <img
            src={imageURL}
            alt="iot testing"
            width={"100%"}
            height={"100%"}
          />
        </Grid>
        <Grid item container xs={12}>
          {iconPositionStart ? (
            <>
              <Grid
                item
                xs={3}
                justifyContent={"center"}
                borderRadius={"3px"}
                className="lightblue-background p-2 d-flex align-items-center-justify-content-center inudtries-card-iconBgColor"
              >
                <img src={icon} alt="" width={"80%"} />
              </Grid>
              <Grid
                item
                xs={9}
                className="fw-700 fs-18 px-3 "
                alignContent={"center "}
              >
                {heading}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={9}
                className="fw-700 fs-18 px-3 sirvice-card"
                alignContent={"center "}
              >
                {heading}
              </Grid>
              <Grid
                item
                xs={3}
                justifyContent={"center"}
                borderRadius={"3px"}
                className="lightblue-background p-2 d-flex align-items-center-justify-content-center inudtries-card-iconBgColor"
              >
                <img src={icon} alt="" width={"70%"} />
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className="fw-400 fs-12 txt-description px-3 pt-1 inudtries-card-text text-start"
          sx={{ lineHeight: "20px !important",}}
          height={"74%"}
          
        >
          {description}
        </Grid>
      </Grid>
    </>
  );
}

export default IndustriesCard;
