
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const ExpertLed =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage14/imG4.webp";
const card2 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage14/imG2.webp";
const card4 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage14/imG5.webp";
const card3 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage14/imG6.webp";
const mainPost =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage14/imG3.webp";

const BlogsArticlesPage14 = () => {
  const description = (
    <>
      With its innovative platforms and teaching strategies, contemporary
      technology continues to shape education. LMSs have demonstrated their
      worth, and the next generation of learning management systems (LMS) is
      poised to transform knowledge acquisition and sharing.Leading this effort
      is{" "}
      <a
        href="https://dyashin.com/"
        target="blank"
        className="content-links fw-600"
      >
        Dyashin TechnoSoft's
      </a>{" "}
      innovative learning management system DSEdify, which reinvents practical
      learning via innovation.
    </>
  );
  
  const cardData = [
    {
      image: ExpertLed,
      heading: "",
      description: (
        <>
          <a
            href="https://dsedify.com/"
            target="blank"
            className="content-links fw-600"
          >
            DSEdify
          </a>{" "}
          from{" "}
          <a
            href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin TechnoSoft
          </a>{" "}
          is meticulously designed to address the imperative of effective
          knowledge sharing, transcending the standard features of traditional
          LMS platforms. DSEdify's unique feature is its integration of coding
          challenges, which enhance practical learning by allowing learners to
          apply theoretical knowledge in real-world scenarios, fostering deeper
          understanding and skill acquisition. The examination modules of this
          LMS are robust, ensuring comprehensive and accurate evaluations of
          learner capabilities across various testing formats. A thorough
          evaluation is essential for identifying areas of strength and room for
          development so that the learning process can be more focused and
          efficient.
        </>
      ),
      link: "",
    },
    {
      image: card2,
      heading: "",
      description: (
        <>
          The internal content creation team of{" "}
          <a
            href={process.env.REACT_APP_BLOGSPOT_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin TechnoSoft
          </a>{" "}
          puts in incessant efforts to provide excellent instructional
          materials, thus making the content ecosystem of DSEdify a key
          differentiator. The team guarantees that{" "}
          <a
            href="https://dsedify.com/"
            target="blank"
            className="content-links fw-600"
          >
            DSEdify
          </a>{" "}
          offers a wide variety of videos spanning technical and non-technical
          themes, in addition to strategic partnerships with top-tier content
          creators worldwide. This range of topics not only enhances the
          learning process but also meets the various needs of learners in
          multiple fields of study or domains.
        </>
      ),
      link: "",
    },
    {
      image: card3,
      heading: "",
      description: (
        <>
          In light of DSEdify's comprehensive approach, students are actively
          participating in a dynamic learning process rather than only digesting
          information.{" "}
          <a
            href={process.env.REACT_APP_QUORA_URL}
            target="blank"
            className="content-links fw-600"
          >
            Dyashin TechnoSoft's
          </a>{" "}
          platform enhances education and skill development through its design
          that promotes continuous interaction and practical application,
          blending innovation and knowledge dissemination. Teams can be
          empowered to transcend traditional boundaries by utilizing a dynamic
          LMS. It’s about providing a platform where learning is not confined to
          theoretical knowledge but extended to practical skills that are
          crucial in today’s competitive environment.
        </>
      ),
      link: "",
    },
    {
      image: card4,
      heading: "",
      description: (
        <>
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600"
          >
            DSEdify
          </a>{" "}
          is more than an LMS; it's an educational ecosystem that equips
          students with the difficulties of the real world.Hence,{" "}
          <a
            href="https://dyashin.com/products"
            target="blank"
            className="content-links fw-600"
          >
            Dyashin TechnoSoft's DSEdify
          </a>{" "}
          is a next-generation LMS, offering innovative, practical learning, and
          comprehensive knowledge sharing. It integrates advanced features like
          coding challenges and robust assessments with high-quality content,
          making it an invaluable asset for organizations and educational
          institutions.
        </>
      ),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Dyashin : Empowering Education</title>
        <meta
          property="og:title"
          content="Empowering Education: DSEdify's Innovative Approach to Practical Learning"
        />
        <meta
          name="description"
          content=" Join the educational revolution with DSEdify by Dyashin Techno Soft, where innovative LMS features and practical coding challenges meet to redefine learning."
        />
        <meta
          property="og:description"
          content=" Join the educational revolution with DSEdify by Dyashin Techno Soft, where innovative LMS features and practical coding challenges meet to redefine learning."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "The Next Generation of LMS: How DSEdify is Enhancing Practical Learning through Innovation"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage14;
