import { Box,} from "@mui/material";
import React from "react";

import EastArrow from "../../../assests/svg/Common/EastArrow/index";
import COLORS from "../../../constants/colorConstants";

const {BLUE3} = COLORS

function FarwarButton({
  btnBgColor= BLUE3,
  btnHeigth='30px',
  btnWidth='42px',
  iconColor='black',
  className="",
  icon=<EastArrow svgcolor={iconColor} />,
  onBtnClick=()=>{}
}) {
  return (
    <Box
      height={btnHeigth}
      width={btnWidth}
      borderRadius={"15px"}
      bgcolor={btnBgColor}
      className={`d-flex justify-content-center align-items-center ${className}`}
      onClick={onBtnClick}
      sx={{cursor:"pointer"}}
    >
      {icon}
    </Box>
  );
}

export default FarwarButton;
