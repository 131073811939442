import React from 'react'
import COLORS from '../../../../constants/colorConstants'
const {BLUE1} = COLORS

function EastArrow({
    svgcolor=BLUE1
}) {
  
  return (
    <>
    <svg width="18" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8125 4.4375L12.5469 7.6875C12.4219 7.8125 12.2734 7.875 12.1016 7.875C11.9297 7.875 11.7865 7.8125 11.6719 7.6875C11.5469 7.5625 11.4844 7.41406 11.4844 7.24219C11.4844 7.07031 11.5469 6.92708 11.6719 6.8125L13.8594 4.625L0.625001 4.625C0.447917 4.625 0.29948 4.5651 0.179688 4.44531C0.0598966 4.32552 7.08489e-07 4.17708 6.77527e-07 4C6.46564e-07 3.82292 0.0598965 3.67448 0.179688 3.55469C0.29948 3.4349 0.447917 3.375 0.625001 3.375L13.8594 3.375L11.6719 1.1875C11.5469 1.07291 11.4844 0.929685 11.4844 0.75781C11.4844 0.585935 11.5469 0.437498 11.6719 0.312498C11.7865 0.187498 11.9297 0.124998 12.1016 0.124998C12.2734 0.124998 12.4219 0.187498 12.5469 0.312498L15.8125 3.5625C15.9375 3.67708 16 3.82291 16 4C16 4.17708 15.9375 4.32291 15.8125 4.4375Z" fill={svgcolor}/>
</svg>
</>
  )
}

export default EastArrow