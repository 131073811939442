import { Grid } from "@mui/material";
import React from "react";
import ServiceCard from "../ServiceCard";
import ContentSection from "../ContentSection";
import COLORS from "../../../constants/colorConstants";
const S3_URL = process.env.REACT_APP_S3_URL;
const OffCampus = S3_URL+"/v3/assestsWebp/services/OffCampus.webp";
const corporate = S3_URL+"/v3/assestsWebp/services/corporate.webp";
const campus = S3_URL+"/v3/assestsWebp/services/campus.webp";

const {PURPLE2} = COLORS


function AccelerateLearningServices() {
  const values = [
    {
      imageURL: corporate,
      heading: "Corporate",
      link:"/corporate",
      description:
        "Our Corporate Learning Program is a meticulously curated skill development initiative aligned with...",
    },
    {
      imageURL: campus,
      heading: "Campus",
      link:"/campus",
      description:
        "Our campus programs offer a comprehensive experience, featuring tech-enabled learning, industry-relevant...",
    },
    {
      imageURL: OffCampus,
      heading: "Off Campus",
      link:"/off-campus",
      description:
        "Dyashin offers unique solutions catering to the retail segment, providing candidates with technology proficiency,...",
    },
  ];
  return (
    <Grid container className="mt-5" paddingTop={{md:40}}>
      <div id="learning-services">
      <ContentSection title="Learning Services" badgeLabel="Accelerate"   chipMuiProps="content-chip1">
        <Grid container item xs={12} >
          {values.map((value, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ServiceCard
              link={`/learning-services${value.link }`}
                imageURL={value.imageURL}
                title={value.heading}
                description={value.description}
                showIcon={true}
                titleMuiProps="fw-700 fs-20 heading-color"
                headingMuiProps="fs-10"
                descriptionMuiProps="fw-400 fs-12 card-text-color"
                sxDescription={{ color: PURPLE2 }}
                contentMuiprops="learning-Services-content overflow-y-auto"
                cardmuimargin={{xs:'30%',sm:'30%',md:'80%',lg:'70%', xl:'60%'}}
              />
            </Grid>
          ))}
        </Grid>
      </ContentSection>
      </div>
    </Grid>
  );
}

export default AccelerateLearningServices;
