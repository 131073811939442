import React from "react";
import { Box } from "@mui/material";
import "../../../styles/imageSection.scss";
const S3_URL = process.env.REACT_APP_S3_URL;
const Static =
  S3_URL+"/v3/assestsWebp/common/dotsSquare.webp";
const BlackCap =
  S3_URL+"/v3/assestsWebp/common/blackCapsuleImg.webp";
const BlueCap =
  S3_URL+"/v3/assestsWebp/common/blueCapsuleImg.webp";

function ImageSectionComponent({ verticalImg, horizontalImg }) {
  return (
    <>
      <Box container marginLeft={"10%"}>
        <div className="image-container">
          <div className="vertical">
            <img src={verticalImg} alt="Vertical_Image" />
          </div>
          <div className="static dots-image">
            <img src={Static} alt="Static_Image" />
          </div>
          <div className="horizontal">
            <img src={horizontalImg} alt="Horizontal_Image" />
          </div>
          <div className="black-cap black-image">
            <img src={BlackCap} alt="BlackCap_Image" />
          </div>
          <div className="blue-cap-small black-image">
            <img src={BlueCap} alt="BlueCapSmall_Image" />
          </div>
          <div className="blue-cap blue-image">
            <img src={BlueCap} alt="BlueCap_Image" />
          </div>
        </div>
      </Box>
      
    </>
  );
}

export default ImageSectionComponent;
