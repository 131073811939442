import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ProductsCard from "../../../molecules/ProductsCard";
import ContentSection from "../../../molecules/ContentSection";
import RoundedRectangleBox from "../../../atoms/RoundedRectangleBox";
import COLORS from "../../../../constants/colorConstants";
const S3_URL = process.env.REACT_APP_S3_URL;
const EDIFY_URL = process.env.REACT_APP_EDIFY_URL;

const dsEdify =
  S3_URL+"/v3/assestsWebp/home/edify.webp";
const dsJobby =
  S3_URL+"/v3/assestsWebp/home/Jobby.webp";
const dsPristine =
  S3_URL+"/v3/assestsWebp/home/pristine.webp";
const dsSigo =
  S3_URL+"/v3/assestsWebp/home/sigoLogo.webp";
const callIcon =
  S3_URL+"/v3/assestsWebp/home/callIcon.webp";


  const {BLUE1, BLUE5, WHITE1, BLUE2} = COLORS
function OurProducts() {
  const products = [
    {
      image: dsJobby,
      description: "Job Portal",
      footer: "READ MORE",
      link: "/products/job-portal",
      isProduct:false,
    },
    {
      image: dsEdify,
      description: "LMS Platform",
      footer: "READ MORE",
      link: EDIFY_URL,
      isProduct:true,
    },
    {
      image: dsPristine,
      description: "Micro Suite ERP",
      footer: "READ MORE",
      link: "/products/erp",
      isProduct:false,
    },
    {
      image: dsSigo,
      description: "E-commerce Platform",
      footer: "READ MORE",
      link: "/products/ecommerce",
      isProduct:false,
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        position={"relative"}
        sx={{
          backgroundColor: BLUE5,
          mb: {xs:"20%",sm:"10%"},
          paddingBottom: {md:"10%",sm:"15%",xs:"35%"},
        }}
      >
        <Grid
          item
          xs={1}
          md={2}
          sx={{ paddingTop: "0%", position: "relative", overflowY: "hidden" }}
        >
          {!isSmallScreen && (
            <RoundedRectangleBox
              animationClassName="grey-capsule"
              height="85%"
              width="88%"
              top="-30%"
              left="-25%"
              zIndex={2}
              backgroundColor={BLUE1}
              sx={{ transform: "rotate(-45deg)" }}
            />
          )}
        </Grid>
        <Grid item container xs={10} md={8} paddingTop={{md:"3%",sm:"5%",xs:"7%"}}>
          <ContentSection
            badgeLabel="PRODUCTS WE OFFER"
            title="Our Products"
            sxDescription={{ textAlign: "justify", paddingX: 2 }}
            description="Enhance skills and knowledge across education, corporate training, and personal development with our versatile learning products. Benefit from innovative tools, high-quality content, and comprehensive assessment modules designed to foster growth and achieve excellence in various verticals. Empower learners and educators with effective and engaging learning solutions."
            sxChip={{ backgroundColor: WHITE1 }}
            badgeTextColor={BLUE2}
            badgeBgColor=""
             chipMuiProps="content-chip2"
          >
            {products.map((product, idx) => (
              <Grid
                item
                md={3}
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
                key={idx}
              >
                <ProductsCard
                  link={`${product.link}`}
                  image={product.image}
                  title1={product.description}
                  cardBgColor= {WHITE1}
                  hoverEffect={true}
                   cardheightclass="ourProducts-card-height"
                   isProduct={product.isProduct}
                />
              </Grid>
            ))}
          </ContentSection>

          <Grid
            item
            xs={10}
            md={8}
            container
            padding={2}
            top="90%"
            position={"absolute"}
            sx={{ backgroundColor: BLUE1 }}
          >
            <Grid item md={2}></Grid>

            <Grid item container md={8}>
              <Grid
                item
                sm={6}
                className="fw-400 fs-10 py-1 text-white d-flex align-items-center"
              >
                <Typography className="fw-400 fs-18">
                  Get your <b style={{ color: BLUE2 }}>Free</b> business
                  consultation.
                </Typography>
              </Grid>
              <Grid item container sm={6}>
                <Grid item xs={4} className="d-flex align-items-center">
                  <img src={callIcon} alt="" />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    className="fw-400 fs-18 py-1"
                    sx={{ color: WHITE1 }}
                  >
                    +1 &nbsp;&nbsp;302 279 0052
                    <br />
                    +91 960 691 5923
                  </Typography>
                  <Typography
                    className="fw-400 fs-16 py-1"
                    sx={{ color: BLUE2 }}
                  >
                    info@dyashin.com
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={1}
              md={2}
              sx={{
                paddingTop: "0%",
                position: "relative",
                overflowY: "hidden",
              }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
          sx={{ paddingTop: "0%", position: "relative" }}
        >
          {!isSmallScreen && (
            <>
              <RoundedRectangleBox
                animationClassName="triangle-animation"
                height="40%"
                width="40%"
                top="105%"
                left="25%"
                backgroundColor= {WHITE1}
                sx={{ transform: "rotate(-45deg)" }}
              />
              <RoundedRectangleBox
                animationClassName="triangle-animation"
                height="60%"
                width="60%"
                top="75%"
                left="65%"
                backgroundColor= {BLUE2}
                sx={{ transform: "rotate(-45deg)" }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default OurProducts;
