import React, { useState } from "react";
import ContentSection from "../../../molecules/ContentSection";
import { Divider, Grid, Typography } from "@mui/material";
import TabsCard from "../../../molecules/TabsCard";
import ProductDesignIcon from "../../../../assests/svg/SoluctionPage/ProductDesignIcon";
import CadModellingIcon from "../../../../assests/svg/SoluctionPage/CadModellingIcon";
import PipingPlantIcon from "../../../../assests/svg/SoluctionPage/PipingPlantIcon";
import SimulationIcon from "../../../../assests/svg/SoluctionPage/SimulationIcon";
import COLORS from "../../../../constants/colorConstants";

const {BLUE2, WHITE1} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;
const background =
  S3_URL+"/v3/assestsWebp/solutions/enggServicesBgImg.webp";

function EngineeringServices() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  let tabsdata = [
    {
      icon: (
        <ProductDesignIcon
          svgcolor={
            selectedIndex === 0
              ? WHITE1
              : BLUE2
          }
        />
      ),
      iconContent: "Product Design",
      description: (<>Our <a href="https://dyashin.com/services" target="blank" className="content-links fw-600">Product Design Engineering Services </a> embody innovation and precision. With a commitment to excellence, our seasoned team of engineers utilizes cutting-edge technology to craft products that not only meet but exceed client expectations. From concept to realization, we specialize in delivering solutions that stand at the forefront of technological advancement, ensuring your ideas materialize with efficiency and ingenuity.</>),
    },
    {
      icon: (
        <CadModellingIcon
          svgcolor={
            selectedIndex === 1
              ? WHITE1
              : BLUE2
          }
        />
      ),
      iconContent: "CAD Modelling",
      description: (<>We excel in delivering high-quality CAD Modelling services as part of our comprehensive <a href=" https://dyashin.com/solutions" target="blank" className="content-links fw-600">Engineering Solutions </a>. Our expert team utilizes cutting-edge technology to provide precise and efficient modelling, ensuring your projects meet industry standards. From conceptualization to detailed designs, we bring precision to every aspect, elevating your engineering endeavors with our commitment to excellence.</>),
    },
    {
      icon: (
        <PipingPlantIcon
          svgcolor={
            selectedIndex === 2
              ? WHITE1
              : BLUE2
          }
        />
      ),
      iconContent: "Piping / Plant Engineering",
      description: (<>Our Piping/Plant <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">Engineering services </a> stand as a hallmark of excellence in the field. With a dedicated team of skilled engineers, we offer comprehensive solutions in plant design, piping layout, and engineering. Our commitment to precision and innovation ensures that we deliver cutting-edge engineering services that meet and exceed the industry's highest standards.</>),
    },
    {
      icon: (
        <SimulationIcon
          svgcolor={
            selectedIndex === 3
              ? WHITE1
              : BLUE2
          }
        />
      ),
      iconContent: "Simulation & Analysis",
      description: (<>We excel in Simulation & Analysis, offering comprehensive <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">engineering services </a>. Leveraging cutting-edge technologies and expertise, our team ensures accurate and insightful simulations to optimize product performance. From structural analysis to fluid dynamics, we deliver tailored solutions, empowering industries with precise insights, fostering innovation, and enhancing overall engineering efficiency.</>),
    },
  ];
  return (
    <>
      <Grid
        container
        paddingTop={7}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={0.5} sm={1} md={2}></Grid>
        <Grid item container xs={11} sm={10} md={8}>
          <div id="engineering-services">
            <ContentSection
              badgeLabel="Solutions"
              title="Engineering Services"
              titleMuiProp="text-white fs-40"
              badgeBgColor="bg-light"
              badgeTextColor= {BLUE2}
              sxTitle={{ color: WHITE1 }}
              chipMuiProps="content-chip1"
            >
              {tabsdata.map((val, idx) => (
                <Grid item xs={3} paddingX={1}>
                  <TabsCard
                    key={idx}
                    icon={val.icon}
                    index={idx}
                    iconContent={val.iconContent}
                    onCardClick={(index) => {
                      setSelectedIndex(index);
                    }}
                    selected={selectedIndex === idx}
                  />
                </Grid>
              ))}
            </ContentSection>
          </div>
        </Grid>
        <Grid item xs={0.5} sm={1} md={2}></Grid>
      </Grid>
      <Grid container>
        <Grid item md={3} xs={1}></Grid>
        <Grid item md={6} xs={10} className="py-5 d-flex flex-column ">
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="gradient-border"
            sx={{
              height: "1px",
              width: "180px",
              mx: "auto",
            }}
          />
          <Typography
            className="fw-400 fs-12 txt-description px-2 mt-1"
            textAlign={"justify"}
          >
            {tabsdata[selectedIndex].description}
          </Typography>
        </Grid>
        <Grid item md={3} xs={1}></Grid>
      </Grid>
    </>
  );
}

export default EngineeringServices;
