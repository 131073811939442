import React from "react";

function CadModellingIcon({ svgcolor = "blue" }) {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_423_1681"
          maskUnits="userSpaceOnUse"
          x="1"
          y="3"
          width="48"
          height="44"
        >
          <path
            d="M13.1348 12.0996C13.252 12.5195 13.6329 12.8027 14.0626 12.8027C14.1505 12.8027 14.2384 12.793 14.3165 12.7637C14.8243 12.627 15.127 12.0996 14.9903 11.5918L14.9317 11.3867L15.9571 11.9824C16.1817 12.1094 16.4356 12.1484 16.6895 12.0801C16.8751 12.0312 17.0313 11.9336 17.1485 11.7969V20.0586C17.1485 20.4199 17.3634 20.7617 17.6759 20.918L24.4434 24.8242C24.5997 24.9316 24.795 24.9902 24.9903 24.9902C25.1856 24.9902 25.3712 24.9316 25.5372 24.8242L28.6036 23.0566L32.3438 20.8984C32.5391 20.791 32.6856 20.6152 32.7638 20.4102H32.8321V12.1387C32.8321 11.7773 32.627 11.4355 32.3048 11.2793L25.4884 7.32422C25.1856 7.14844 24.8145 7.14844 24.5118 7.32422L17.6466 11.2891C17.4513 11.3965 17.3048 11.5723 17.2266 11.7773H17.1778C17.2169 11.7285 17.2559 11.6797 17.2852 11.6309C17.4122 11.4062 17.4513 11.1523 17.3829 10.8984C17.3145 10.6543 17.1583 10.4395 16.9337 10.3125L15.9083 9.7168L16.1134 9.6582C16.3575 9.58984 16.5723 9.43359 16.6993 9.20898C16.8263 8.98438 16.8653 8.73047 16.797 8.47656C16.7286 8.23242 16.5723 8.01758 16.3477 7.89063C16.1231 7.76367 15.8692 7.72461 15.6153 7.79297L13.1641 8.44727C12.92 8.51562 12.7052 8.67187 12.5782 8.89648C12.4513 9.12109 12.4122 9.375 12.4805 9.62891L13.1348 12.0996ZM20.0587 12.1289L25.0001 9.26758L29.9415 12.1191L25.0001 14.9805L20.0587 12.1289ZM25.9669 22.3535V16.6406L30.9083 13.7891V19.502L25.9669 22.3535ZM19.0919 19.502V13.7891L24.0333 16.6406V22.3535L19.0919 19.502Z"
            fill={svgcolor}
          />
          <path
            d="M6.40625 27.5293H11.3574C11.8945 27.5293 12.3242 27.0996 12.3242 26.5625C12.3242 26.0254 11.8945 25.5957 11.3574 25.5957H6.40625C5.86914 25.5957 5.43945 26.0254 5.43945 26.5625C5.43945 27.0996 5.87891 27.5293 6.40625 27.5293Z"
            fill={svgcolor}
          />
          <path
            d="M26.7969 26.7188C26.543 26.7188 26.2988 26.8164 26.1133 27.002L25.9668 27.1484V25.9668C25.9668 25.4297 25.5371 25 25 25C24.4629 25 24.0332 25.4297 24.0332 25.9668V27.1484L23.8867 27.002C23.5059 26.6211 22.9004 26.6211 22.5195 27.002C22.1387 27.3828 22.1387 27.9883 22.5195 28.3691L24.3066 30.1562C24.4922 30.3418 24.7363 30.4492 25 30.4492C25.2539 30.4492 25.498 30.3516 25.6738 30.1758L27.4707 28.3789C27.6562 28.1934 27.7539 27.959 27.7539 27.6953C27.7539 27.4316 27.6562 27.1973 27.4707 27.0117C27.2949 26.8164 27.0508 26.7188 26.7969 26.7188Z"
            fill={svgcolor}
          />
          <path
            d="M6.40625 30.5273H11.3574C11.8945 30.5273 12.3242 30.0977 12.3242 29.5605C12.3242 29.0234 11.8945 28.5938 11.3574 28.5938H6.40625C5.86914 28.5938 5.43945 29.0234 5.43945 29.5605C5.43945 30.0977 5.87891 30.5273 6.40625 30.5273Z"
            fill={svgcolor}
          />
          <path
            d="M33.3009 12.0899C33.5451 12.1583 33.8087 12.1192 34.0333 11.9923L35.0587 11.3966L35.0001 11.6016C34.8634 12.1192 35.1759 12.6466 35.6935 12.7833C35.7716 12.8028 35.8497 12.8126 35.9376 12.8126C36.3673 12.8126 36.7579 12.5196 36.8751 12.0997L37.5294 9.64852C37.5978 9.40438 37.5587 9.14071 37.4318 8.9161C37.3048 8.69149 37.09 8.53524 36.8458 8.46688L34.3947 7.81258C34.1505 7.74422 33.8868 7.78328 33.6622 7.91024C33.4376 8.03719 33.2814 8.25203 33.213 8.49617C33.1447 8.74032 33.1837 9.00399 33.3107 9.2286C33.4376 9.45321 33.6525 9.60946 33.8966 9.67782L34.1017 9.73641L33.0763 10.3321C32.6173 10.5958 32.4611 11.1817 32.7247 11.6505C32.8419 11.8556 33.0568 12.0216 33.3009 12.0899Z"
            fill={svgcolor}
          />
          <path
            d="M5.6543 36.6797H5.81055C6.34766 36.6797 6.77734 36.25 6.77734 35.7129C6.77734 35.1758 6.34766 34.7461 5.81055 34.7461H5.6543C5.11719 34.7461 4.6875 35.1758 4.6875 35.7129C4.6875 36.25 5.12695 36.6797 5.6543 36.6797Z"
            fill={svgcolor}
          />
          <path
            d="M47.7148 3.57422H2.27539C1.69922 3.57422 1.2207 4.05273 1.2207 4.63867V37.9883C1.2207 38.5742 1.69922 39.0527 2.28516 39.0527H20.1367V40.9766H14.4531C13.7402 40.9766 13.0371 41.2695 12.5293 41.7773C11.7383 42.5684 11.5137 43.7012 11.9434 44.7363C12.373 45.7812 13.3398 46.4258 14.4531 46.4258H35.5469C36.2598 46.4258 36.9629 46.1328 37.4707 45.625C38.2617 44.834 38.4863 43.7012 38.0566 42.666C37.627 41.6211 36.6602 40.9766 35.5469 40.9766H29.8633V39.0527H47.7246C48.3008 39.0527 48.7793 38.5742 48.7793 37.9883V4.63867C48.7793 4.05273 48.3008 3.57422 47.7148 3.57422ZM22.0703 40.9766V39.0527H27.9297V40.9766H22.0703ZM13.7207 43.3984C13.8184 43.1641 14.0625 42.9004 14.4531 42.9004H35.5469C35.7617 42.9004 35.957 42.9883 36.1035 43.1348C36.377 43.4082 36.377 43.7598 36.2793 43.9941C36.1816 44.2285 35.9375 44.4922 35.5469 44.4922H14.4531C14.248 44.4922 14.043 44.4043 13.8965 44.2578C13.6133 43.9844 13.623 43.6328 13.7207 43.3984ZM46.8457 34.0625V37.1191H3.1543V34.0625H46.8457ZM46.8457 5.50781V32.1289H3.1543V5.50781H46.8457Z"
            fill={svgcolor}
          />
        </mask>
        <g mask="url(#mask0_423_1681)">
          <rect x="-5" y="-5" width="60" height="60" fill={svgcolor} />
        </g>
      </svg>
    </>
  );
}

export default CadModellingIcon;
