import { Grid } from "@mui/material";
import React from "react";
import ContactusCard from "../ContactusCard";
const S3_URL = process.env.REACT_APP_S3_URL;
const indiaflag =
  S3_URL+"/v3/assestsWebp/contactUs/indiaSquareFlag.webp";
const usflag =
  S3_URL+"/v3/assestsWebp/contactUs/usSquareFlag.webp";

function ContactUsCrads() {
  const carddata = [
    {
      Address1: `Dyashin Technosoft LLC`,
      Address2: `151 Irongable Drive, Middletown,Delaware `,
      Address3: `DE - 19709, USA`,
      PhoneNumber: "+1 302 279 0052",
      Email: "info@dyashin.com",
      flag: usflag,
    },
    {
      Address1: `Dyashin Technosoft Private Limited`,
      Address2: `#32/33/5B, 1st Floor,Annapurna Block II, Kanakapura Road,J.P.Nagar,`,
      Address3: `Bengaluru-560 078, India`,
      PhoneNumber: "+91 960 691 5923",
      Email: "info@dyashin.com",
      flag: indiaflag,
    },
  ];
  return (
    <Grid container>
      <Grid container item xs={12} className="justify-content-between p-3">
        {carddata.map((val, idx) => (
          <Grid
            item
            key={idx}
            xs={12}
            sm={5.8}
            md={5.8}
            paddingBottom={{ xs: 5 }}
            
          >
            <ContactusCard
              Address1={val.Address1}
              Address2={val.Address2}
              Address3={val.Address3}
              email={val.Email}
              flag={val.flag}
              phoneNumber={val.PhoneNumber}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ContactUsCrads;
