import { Box, Grid } from "@mui/material";
import React from "react";

function ConsultingImageSection({ imageURL = "", isSmallScreen }) {
  return (
    <Grid container className="h-100 w-100">
      <Grid
        item
        xs={12}
        className="h-100 w-100 d-flex justify-content-center align-items-center"
      >
        <Box
          height={"28.93vh"}
          width={"100%"}
          sx={{
            backgroundImage: `url(${imageURL})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Box>
      </Grid>
    </Grid>
  );
}

export default ConsultingImageSection;
