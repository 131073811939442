import React, { useRef } from "react";
import Slider from "react-slick";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

import CarouselShape1 from "../../../../assests/CarouselAnimation/CarouselShape1.png";
import CarouselShape2 from "../../../../assests/CarouselAnimation/CarouselShape2.png";
import COLORS from "../../../../constants/colorConstants";

const S3_URL = process.env.REACT_APP_S3_URL;
const About = S3_URL + "/v3/assestsWebp/home/aboutusCarousel.webp";
const Services = S3_URL + "/v3/assestsWebp/home/servicesCarousel.webp";
const Industry = S3_URL + "/v3/assestsWebp/home/industries.webp";
const Products = S3_URL + "/v3/assestsWebp/home/carouselProducts.webp";
const Solutions = S3_URL + "/v3/assestsWebp/home/carouselSolutions.webp";
const CarouselShape3 = S3_URL + "/v3/assestsWebp/home/CarouselShape3.webp";

const {BLACK2, WHITE1, BLUE2} = COLORS

function HomePageCarousel() {
  const navigate = useNavigate();
  const data = [
    {
      heading: "About",
      description:
        "We provide 360-degree solutions with cutting-edge technology, offering unique solutions. Our portfolio includes learning, consulting, and project services, ensuring client satisfaction and success.",
      imageURL: About,
      link: "/our-company",
    },
    {
      heading: "Services",
      description:
        "We offer services in Learning, Consulting, and Projects. Learning includes Retail, Campus, and Corporate. Consulting covers Onetime Hire, Contract, and Contract to Hire. Project services include Fixed Bid and Time & Material models.",
      imageURL: Services,
      link: "/services",
    },
    {
      heading: "Products",
      description:
        "We stands at the forefront of technology, offering cutting-edge products that deliver unique solutions for both B2B and B2C sectors. Our innovative solutions address diverse customer needs, setting new standards in the industry.",
      imageURL: Products,
      link: "/products",
    },
    {
      heading: "Solutions",
      description:
        'We provide diverse IT solutions, with innovation at its core. Adhering to the principle of "doing the same thing in a more innovative manner," our solutions, though similar in name, stand apart with unique innovation embedded within.',
      imageURL: Solutions,
      link: "/solutions",
    },
    {
      heading: "Industries",
      description:
        "We cater to diverse industries' IT needs, leveraging subject matter experts to address specific requirements promptly and deliver substantial returns on investment for our clients.",
      imageURL: Industry,
      link: "/industries",
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => (
      <div>
        <ul
          className="homepage-carousal "
          style={{
            marginBottom: "60px",
            display: "flex",
            justifyContent: "center",
            padding: "0px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };

  return (
    <Box sx={{ overflow: "hidden", height: "600px", position: "relative" }}>
      <Slider ref={sliderRef} {...settings} className="slick-animate">
        {data.map((val, index) => (
          <Grid
            container
            className="position-relative"
            key={index}
            sx={{ height: "100%" }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                backgroundImage: `url(${val.imageURL})`,
                backgroundSize: index ? "cover" : "100% 100%",
                backgroundPosition: "center",
                position: "relative",
                height: "540px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor:  BLACK2,
                  zIndex: 1,
                }}
              ></Box>

              {!isSmallScreen && (
                <Box
                  sx={{
                    position: "absolute",
                    marginLeft: "55%",
                    opacity: "70%",
                    zIndex: 2,
                  }}
                >
                  <Slide direction="down">
                    <img
                      src={CarouselShape3}
                      alt=""
                      style={{ height: "180px" }}
                    />
                  </Slide>
                </Box>
              )}
              <Grid item md={2} xs={1}></Grid>
              <Grid item container md={8} xs={10} paddingY={{ sm: 10, xs: 5 }}>
                <Grid item xs={12} md={8} sx={{ zIndex: "20" }}>
                  <Slide direction="down">
                    <Typography
                      color= {WHITE1}
                      className="fw-700 fs-45 text-white text-left py-2"
                      style={{ textAlign: "left" }}
                    >
                      {val.heading}
                    </Typography>
                    <Typography
                      color= {WHITE1}
                      className="fw-400 fs-18 text-white text-left mb-5"
                      style={{ textAlign: "left", lineHeight: "200%" }}
                    >
                      {val.description}
                    </Typography>
                  </Slide>
                  <Slide direction="up">
                    {index >= 0 && (
                      <Button
                        className="mt-3"
                        variant="contained"
                        onClick={() => navigate(val.link)}
                        sx={{
                          borderRadius: "30px",
                          paddingX: "5%",
                          paddingY: "2%",
                          backgroundColor: BLUE2,
                        }}
                      >
                        Discover More
                      </Button>
                    )}
                  </Slide>
                </Grid>
              </Grid>
              <Grid item md={2} xs={10}></Grid>
              {!isSmallScreen && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      opacity: "70%",
                      bottom: "0%",
                      right: isMediumScreen ? "15%" : "12%",
                      zIndex: 2,
                    }}
                    className="d-flex me-5"
                  >
                    <Slide direction="up" delay={500}>
                      <img
                        src={CarouselShape1}
                        alt=""
                        style={{ height: "120px" }}
                      />
                    </Slide>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      opacity: "70%",
                      bottom: "0%",
                      right: "0%",
                      zIndex: 2,
                    }}
                    className="d-flex"
                  >
                    <Slide direction="up" delay={500}>
                      <img
                        src={CarouselShape2}
                        alt=""
                        style={{ height: "300px" }}
                      />
                    </Slide>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
}

export default HomePageCarousel;
