import React from "react";

function Developers({svgcolor="#24ABE3"}) {
  return (
    <>
      <svg
        width="60"
        height="61"
        viewBox="0 0 60 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.09073 36.5909C6.54528 36.5909 6.18164 36.2273 6.18164 35.6818V29.3182C6.18164 28.7728 6.54528 28.4091 7.09073 28.4091C7.63619 28.4091 7.99982 28.7728 7.99982 29.3182V35.6818C7.99982 36.2273 7.63619 36.5909 7.09073 36.5909Z"
          fill={svgcolor}
        />
        <path
          d="M13.1816 36.6818H6.54528C4.18164 36.6818 2.18164 34.6818 2.18164 32.3182V27.2273C2.18164 27.0455 2.18164 26.8636 2.27255 26.6818C2.27255 26.5909 2.27255 26.5 2.27255 26.4091C2.27255 25.8636 2.63619 25.5 3.18164 25.5C3.7271 25.5 4.09073 25.8636 4.09073 26.4091C4.09073 26.5909 4.09073 26.7727 3.99982 26.9545V27.2273V32.3182C3.99982 33.6818 5.18164 34.8636 6.54528 34.8636H13.1816C13.7271 34.8636 14.0907 35.2273 14.0907 35.7727C14.0907 36.3182 13.7271 36.6818 13.1816 36.6818Z"
          fill={svgcolor}
        />
        <path
          d="M3.72714 27.1364C3.09078 27.1364 2.45441 26.8636 1.90896 26.4091L1.63623 26.1364C0.63623 25.1364 0.63623 23.5 1.63623 22.5L3.54532 20.5909C4.45441 19.6818 6.09078 19.5909 7.09078 20.5L7.27259 20.5909C7.3635 20.5909 7.45441 20.6818 7.54532 20.7727C8.54532 21.7727 8.54532 23.4091 7.54532 24.4091L5.63623 26.3182C5.09078 26.8636 4.45441 27.1364 3.72714 27.1364ZM3.18169 25.1364C3.54532 25.5 3.99987 25.4091 4.3635 25.0455L6.27259 23.1364C6.54532 22.8636 6.54532 22.4091 6.3635 22.1364L6.18169 22.0455C6.09078 22.0455 5.99987 21.9545 5.90896 21.8636C5.63623 21.5909 5.09078 21.5909 4.81805 21.8636L2.90896 23.7727C2.63623 24.0455 2.63623 24.5909 2.90896 24.8636L3.18169 25.1364Z"
          fill={svgcolor}
        />
        <path
          d="M53.9088 36.6819H46.727C46.1815 36.6819 45.8179 36.3182 45.8179 35.7728C45.8179 35.2273 46.1815 34.8637 46.727 34.8637H53.9088C55.2724 34.8637 56.4542 33.6819 56.4542 32.3182V27.3182C56.4542 26.9546 56.3633 26.5909 56.2724 26.1364C56.1815 25.6819 56.4542 25.1364 56.9997 25.0455C57.4542 24.9546 57.9997 25.2273 58.0906 25.7728C58.1815 26.2273 58.2724 26.7728 58.2724 27.3182V32.3182C58.2724 34.6819 56.2724 36.6819 53.9088 36.6819Z"
          fill={svgcolor}
        />
        <path
          d="M7.36328 22.7728H7.27237C6.72692 22.7728 6.36328 22.4091 6.36328 21.8637C6.36328 21.3182 6.72692 20.9546 7.27237 20.9546H7.36328C7.90874 20.9546 8.36328 20.7728 8.72692 20.4091C9.09055 20.0455 9.27237 19.591 9.27237 19.0455V17.0455C9.27237 16.5 9.63601 16.1364 10.1815 16.1364C10.7269 16.1364 11.0906 16.5 11.0906 17.0455V19.0455C11.0906 20.1364 10.7269 20.9546 9.99964 21.6819C9.27237 22.4091 8.45419 22.7728 7.36328 22.7728Z"
          fill={svgcolor}
        />
        <path
          d="M23.2725 28.2273C22.727 28.2273 22.3634 27.8637 22.3634 27.3182C22.3634 25.2273 20.9088 23.3182 18.9088 22.7728C16.9088 22.6819 15.2725 21.0455 15.2725 19.0455V17.0455C15.2725 16.5 15.6361 16.1364 16.1816 16.1364C16.727 16.1364 17.0906 16.5 17.0906 17.0455V19.0455C17.0906 20.0455 17.9997 20.9546 18.9997 20.9546H19.1816C22.0906 21.6819 24.1816 24.3182 24.1816 27.3182C24.1816 27.8637 23.8179 28.2273 23.2725 28.2273Z"
          fill={svgcolor}
        />
        <path
          d="M30.2725 17.8637C26.3634 17.8637 23.2725 13.2273 23.2725 9.40912C23.2725 5.59094 26.4543 2.40912 30.2725 2.40912C34.0906 2.40912 37.2725 5.59094 37.2725 9.40912C37.2725 13.2273 34.1816 17.8637 30.2725 17.8637ZM30.2725 4.2273C27.4543 4.2273 25.0906 6.59094 25.0906 9.40912C25.0906 12.3182 27.5452 16.0455 30.2725 16.0455C32.9997 16.0455 35.4543 12.3182 35.4543 9.40912C35.4543 6.59094 33.0906 4.2273 30.2725 4.2273Z"
          fill={svgcolor}
        />
        <path
          d="M20.8182 23.6819C20.6363 23.6819 20.3636 23.591 20.1818 23.4091C19.8182 23.0455 19.8182 22.5 20.0909 22.1364C21.0909 21.0455 22.3636 20.2273 23.6363 19.9546H23.8182C24.4545 19.9546 24.9091 19.7728 25.3636 19.3182C25.7273 18.9546 26 18.3182 26 17.7728V15.8637C26 15.3182 26.3636 14.9546 26.9091 14.9546C27.4545 14.9546 27.8182 15.3182 27.8182 15.8637V17.7728C27.8182 18.7728 27.3636 19.8637 26.6363 20.591C25.9091 21.3182 25 21.7728 23.9091 21.7728C23 22.0455 22.0909 22.591 21.3636 23.4091C21.2727 23.591 21.0909 23.6819 20.8182 23.6819Z"
          fill={svgcolor}
        />
        <path
          d="M39.7271 23.6819C39.4543 23.6819 39.2725 23.5909 39.0907 23.4091C38.3634 22.6819 37.5452 22.1364 36.5452 21.7728C34.3634 21.6819 32.7271 19.9546 32.7271 17.7728V15.6819C32.7271 15.1364 33.0907 14.7728 33.6361 14.7728C34.1816 14.7728 34.5452 15.1364 34.5452 15.6819V17.7728C34.5452 18.9546 35.5452 19.9546 36.7271 19.9546H36.9998C38.4543 20.3182 39.5452 21.0455 40.5452 22.1364C40.9089 22.5 40.818 23.0455 40.4543 23.4091C40.1816 23.5909 39.9089 23.6819 39.7271 23.6819Z"
          fill={svgcolor}
        />
        <path
          d="M47.3637 19.0455C43.7274 19.0455 40.9092 14.8637 40.9092 11.2273C40.9092 7.59095 43.7274 4.77277 47.3637 4.77277C51.0001 4.77277 53.8183 7.59095 53.8183 11.2273C53.8183 14.8637 51.0001 19.0455 47.3637 19.0455ZM47.3637 6.59095C44.7274 6.59095 42.7274 8.59095 42.7274 11.2273C42.7274 13.9546 44.9092 17.2273 47.3637 17.2273C49.8183 17.2273 52.0001 13.9546 52.0001 11.2273C52.0001 8.59095 50.0001 6.59095 47.3637 6.59095Z"
          fill={svgcolor}
        />
        <path
          d="M13.1816 19.0455C9.54523 19.0455 6.72705 14.8637 6.72705 11.2273C6.72705 7.59095 9.54523 4.77277 13.1816 4.77277C16.818 4.77277 19.6361 7.59095 19.6361 11.2273C19.6361 14.8637 16.818 19.0455 13.1816 19.0455ZM13.1816 6.59095C10.5452 6.59095 8.54523 8.59095 8.54523 11.2273C8.54523 13.9546 10.7271 17.2273 13.1816 17.2273C15.6361 17.2273 17.818 13.9546 17.818 11.2273C17.818 8.59095 15.818 6.59095 13.1816 6.59095Z"
          fill={svgcolor}
        />
        <path
          d="M37.2724 28.2273C36.7269 28.2273 36.3633 27.8637 36.3633 27.3182C36.3633 24.3182 38.3633 21.6819 41.3633 20.9546H41.5451C42.5451 20.9546 43.4542 20.0455 43.4542 19.0455V17.0455C43.4542 16.5 43.8178 16.1364 44.3633 16.1364C44.9087 16.1364 45.2724 16.5 45.2724 17.0455V19.0455C45.2724 21.0455 43.636 22.6819 41.636 22.7728C39.5451 23.3182 38.1815 25.2273 38.1815 27.3182C38.1815 27.8637 37.8178 28.2273 37.2724 28.2273Z"
          fill={svgcolor}
        />
        <path
          d="M52.9091 22.7728H52.8182C50.9091 22.5 49.4546 20.9546 49.4546 19.0455V17.0455C49.4546 16.5 49.8182 16.1364 50.3637 16.1364C50.9091 16.1364 51.2728 16.5 51.2728 17.0455V19.0455C51.2728 20.0455 52 20.8637 53 20.9546C53.4546 21.0455 53.8182 21.5 53.8182 21.9546C53.7273 22.4091 53.3637 22.7728 52.9091 22.7728Z"
          fill={svgcolor}
        />
        <path
          d="M53.4545 36.5909C52.909 36.5909 52.5454 36.2273 52.5454 35.6818V29.3182C52.5454 28.7728 52.909 28.4091 53.4545 28.4091C54 28.4091 54.3636 28.7728 54.3636 29.3182V35.6818C54.3636 36.2273 54 36.5909 53.4545 36.5909Z"
          fill={svgcolor}
        />
        <path
          d="M56.2728 27.1364C55.5455 27.1364 54.9091 26.8636 54.3637 26.3182L52.4546 24.4091C51.4546 23.4091 51.4546 21.7727 52.4546 20.7727C52.5455 20.6818 52.6364 20.6818 52.7273 20.5909L53 20.5C54 19.5909 55.5455 19.6818 56.5455 20.5909L58.4546 22.5C59.4546 23.5 59.4546 25.1364 58.4546 26.1364L58.1819 26.4091C57.5455 26.9545 56.9091 27.1364 56.2728 27.1364ZM53.6364 22.1364C53.4546 22.4091 53.4546 22.8636 53.7273 23.1364L55.6364 25.0455C56 25.4091 56.4546 25.4091 56.8182 25.1364L57.091 24.8636C57.3637 24.5909 57.3637 24.0455 57.091 23.7727L55.1819 21.8636C54.9091 21.5909 54.3637 21.5909 54.091 21.8636C54 21.9545 53.9091 21.9545 53.8182 22.0455L53.6364 22.1364Z"
          fill={svgcolor}
        />
        <path
          d="M43.1816 58.5909H16.8179C14.2725 58.5909 12.2725 56.5909 12.2725 54.0455V31.1364C12.2725 28.5909 14.2725 26.5909 16.8179 26.5909H43.0906C45.6361 26.5909 47.6361 28.5909 47.6361 31.1364V54.0455C47.727 55.2273 47.2725 56.3182 46.4543 57.2273C45.5452 58.0455 44.3634 58.5909 43.1816 58.5909ZM16.8179 28.4091C15.2725 28.4091 14.0906 29.5909 14.0906 31.1364V54.0455C14.0906 55.5909 15.2725 56.7728 16.8179 56.7728H43.1816C43.9088 56.7728 44.6361 56.5 45.0906 55.9546C45.5452 55.4091 45.8179 54.7728 45.8179 54.1364V31.2273C45.8179 29.6818 44.6361 28.5 43.0906 28.5H16.8179V28.4091Z"
          fill={svgcolor}
        />
        <path
          d="M46.8179 35.4091H13.1816C12.6361 35.4091 12.2725 35.0455 12.2725 34.5C12.2725 33.9546 12.6361 33.5909 13.1816 33.5909H46.8179C47.3634 33.5909 47.727 33.9546 47.727 34.5C47.727 35.0455 47.3634 35.4091 46.8179 35.4091Z"
          fill={svgcolor}
        />
        <path
          d="M24.9088 52.9546C24.6361 52.9546 24.4543 52.8636 24.2725 52.6818L18.5452 47.0455C18.3634 46.8636 18.2725 46.6818 18.2725 46.4091C18.2725 46.1364 18.3634 45.9545 18.5452 45.7727L24.2725 39.9546C24.6361 39.5909 25.1816 39.5909 25.5452 39.9546C25.9088 40.3182 25.9088 40.8636 25.5452 41.2273L20.4543 46.4091L25.5452 51.4091C25.9088 51.7727 25.9088 52.3182 25.5452 52.6818C25.3634 52.8636 25.1816 52.9546 24.9088 52.9546Z"
          fill={svgcolor}
        />
        <path
          d="M35.0907 52.9546C34.818 52.9546 34.6362 52.8636 34.4544 52.6818C34.0907 52.3182 34.0907 51.7727 34.4544 51.4091L39.5453 46.4091L34.4544 41.2273C34.0907 40.8636 34.0907 40.3182 34.4544 39.9546C34.818 39.5909 35.3635 39.5909 35.7271 39.9546L41.4544 45.7727C41.6362 45.9545 41.7271 46.1364 41.7271 46.4091C41.7271 46.6818 41.6362 46.8636 41.4544 47.0455L35.7271 52.6818C35.5453 52.8636 35.3635 52.9546 35.0907 52.9546Z"
          fill={svgcolor}
        />
        <path
          d="M27.9999 54.8636C27.909 54.8636 27.8181 54.8636 27.7271 54.8636C27.2726 54.7727 26.909 54.2273 27.0908 53.7727L31.0908 38.5909C31.1817 38.1364 31.7271 37.7727 32.1817 37.9546C32.6362 38.0455 32.9999 38.5909 32.8181 39.0455L28.8181 54.2273C28.7271 54.5909 28.3635 54.8636 27.9999 54.8636Z"
          fill={svgcolor}
        />
        <path
          d="M32 31.9546H17.9091C17.3636 31.9546 17 31.591 17 31.0455C17 30.5 17.3636 30.1364 17.9091 30.1364H32C32.5455 30.1364 32.9091 30.5 32.9091 31.0455C32.9091 31.591 32.5455 31.9546 32 31.9546Z"
          fill={svgcolor}
        />
        <path
          d="M37.2728 31.9546H35.3637C34.8182 31.9546 34.4546 31.591 34.4546 31.0455C34.4546 30.5 34.8182 30.1364 35.3637 30.1364H37.2728C37.8182 30.1364 38.1819 30.5 38.1819 31.0455C38.1819 31.591 37.8182 31.9546 37.2728 31.9546Z"
          fill={svgcolor}
        />
        <path
          d="M42.091 31.9546H40.2729C39.7274 31.9546 39.3638 31.591 39.3638 31.0455C39.3638 30.5 39.7274 30.1364 40.2729 30.1364H42.091C42.6365 30.1364 43.0001 30.5 43.0001 31.0455C43.0001 31.591 42.6365 31.9546 42.091 31.9546Z"
          fill={svgcolor}
        />
      </svg>
    </>
  );
}

export default Developers;
