import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";

const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>In today's fast-paced world, traditional learning methods often fail to prepare students for real-world challenges. Dyshine Technosoft recognizes this gap and advocates for experiential learning, a hands-on approach that significantly enhances career readiness. By diving into practical experiences, students gain valuable insights and skills that are often missed in conventional classrooms. This is especially true for those enrolled in software development courses.</>
  );
  const cardData = [
    {
      image: card1,
      heading: "What is Experiential Learning?",
      description:"Experiential learning is a method where students learn by doing. Instead of just listening to lectures or reading textbooks, they engage in activities that mirror real-world tasks. This could involve internships, project-based assignments, lab work, or simulations. The goal is to provide students with practical experience that deepens their understanding and boosts their confidence.",
      link: "",
    },
    {
      image: card2,
      heading: "Benefits of Experiential Learning",
      description:"1.	Enhanced Skill Development: Experiential learning allows students to apply theoretical knowledge in practical settings. For instance, in software development courses, students might work on actual coding projects, troubleshoot real software issues, and collaborate on team-based tasks. This hands-on approach helps them develop critical technical skills and soft skills like problem-solving and teamwork. 2.	Increased Engagement: Learning by doing is often more engaging than traditional methods. Students are more likely to stay motivated and interested when they see the direct application of what they're learning. Active involvement can improve information retention and foster a deeper comprehension of the subject matter.",
      link: "",
    },
    {
      image: card3,
      heading: "",
      description:"3.	Real-World Experience: One of the biggest advantages of experiential learning is the opportunity to gain real-world experience. Internships and project work allow students to experience the workplace, understand industry expectations, and build professional networks. This experience is priceless when entering the job market. 4.	Improved Career Readiness: Students who participate in experiential learning programs are often better prepared for their careers. They enter the workforce with a clearer understanding of their roles, having already faced and overcome challenges similar to those they'll encounter in their jobs. This preparedness grants them a competitive edge in the job market.",
      link: "",
    },
    {
      image: card4,
      heading: "Experiential Learning in Software Development",
      description:"For students in software development courses, experiential learning is particularly beneficial. The tech industry is fast-paced and constantly evolving, making practical experience crucial. Here’s how experiential learning can make a difference: 1.	Hands-On Coding Practice: By working on real coding projects, students can apply what they've learned in class to real-world problems. This practice helps them understand the nuances of programming languages and development frameworks.",
      link: "",
    },
    {
      image: card5,
      heading: "",
      description:"2.	Problem-Solving Skills: Experiential learning helps students develop strong problem-solving skills. They learn how to debug code, optimize performance, and think critically about how to tackle complex software issues. 3.	Collaboration and Communication: In the tech industry, teamwork is essential. Experiential learning often involves collaborative projects where students must communicate effectively, share responsibilities, and work towards a common goal. These experiences prepare them for the collaborative nature of software development jobs. 4.	Exposure to Industry Tools: Through internships and projects, students get hands-on experience with industry-standard tools and technologies. This exposure is crucial for staying current with the latest trends and practices in software development. ",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>
          Experiential learning is a powerful tool for enhancing career readiness, especially in fields like software development. Bridging the gap between theory and practice equips students with the skills, experience, and confidence they need to succeed in their careers. At Dyshine Techno soft, we are committed to providing our students with the best possible learning experiences to ensure they are well-prepared for the future. Whether you're just starting or looking to advance your career, our software development courses offer the practical, hands-on learning you need to thrive in the tech industry.
        </>
      ),
      link: "",
    }
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content=""
        />
        <meta
          name="description"
          content=""
        />
        <meta
          property="og:description"
          content=""
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;