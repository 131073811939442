import PageLayout from "../../organisms/PageLayout";
import CoreValues from "./CoreValues";
import Team from "./Team";
import VisionAndMission from "./VisionAndMission";
import SocialResponsibility from "./SocialResponsibility";
import CompanyAboutUs from "./CompanyAboutUs";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + '/v3/assestsWebp/company/banner.webp';

function OurCompanyPage() {
  const { section } = useParams();
  const targetSectionRef = useRef(null);

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  });
  return (
    <>
      <Helmet>
        <title>Dyashin : About Us</title>
        <meta property="og:title" content="About Dyashin - Pioneering Your Digital Transformation Journey" />
        <meta name="description" content="Discover the vision and values that drive Dyashin. Learn about our commitment to innovation and excellence in providing digital solutions." />
        <meta property="og:description" content="Discover the vision and values that drive Dyashin. Learn about our commitment to innovation and excellence in providing digital solutions." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Our Company">
        <div ref={targetSectionRef} id="about-us">
          <CompanyAboutUs />
        </div>
        <div id="vision-and-mission">
          <VisionAndMission />
        </div>
        <Team />
        <div id="core-values">
          <CoreValues />
        </div>
        <SocialResponsibility />
      </PageLayout>
    </>
  );
}
export default OurCompanyPage;
