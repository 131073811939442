import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../organisms/PageLayout";
import ConsultingServices from "../../molecules/ConsultingServices";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/ConsultingServices/banner.webp";

function ConsultingServicesPage() {
  useEffect(() => window.scrollTo(0, 0), []);
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);

  return (
    <>
      <Helmet>
        <title>Dyashin : Consulting Services</title>
        <meta property="og:title" content="Expert Consulting Services - Dyashin's Strategy for Your Success" />
        <meta property="og:description" content="Dyashin's consulting services offer strategic insights and tailored solutions to propel your business forward. Connect with our experts for unparalleled guidance." />
        <meta name="description" content="Dyashin's consulting services offer strategic insights and tailored solutions to propel your business forward. Connect with our experts for unparalleled guidance." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Consulting Services">
        <ReusableBackground1>
          <ConsultingServices />
        </ReusableBackground1>
      </PageLayout>
    </>
  );
}

export default ConsultingServicesPage;
