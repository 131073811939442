import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import Slider from "react-slick";

import MobileStepper from "@mui/material/MobileStepper";
import WestArrow from "../../../assests/svg/Common/WestArrow";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import EastArrow from "../../../assests/svg/Common/EastArrow";
import COLORS from "../../../constants/colorConstants";

const {BLUE3} = COLORS

function BlogCarousel({
  dots = false,
  infinite = true,
  arrows = false,
  speed = 500,
  slidesToShow = 3,
  slidesToScroll = 1,
  autoplay = false,
  autoplaySpeed = 0,
  children,
  sx = {},
}) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
    if (activeStep < 5) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep(0);
    }
  };
  const previous = () => {
    sliderRef.slickPrev();
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStep(5);
    }
  };

  const settings = {
    dots: dots,
    infinite: infinite,
    arrows: arrows,
    speed: speed,
    slidesToShow: isSmallScreen ? 1 : slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
  };

  return (
    <>
      <Grid container spacing={2} sx={{ ...sx }}>
        <Grid item xs={12} height={"90%"}>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {children}
          </Slider>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            {!isSmallScreen && (
              <MobileStepper
                variant="dots"
                steps={6}
                position="static"
                activeStep={activeStep}
                sx={{ backgroundColor: "transparent" }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className="d-flex align-items-center justify-content-end"
          >
            <FarwarButton
              btnBgColor= {BLUE3}
              icon={<WestArrow />}
              onBtnClick={previous}
            />
            &nbsp;
            <FarwarButton
              btnBgColor= {BLUE3}
              icon={<EastArrow />}
              onBtnClick={next}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default BlogCarousel;
