import ContentSection from "../../../molecules/ContentSection";
import ServiceCard from "../../../molecules/ServiceCard";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import RoundedRectangleBox from "../../../atoms/RoundedRectangleBox";
import COLORS from "../../../../constants/colorConstants";
const S3_URL = process.env.REACT_APP_S3_URL;


const {WHITE1, BLUE1, BLUE2, PURPLE2} = COLORS
const valueImage1 =
  S3_URL+"/v3/assestsWebp/company/value1.webp";
const valueImage2 =
  S3_URL+"/v3/assestsWebp/company/value2.webp";
const valueImage3 =
  S3_URL+"/v3/assestsWebp/company/value3.webp";
const valueImage4 =
  S3_URL+"/v3/assestsWebp/company/value4.webp";
const valueImage5 =
  S3_URL+"/v3/assestsWebp/company/value5.webp";
const valueImage6 =
  S3_URL+"/v3/assestsWebp/company/value6.webp";
const background =
  S3_URL+"/v3/assestsWebp/company/blueBackground.webp";
function CoreValues() {
  const values = [
    {
      imageURL: valueImage1,
      heading: "Innovate",
      description:
        "We embody innovation, constantly seeking creative solutions to challenges. By fostering a culture of curiosity, we drive progress and stay ahead in a rapidly evolving landscape. ",
    },
    {
      imageURL: valueImage2,
      heading: "Collaborate",
      description:
        "At Dyashin Technosoft, collaboration is our core value, fostering teamwork and unity to achieve shared goals and driving innovation and success in every endeavour we undertake.",
    },
    {
      imageURL: valueImage3,
      heading: "Integrity",
      description:
        "At Dyashin Technosoft, we uphold integrity as our cornerstone, ensuring transparency, honesty, and ethical practices in every aspect of our work, fostering trust and credibility.",
    },
    {
      imageURL: valueImage4,
      heading: "Customer Centric",
      description:
        "At Dyashin Technosoft, our unwavering commitment to being customer-centric is reflected in every aspect of our service, ensuring satisfaction and exceeding expectations at every turn.",
    },
    {
      imageURL: valueImage5,
      heading: "Loyalty",
      description:
        "Loyalty is a steadfast commitment to unwavering support, trust, and allegiance, fostering enduring connections and reliability within our team and towards our clients.",
    },
    {
      imageURL: valueImage6,
      heading: "Excellence",
      description:
        "At Dyashin Technosoft, we uphold excellence in every endeavour, ensuring meticulous attention to detail, continuous improvement, and surpassing expectations to deliver unparalleled quality and innovation.",
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        sx={{
          position: "relative",
          backgroundImage: `url(${background})`,
          overflow: "hidden",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          paddingTop: "3%",
          paddingBottom: "3%",
        }}
      >
        <Grid item xs={1} md={2} sx={{ paddingTop: "0%", overflowY: "hidden" }}>
          {!isSmallScreen && (
            <RoundedRectangleBox
              animationClassName="grey-capsule"
              height="35%"
              width="18%"
              top="-5%"
              left="-10%"
              zIndex={2}
              backgroundColor={BLUE1}
              sx={{ transform: "rotate(-45deg)" }}
            />
          )}
        </Grid>
        <Grid item container md={8} xs={10}>
          <ContentSection
            badgeLabel="Business Goal"
            title={`Core Values : "ICICLE"`}
            badgeBgColor="bg-light"
            badgeTextColor= {BLUE2}
            chipMuiProps="content-chip1"
          >
            {values.map((value) => (
              <Grid item md={4} sm={6} mb={10}>
                <ServiceCard
                  height="55%"
                  imageURL={value.imageURL}
                  title={value.heading}
                  description={value.description}
                  showIcon={false}
                  titleMuiProps="fw-700 fs-18 heading-color text-center"
                  headingMuiProps="fs-10 "
                  descriptionMuiProps="fw-400 fs-12 card-text-color "
                  sxDescription={{
                    color: PURPLE2,
                    wordSpacing: 0.5,
                    textAlign: "center",
                  }}
                  elevationValue={3}
                  contentMuiprops="core-values-content"
                  cardmuimargin={{xs:'30%',sm:'40%',md:'72%',lg:'35%'}}
                />
              </Grid>
            ))}
           
          </ContentSection>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
          sx={{ paddingTop: "0%", position: "relative" }}
        >
          {!isSmallScreen && (
            <>
              <RoundedRectangleBox
                animationClassName="triangle-animation"
                height="20%"
                width="45%"
                top="45%"
                left="25%"
                backgroundColor={WHITE1}
                sx={{ transform: "rotate(-45deg)" }}
              />
              <RoundedRectangleBox
                animationClassName="triangle-animation"
                height="25%"
                width="65%"
                top="32%"
                left="65%"
                backgroundColor= {BLUE2}
                sx={{ transform: "rotate(-45deg)" }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default CoreValues;
