import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastProvider } from "react-toast-notifications";
import CustomToast from "./components/atoms/ToastComp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <ToastProvider components={{ Toast: CustomToast }}
    placement="top-right"
    autoDismissTimeout={3500}
    autoDismiss
    id="toaster"
    transitionDuration={4}>
    <App />
  </ToastProvider>

);

reportWebVitals();
