import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";
import ContentUsAddressSection from "./ContentUsAddressSection";
import ContactUsFormLayout from "./ContactUsFormLayout";
import ContactUsMap from "./ContactUsMap";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;
const Banner = S3_URL + "/v3/assestsWebp/contactUs/Banner.webp";

function ContactUsPage() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Helmet>
        <title>Dyashin : Contact Us</title>
        <meta property="og:title" content="Contact Dyashin - Connect With Our Expert Team Today" />
        <meta name="description" content="Reach out to Dyashin for innovative tech solutions. Contact our expert team for support, inquiries, or partnerships. We're here to help your business grow." />
        <meta property="og:description" content="Reach out to Dyashin for innovative tech solutions. Contact our expert team for support, inquiries, or partnerships. We're here to help your business grow." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout
        backroundImg={Banner}
        pageTitle="Contact Us"
        DiscoverMore={false}
      >
        <ContactUsFormLayout />
        <ContentUsAddressSection />
        <ContactUsMap />
      </PageLayout>
    </>
  );
}

export default ContactUsPage;
