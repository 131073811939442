import React from 'react'
import COLORS from '../constants/colorConstants'

const {WHITE1, BLUE2} = COLORS

function ChatbotHeader({ onClose }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: BLUE2, padding: '10px', color: WHITE1 }}>
            <span>Chat with Adya &nbsp;<span className='fs-8'>🟢Online</span></span>
            <button onClick={onClose} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', cursor: 'pointer' }}>
                X
            </button>
        </div>
    )
}

export default ChatbotHeader