import ContentSection from "../../../molecules/ContentSection";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import HomepageBlogsCard from "../../../molecules/HomePageBlogsCard";
import RoundedRectangleBox from "../../../atoms/RoundedRectangleBox";
import BlogCarousel from "../../../molecules/BlogsCarousel";
import { useNavigate } from "react-router-dom";
import COLORS from "../../../../constants/colorConstants";

const { BLUE1, BLUE2 } = COLORS;
const S3_URL = process.env.REACT_APP_S3_URL;

const blogsImage1 = S3_URL + "/v3/assestsWebp/home/blogImage1.webp";
const blogsImage2 = S3_URL + "/v3/assestsWebp/home/blog2.webp";
const blogsImage3 = S3_URL + "/v3/assestsWebp/home/blog3.webp";
const background = S3_URL + "/v3/assestsWebp/home/blueBackground.webp";

function HomePageBlogsAndArticles() {
  const navigate = useNavigate();
  const blogs = [
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title: "Empowering learning with Dyashin - Unlock your potential",
      link: "/empowering-learning",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "Embracing Change: Dyashin Technosoft's Guide to Business Migration and Process Re-engineering",
      link: "/embracing-change",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title:
        "AI in Recruitment: Unveiling DSJobby's Impact on Talent Acquisition",
      link: "/ai-in-recruitment",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title:
        "Future-Proofing with Cloud: Dyashin Technosoft's Guide to Efficient Business Migration",
      link: "/future-proofing-with-cloud",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "Augmented Reality in Engineering: Dyashin Technosoft's Visionary Integration",
      link: "/augmented-reality-in-engineering",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title:
        "Revolutionizing Customer Engagement: Dyashin's Generative Experience Strategy",
      link: "/revolutionizing-customer-engagement",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title:
        "Managed Services Revolution: Dyashin TechnoSoft's Project Management Innovation",
      link: "/managed-services-revolution",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "Empowering Education: DSEdify's Innovative Approach to Practical Learning",
      link: "/empowering-education",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title: "Transforming Business Through Generative Experience",
      link: "/transforming-business",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title:
        "Awareness about the migration and re-engineering for enhancing quality in business",
      link: "/migrate-and-reengineer",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "Building chatbots and chatrooms for enhanced communication in business",
      link: "/chatbot-implementation",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title: "SAP implementation enhances the efficiency of businesses.",
      link: "/sap-unlock-business",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title: " beginner’s guide to mobile application development in 2024",
      link: "/mobile-app-development",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "The Next Generation of LMS: How DSEdify is Enhancing Practical Learning through Innovation",
      link: "/empower-education-dsedify",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title:
        "Leveraging AI to Transform the Retail Experience: Insights from Dyashin Technosoft",
      link: "/ai-implementation",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title:
        "Critical Success Factors for ERP Implementation: A Guide by Dyashin Technosoft",
      link: "/erp-implementation",
    },
    {
      imageURL: blogsImage2,
      heading: "FINANCE",
      title:
        "Advancements in FPGA Design: Pushing the Limits with Dyashin Technosoft",
      link: "/fpga-implementation",
    },
    {
      imageURL: blogsImage3,
      heading: "FINANCE",
      title: "The Role of Advanced HRMS in Global Compliance and Efficiency",
      link: "/hrms-implementation",
    },
    {
      imageURL: blogsImage1,
      heading: "FINANCE",
      title:
        "Transforming Business Processes through Effective SAP Implementation",
      link: "/sap-implementation",
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid
        className="home-blog"
        container
        sx={{
          backgroundImage: `url(${background})`,
        }}
      >
        <Grid item xs={1} md={2}></Grid>
        <Grid item container xs={10} md={8}>
          <ContentSection
            badgeLabel="WHAT'S HAPPENING"
            title="Blogs & Articles"
            badgeBgColor="bg-light"
            badgeTextColor={BLUE2}
            chipMuiProps="content-chip1"
          >
            <BlogCarousel>
              {blogs.map((blog, index) => (
                <div
                  key={index}
                  onClick={() => navigate(`/resources/blogs${blog.link}`)}
                >
                  <HomepageBlogsCard
                    title={blog.title}
                    label={blog.heading}
                    imageUrl={blog.imageURL}
                    muicardprops="Shadow-effects"
                  />
                </div>
              ))}
            </BlogCarousel>
          </ContentSection>
        </Grid>
        <Grid item xs={1} md={2}>
          {!isSmallScreen && (
            <RoundedRectangleBox
              className="home-blogs-articles-RoundedRectangleBox"
              animationClassName="grey-capsule"
              backgroundColor={BLUE1}
              sx={{ transform: "rotate(45deg)" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default HomePageBlogsAndArticles;
