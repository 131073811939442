import ImageSectionComponent from "../../../molecules/ImageSectionComponent";
import TwoSectionGridComponent from "../../../organisms/TwoSectionGridComponent";
import ContentSection from "../../../molecules/ContentSection";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const S3_URL = process.env.REACT_APP_S3_URL;

const VerticalImage =
  S3_URL + "/v3/assestsWebp/home/aboutVertical.webp";
const HorizontalImage =
  S3_URL + "/v3/assestsWebp/home/aboutHorizontal.webp";
function CompanyAboutUs() {
  return (
    <>
      <Grid container paddingTop={5} paddingBottom={15}
        sx={{ marginTop: 1 }}>
        <Grid xs={1} md={2}></Grid>
        <Grid xs={10} md={8}>
          <TwoSectionGridComponent>
            <ImageSectionComponent
              verticalImg={VerticalImage}
              horizontalImg={HorizontalImage}
            />
            <ContentSection
              badgeLabel="WHAT WE DO"
              title="About"
              textAlign="left"
              chipMuiProps="content-chip1"
            >
              <Typography
                className="fs-12 fw-400 txt-description"
                textAlign={"start"}
              >
                Dyashin Technosoft is a renowned <Link to={'/solutions/software-development'} className="content-links fw-600 ">Software Development</Link> and <Link to={'/services'} className="content-links fw-600">Engineering services</Link> company led by a group of industry
                veterans. With our passion, knowledge, and commitment to
                excellence, we believe in transforming the arena of software
                development and empowering customer businesses to embrace the
                future and achieve amazing success. Our comprehensive range of
                custom software services & Solutions are designed to assist our
                customers at all stages of the SDLC, assuring project success
                from start to end and beyond.
              </Typography>
            </ContentSection>
          </TwoSectionGridComponent>
        </Grid>
        <Grid xs={1} md={8}></Grid>
      </Grid>
    </>
  );
}

export default CompanyAboutUs;
