import React from "react";
import COLORS from "../../../../constants/colorConstants";

const {BLUE1} = COLORS

function WestArrow({ svgcolor = BLUE1 }) {
  return (
    <>
      <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.1875 3.5625L3.45312 0.312501C3.57812 0.187501 3.72656 0.125001 3.89844 0.125001C4.07031 0.125001 4.21354 0.187501 4.32812 0.312501C4.45312 0.437501 4.51562 0.585939 4.51562 0.757814C4.51562 0.929689 4.45312 1.07292 4.32812 1.1875L2.14062 3.375L15.375 3.375C15.5521 3.375 15.7005 3.4349 15.8203 3.55469C15.9401 3.67448 16 3.82292 16 4C16 4.17708 15.9401 4.32552 15.8203 4.44531C15.7005 4.5651 15.5521 4.625 15.375 4.625L2.14062 4.625L4.32812 6.8125C4.45312 6.92708 4.51562 7.07031 4.51562 7.24219C4.51562 7.41406 4.45312 7.5625 4.32812 7.6875C4.21354 7.8125 4.07031 7.875 3.89844 7.875C3.72656 7.875 3.57812 7.8125 3.45312 7.6875L0.1875 4.4375C0.0624997 4.32292 -3.23282e-07 4.17708 -3.38763e-07 4C-3.54244e-07 3.82292 0.0624996 3.67708 0.1875 3.5625Z"
          fill={svgcolor}
        />
      </svg>
    </>
  );
}

export default WestArrow;
