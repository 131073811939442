import React from 'react'
import COLORS from '../../../../constants/colorConstants'

const {BLUE1} = COLORS
function Linkedin() {
  return (
 <>
 <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.13084 5.98483V16.3092H1.6956V5.98483H5.13084ZM5.35488 2.7923C5.35488 3.7818 4.60809 4.56593 3.41322 4.56593H3.39455C2.23703 4.56593 1.49023 3.7818 1.49023 2.7923C1.49023 1.78413 2.2557 1 3.43189 1C4.60809 1 5.33621 1.78413 5.35488 2.7923ZM17.4902 10.3909V16.3092H14.0737V10.783C14.0737 9.4014 13.5696 8.44924 12.3187 8.44924C11.3852 8.44924 10.8065 9.08401 10.5637 9.70012C10.4891 9.92415 10.4517 10.2229 10.4517 10.5403V16.3092H7.01649C7.0725 6.95566 7.01649 5.98483 7.01649 5.98483H10.4517V7.47841H10.4331C10.8811 6.76896 11.6839 5.74212 13.5509 5.74212C15.81 5.74212 17.4902 7.21704 17.4902 10.3909Z" fill={BLUE1}/>
</svg>

 </>
  )
}

export default Linkedin