import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>In the fast-changing world of work today, learning new skills has become crucial for both workers and their bosses. At Dyashin Technosoft, continuous learning and skill enhancement are critical to maintaining a competitive edge. The advent of new technologies and shifting industry trends necessitates that professional stay current to meet the demands of their roles effectively.</>
  );
  const cardData = [
    {
      image: card1,
      heading: "",
      description:"For employees, upskilling offers several advantages. It enhances job security by making them more valuable to their current employers and increases their employability in the job market. Learning new skills can lead to career advancement opportunities, higher salaries, and greater job satisfaction. Moreover, it fosters personal growth, boosts confidence, and keeps professionals motivated and engaged.",
      link: "",
    },
    {
      image: card2,
      heading: "",
      description:"From an organizational perspective, companies like Dyashin Technosoft benefit significantly from investing in the upskilling of their workforce. It leads to higher productivity, innovation, and efficiency as employees are better equipped to handle advanced tools and technologies. Additionally, a well-trained workforce can adapt more quickly to industry changes and disruptions, ensuring the organization remains agile and resilient. This investment also improves employee retention rates, as workers are likelier to stay with a company that supports their professional development.",
      link: "",
    },
    {
      image: card3,
      heading: "",
      description:"The benefits of continuous learning extend beyond immediate job performance. Upskilling employees can stimulate creative thinking and problem-solving skills, which are essential for innovation. Employees who are encouraged to learn new skills are more likely to propose fresh ideas and solutions, driving the company's growth and competitiveness. In a technology-driven industry, the ability to innovate can set a company apart from its competitors, making skill enhancement a strategic priority.",
      link: "",
    },
    {
      image: card4,
      heading: "",
      description:"Moreover, the process of learning itself can lead to a more dynamic and collaborative work environment. When employees engage in learning activities together, such as workshops, training sessions, or online courses, it fosters a culture of knowledge sharing and teamwork. This collaborative spirit can enhance communication and build stronger relationships among colleagues, contributing to a more cohesive and productive workplace. At Dyashin Technosoft, the commitment to continuous learning is evident through various initiatives designed to support employee development. The company offers a range of training programs, from technical skills courses to leadership development workshops. Employees are encouraged to set personal learning goals and are provided with the resources and time needed to achieve them. This supportive environment ensures that learning becomes an integral part of the work culture.",
      link: "",
    },
    {
      image: card5,
      heading: "",
      description:"To maximize the impact of upskilling efforts, Dyashin Technosoft also leverages technology. E-learning platforms, virtual classrooms, and interactive modules provide flexible and accessible learning options for employees. This approach caters to different learning styles and schedules, making it easier for employees to fit learning into their busy lives. The use of technology also allows for real-time feedback and progress tracking, helping employees stay on course and measure their achievements.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>
          In today's rapidly evolving job market, continuous learning and skill enhancement are essential for both employees and organizations. At Dyashin Technosoft, the emphasis on upskilling ensures that the workforce remains competitive, innovative, and adaptable. By investing in employee development, the company not only boosts productivity and efficiency but also fosters a culture of continuous improvement and collaboration. For employees, the benefits of upskilling are manifold, leading to greater job security, career advancement, and personal growth. As the world of work continues to change, the commitment to learning will remain a key driver of success for both individuals and organizations.
        </>
      ),
      link: "",
    },
   
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content="Upskilling: Why It’s Essential for Modern Business Success"
        />
        <meta
          name="description"
          content="Discover why upskilling is crucial for modern businesses, driving innovation, improving productivity, and keeping teams competitive in a rapidly changing market."
        />
        <meta
          property="og:description"
          content="Discover why upskilling is crucial for modern businesses, driving innovation, improving productivity, and keeping teams competitive in a rapidly changing market."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;