import COLORS from "../../../../constants/colorConstants";
import ContentSection from "../../../molecules/ContentSection";
import { Box, Grid, Typography } from "@mui/material";

const {WHITE1, BLUE2} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const background = S3_URL+"/v3/assestsWebp/company/visionMission.webp";

function VisionAndMission() {
  const visionMission = [
    {
      heading: "Vision",
      title:
        "To be a global standard company to provide Value Driven IT services, Innovative Engineering Solutions & Reliable NexGen products for a better tomorrow.",
    },
    {
      heading: "Mission",
      title:
        "Fostering long lasting relationships and driving mutual growth with our customers, employees & vendor partners in the ever-evolving landscape of technology.",
    },
  ];

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: {xs:"100% 25%", sm:"contain"},
          backgroundRepeat: "no-repeat",
          paddingY: "5%",
        }}
      >
        <Grid item md={2}></Grid>
        <Grid item container md={8}>
          <ContentSection
            badgeLabel="THOUGHT PROCESS"
            title="Vision & Mission"
            titleMuiProp="text-white"
            badgeBgColor="bg-light"
            badgeTextColor= {BLUE2}
              chipMuiProps="content-chip1"
          >
            {visionMission.map((item) => (
              <Grid item md={6} xs={12} padding={2}>
                <Box
                  className="vision-card heading-color"
                  sx={{
                    backgroundColor: WHITE1,
                    padding: "5%",
                    boxShadow: "0px 2px 10px rgba(25, 24, 37, 0.08)",
                  }}
                >
                  <Typography className="fw-700 fs-18 mb-2 heading-color ">
                    {item.heading}
                  </Typography>
                  <Typography
                    className="fs-14  txt-description vision-card-text"
                 
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </ContentSection>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </>
  );
}
export default VisionAndMission;
