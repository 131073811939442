import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";
import SocialResponsibilityCard from "../../molecules/SocialResponsibilityCard";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner =
  S3_URL + "/v3/assestsWebp/SocialResponsibility/banner.webp";

function SocialResponsibilityPage() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Helmet>
        <title>Dyashin : Social Responsibility</title>
        <meta property="og:title" content="Dyashin's Commitment to Social Responsibility - Building a Better Future" />
        <meta name="description" content="Dyashin specializes in bespoke solutions that drive innovation and growth for businesses. Explore our custom-tailored services to meet your unique needs" />
        <meta property="og:description" content="Dyashin specializes in bespoke solutions that drive innovation and growth for businesses. Explore our custom-tailored services to meet your unique needs" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Social Responsibility">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <SocialResponsibilityCard />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default SocialResponsibilityPage;
