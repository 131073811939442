import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";

import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage6/mainPost.webp";
const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage6/card1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage6/card2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage6/card3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage6/card4.webp";

const Conclusion =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/Conclusion.webp";

const Banner =
  S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const BlogsArticlesPage06 = () => {
  const description = `In today's fast-paced digital world, companies are always looking for new ways to connect with their customers and keep up with the competition. One such transformative approach gaining traction is the concept of Generative Experience. This approach goes beyond traditional customer experiences by leveraging data, artificial intelligence, and human-centred design principles to create personalized, immersive, and value-driven interactions. Let's understand how Generative Experience is reshaping the business landscape.`;
  const cardData = [
    {
      image: card1,
      heading: "Tremendous value:",
      description: (<>At its core, Generative Experience focuses on co-creating value with customers through continuous feedback loops and dynamic adaptation. By harnessing <a href="https://dyashin.com/" target="blank" className="content-links fw-600">data analytics </a>and machine learning algorithms, businesses can gain deep insights into customer behaviours, preferences, and needs. This data-driven approach enables organizations to anticipate customer demands and deliver tailored experiences that resonate on a personal level.
Moreover, Generative Experience emphasizes human-centred design principles to ensure that interactions are intuitive, seamless, and emotionally resonant. By empathizing with customers' perspectives and understanding their pain points, businesses can design solutions that not only meet functional requirements but also evoke positive emotions and foster long-term loyalty
</>),
      link: "",
    },
    {
      image: card2,
      heading: "Versatility:",
      description: (<>One of the key components of Generative Experience is its emphasis on real-time adaptability. In today's fast-paced environment, customer expectations are constantly evolving, necessitating agile and responsive business strategies. By leveraging real-time <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">data analytics </a> and predictive modelling, businesses can dynamically adjust their offerings and messaging to align with shifting customer preferences and market trends.
Furthermore, Generative Experience extends beyond individual touchpoints to encompass the entire customer journey. By mapping out the end-to-end customer experience and identifying critical moments of truth, businesses can orchestrate cohesive and memorable experiences that drive customer satisfaction and loyalty. This holistic approach enables organizations to differentiate themselves in the marketplace and create sustainable competitive advantages
.</>),
      link: "",
    },
    {
      image: card3,
      heading: "Loyalty: ",
      description:
        "Another hallmark of Generative Experience is its focus on ecosystem collaboration and co-innovation. By partnering with customers, suppliers, and other stakeholders, businesses can co-create value-added solutions that address complex challenges and unlock new growth opportunities. This collaborative approach fosters trust, transparency, and mutual benefit, laying the foundation for long-term success in an increasingly interconnected world.",
      link: "",
    },
    {
      image: card4,
      heading: "Adaptability:",
      description: (<>In addition to enhancing customer experiences, Generative Experience can also drive internal transformation within organizations. By fostering a culture of innovation, experimentation, and continuous learning, businesses can empower employees to ideate, iterate, and innovate at every level. This cultural shift enables organizations to adapt quickly to changing market conditions and seize emerging opportunities with agility and confidence.
Furthermore, Generative Experience can help businesses optimize their operations and resource allocation by leveraging predictive analytics and <a href="https://dyashin.com/solutions" target="blank" className="content-links fw-600">automation technologies </a>. By streamlining processes, reducing inefficiencies, and maximizing resource utilization, organizations can enhance productivity, reduce costs, and drive bottom-line growth.
</>),
      link: "",
    },
    {
      image: Conclusion,
      heading: "Conclusion:",
      description: (<>Generative Experience represents a paradigm shift in how businesses engage with their customers, employees, and stakeholders. By embracing data-driven insights, human-centred design principles, and ecosystem collaboration, <a href="https://www.linkedin.com/company/100918843/admin/dashboard/" target="blank" className="content-links fw-600">Dyashin Technosoft </a> creates personalized, immersive, and value-driven experiences that drive customer satisfaction, loyalty, and business growth. As businesses continue to navigate the complexities of the digital age, Generative Experience offers a transformative framework for driving innovation, differentiation, and sustainable competitive advantage.</>),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Revolutionizing Customer Engagement</title>
        <meta property="og:title" content="Revolutionizing Customer Engagement: Dyashin's Generative Experience Strategy" />
        <meta name="description" content="Discover how Dyashin Technosoft is transforming business engagement with Generative Experience, creating personalized, adaptive, and co-innovative customer interactions." />
        <meta property="og:description" content="Discover how Dyashin Technosoft is transforming business engagement with Generative Experience, creating personalized, adaptive, and co-innovative customer interactions." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Revolutionizing Customer Engagement: Dyashin's Generative Experience Strategy"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage06;
