import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";

import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const mainPost = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage4/mainPost.webp'
const card1 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage4/card1.webp'
const card2 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage4/card2.webp'
const card3 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage4/card3.webp'

const Conclusion = S3_URL + "/v3/assestsWebp/blogsArticlesSub/Conclusion.webp";
const stayingAhead = S3_URL + "/v3/assestsWebp/blogsArticlesSub/stayingAhead.webp";



const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";


const BlogsArticlesPage4 = () => {
  const description = (<><a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Cloud migration </a> is particularly important as a strategic requirement for businesses looking to remain flexible and competitive in a time when digital transformation is a major factor in business success. <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Dyashin Technosoft </a>, a leading provider of IT solutions, emphasizes the benefits of cloud migration for companies, ensuring they are prepared for upcoming opportunities and challenges.</>);
  const cardData = [
    {
      image: card1,
      heading: "Improved flexibility & scalability:",
      description:
      (<>Cloud migration offers unparalleled scalability and flexibility, overcoming the challenges of traditional on-premises <a href={process.env.REACT_APP_X_URL} target="blank" className="content-links fw-600">IT infrastructures </a> in handling rapid demand changes. On the other hand, cloud solutions enable businesses to scale resources up or down according to current needs without being constrained by physical hardware limitations. The flexibility guarantees that businesses can effectively handle seasonal fluctuations and peak demands, maximizing resource efficiency and economy of scale.</>),
      link: "",
    },
    {
      image: card2,
      heading: "Cost Efficiency:",
      description:
      (<>With the elimination of capital expenditures on actual servers and hardware, <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">cloud migration </a>gives businesses significant cost savings through the adoption of a pay-as-you-go approach, paying only for the resources they use. The shift from capital expenditure (CapEx) to operational expenditure (OpEx) provides greater financial flexibility and allows companies to allocate funds to other critical areas, such as innovation and growth initiatives.</>),
      link: "",
    },
    {
      image: card3,
      heading: "Better Output & Collaboration: ",
      description:
      (<>Cloud platforms enhance team collaboration and productivity by allowing employees to access data and work from anywhere, anytime, using any device. Emphasizing the importance of real-time collaboration, data sharing, and communication,<a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600"> Dyashin </a> offers cloud solutions for enhanced efficiency and quicker decision-making in remote and hybrid work environments.</>),
      link: "",
    },
    {
      image: stayingAhead,
      heading: "Increased security & Compliance:",
      description: (<>In the digital age, cloud providers heavily invest in cutting-edge safeguards to protect data from breaches and cyber threats as businesses prioritize security. <a href=" https://dyashin.com/solutions" target="blank" className="content-links fw-600"> Cloud migration </a> allows businesses to benefit from robust security frameworks, regular updates, and industry-specific regulations, while also ensuring compliance with industry standards.</>),
      link: "",
    },
    {
      image: Conclusion,
      heading: "Business Continuity & Disaster Recovery:",
      description: (<>Compared to traditional & complex methods, cloud migration offers efficient and cost-effective solutions that minimize downtime and data loss, enhancing disaster recovery and business continuity. With cloud-based backup and recovery solutions from <a href=" https://dyashin.com/" target="blank" className="content-links fw-600">Dyashin Technosoft </a>, businesses can quickly restore operations in the event of a disruption, maintaining continuity and safeguarding their reputation.</>),
      link: "",
    },

  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Future-Proofing with Cloud</title>
        <meta property="og:title" content="Future-Proofing with Cloud: Dyashin Technosoft's Guide to Efficient Business Migration" />
        <meta name="description" content="Unlock the potential of cloud migration with Dyashin Technosoft: enhanced flexibility, cost savings, and robust security for a resilient and future-ready business infrastructure." />
        <meta property="og:description" content="Unlock the potential of cloud migration with Dyashin Technosoft: enhanced flexibility, cost savings, and robust security for a resilient and future-ready business infrastructure." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Future-Proofing with Cloud: Dyashin Technosoft's Guide to Efficient Business Migration"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage4;
