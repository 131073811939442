import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const mainPost = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticalePage7/mainPost.webp'
const card1 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticlesPage4/card2.webp'
const card2 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticalePage7/card2.webp'
const card3 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticalePage7/card3.webp'
const card4 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticalePage7/card4.webp'
const card5 = S3_URL + '/v3/assestsWebp/blogsArticlesSub/blogArticalePage7/card5.webp'

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const BlogsArticlesPage07 = () => {
  const description = (<>Businesses are adopting modern technologies to scale productivity and adapt to market standards. Owners are proactively addressing operational challenges, leading to a shift towards managed services provided by professional service providers. Managed services have revolutionized project management by reshaping traditional approaches and transforming the way businesses handle their operations. <a href=" https://dyashin.com/services" target="blank" className="content-links fw-600">Dyashin TechnoSoft </a> has spearheaded this shift by utilizing cutting-edge technology and inventive approaches to optimize procedures and provide remarkable results.
Now, let’s understand how managed services are reshaping project management.
</>);
  const cardData = [
    {
      image: card1,
      heading: "Cost efficiency:",
      description:
      (<>Managed services offer cost efficiency by outsourcing tasks to specialized providers, reducing operational costs associated with maintaining in-house teams and infrastructure. The shift from capital to operational expenditure enables organizations to allocate resources efficiently and concentrate on their core business activities. Dyashin Techno Soft provides cost-effective end-to-end solutions for projects.</>),
      link: "",
    },
    {
      image: card2,
      heading: "Scalability:",
      description:
      (<>Managed services provide scalability for businesses, enabling quick adjustments to workloads and business needs without extensive planning or resource investment. The adaptable service models offered by<a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600"> Dyashin TechnoSoft </a>allow customers to easily modify their resources and capabilities in response to shifting needs, guaranteeing peak performance every time.</>),
      link: "",
    },
    {
      image: card3,
      heading: "Access to expertise: ",
      description:
      (<>Complex project management calls for particular expertise and skills that may not be easily found within the company. <a href={process.env.REACT_APP_PINTEREST_URL} target="blank" className="content-links fw-600">Managed service </a> companies offer access to highly qualified personnel and state-of-the-art technologies, together with a plethora of expertise and knowledge. Access to expertise allows businesses to confidently tackle projects of any size or complexity, ensuring they have the right talent and resources at their disposal.</>),
      link: "",
    },
    {
      image: card5,
      heading: "Focus on Core Competencies:",
      description: (<>Offloading non-core tasks to managed service providers allows businesses to focus on core competencies and strategic initiatives, reducing their resources and time. <a href=" https://dyashin.com/services" target="blank" className="content-links fw-600">Dyashin TechnoSoft </a> manages routine tasks like monitoring, maintenance, and support, allowing clients to focus on innovation, customer satisfaction, and market competitiveness.</>),
      link: "",
    },
    {
      image: card4,
      heading: "Increased flexibility & agility:",
      description: (<>In a fast-paced business environment, managed services give enterprises the agility and flexibility they need to respond to changing market conditions, consumer expectations, and technology breakthroughs. Clients/Businesses may swiftly adjust to new opportunities and difficulties due to Dyashin’s agile project management methodology, which guarantees long-term success and business continuity. 
Hence, the innovative managed services approach is revolutionizing traditional project management by offering cost-efficient, scalable, and flexible solutions, enabling businesses to thrive in a rapidly evolving landscape.<a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600"> Dyashin Techno Soft </a> offers bespoke software services that help our clients at every step of the SDLC/PDLC and guarantee project success through completion and beyond.
</>),
      link: "",
    },

  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Managed Services Revolution</title>
        <meta property="og:title" content="Managed Services Revolution: Dyashin Techno Soft's Project Management Innovation" />
        <meta name="description" content="Learn how Dyashin Techno Soft is redefining project management with managed services, offering scalable, cost-efficient solutions for a dynamic business world." />
        <meta property="og:description" content="Learn how Dyashin Techno Soft is redefining project management with managed services, offering scalable, cost-efficient solutions for a dynamic business world." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>

          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Managed Services Revolution: Dyashin TechnoSoft's Project Management Innovation"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage07;
