import React, { useEffect } from "react";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const stayingAhead = S3_URL + "/v3/assestsWebp/blogsArticlesSub/stayingAhead.webp";
const supportandFeedback = S3_URL + "/v3/assestsWebp/blogsArticlesSub/supportandFeedback.webp";
const card1 = S3_URL + "/v3/assestsWebp/blogsArticlesSub/card1.webp";
const ExpertLed = S3_URL + "/v3/assestsWebp/blogsArticlesSub/ExpertLed.webp";
const interactiveLearning = S3_URL + "/v3/assestsWebp/blogsArticlesSub/interactiveLearning.webp";

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";


const BlogsArticlesPage1 = () => {

  const description = (<>In the rapidly evolving landscape of technology, continuous learning is the key to staying ahead. <a href="https://dyashin.com/" target="blank" className="content-links fw-600">Dyashin Technosoft Pvt Ltd </a> understands this dynamic and is committed to empowering individuals and organizations through its comprehensive Learning Services. Unlocking Potential Through Learning: At Dyashin, we recognize that learning is a lifelong journey, and we have curated a suite of Learning Services designed to cater to diverse needs. Whether you're an individual looking to upskill, an enterprise aiming to enhance workforce capabilities, or an educational institution seeking innovative learning solutions, Dyashin has you covered. Customized Learning Paths: Our <a href="https://dyashin.com/learning-services/corporate" target="blank" className="content-links fw-600">Learning Services </a> are not one-size-fits-all; instead, they are tailored to meet specific goals and objectives. We offer a variety of courses spanning cutting-edge technologies, industry best practices, and soft skills development. From foundational courses to advanced certifications, Dyashin's learning paths are crafted to empower learners at every stage of their journey.</>);
  const cardData = [
    {
      image: ExpertLed,
      heading: "Empowers with AI technology:",
      description:
        (<><a href="https://dyashin.com/products" target="blank" className="content-links fw-600">Dyashin's DSJobby </a> is a leading software product in the job search industry, providing a comprehensive solution for job seekers and providers. DSJobby empowers recruitment teams with advanced AI technology, which gives them efficient tools to manage the challenging talent acquisition landscape.</>),
      link: "",
    },
    {
      image: interactiveLearning,
      heading: "Streamlines the process with precision:",
      description:
        (<>Streamlining the entire process effortlessly, <a href="https://www.instagram.com/dyashin_technosoft/" target="blank" className="content-links fw-600">DSJobby </a>transforms candidate tracking with precision, removing the necessity for manual monitoring and unwieldy spreadsheets. It makes the hiring process more efficient by streamlining it and giving recruiters real-time access to candidates' applications, interactions and interviews.</>),
      link: "",
    },
    {
      image: supportandFeedback,
      heading: "Sophisticated AI algorithms for accurate results:",
      description:
        (<>However, the most innovative feature of DSJobby from <a href="https://www.quora.com/profile/Dyashin-Technosoft" target="blank" className="content-links fw-600">Dyashin </a>is its sophisticated AI algorithms, which guarantee accurate candidate-job matching.  It provides results that are incredibly relevant and accurate, going beyond simple keyword matching by using the latest advancements in machine learning and data analysis.</>),
      link: "",
    },
    {
      image: stayingAhead,
      heading: "Perfect choice for job seekers:",
      description: (<>For job seekers, DSJobby provides a unique job search experience by utilizing an intelligent matching system that matches candidates with opportunities that align with their skills, experience, and career aspirations. Leveraging AI, the software expedites job seekers' job search, empowering them to find their desired roles and advance their careers.
Employers see DSJobby as a paradigm shift in hiring practices. Businesses may increase the quality of their hiring and drastically cut down on the time it takes to hire new employees by utilizing AI. The recruiting teams can concentrate on interacting with exceptional candidates using the tool, knowing that each match is supported by insights derived from data.
Hence, DSJobby from <a href="https://dyashintechnosoft.blogspot.com/" target="blank" className="content-links fw-600">Dyashin Techno Soft </a> is revolutionizing talent acquisition with AI, empowering job seekers and providers with advanced technology and intelligent algorithms. As the job market evolves, software stands as an innovation beacon, helping companies and candidates navigate the ever-changing landscape. Dyashin Techno Soft is a software development and engineering services company offering managed IT services.</>),
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : Empowering Learning</title>
        <meta property="og:title" content="Empowering Learning with Dyashin - Unlock Your Potential" />
        <meta property="og:description" content="Discover Dyashin's Learning Services designed to enhance skills and advance careers with AI-driven solutions. Explore our educational offerings today." />
        <meta name="description" content="Discover Dyashin's Learning Services designed to enhance skills and advance careers with AI-driven solutions. Explore our educational offerings today." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>

          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "Empowering learning with Dyashin - Unlock your potential"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <img src={card1} alt="" className="w-100 h-100" />
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage1;
