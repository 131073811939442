import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";

import ReusableBackground1 from "../../organisms/ReusableBackground1";

import { useParams } from "react-router-dom";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ConsultingImageSection from "../../molecules/ConsultingImageSection";
import ConsultingContentSection from "../../molecules/ConsultingContentSection";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Campus =
  S3_URL + "/v3/assestsWebp/learningServices/campus.webp";
const Corporate =
  S3_URL + "/v3/assestsWebp/learningServices/corporate.webp";
const OffCampus =
  S3_URL + "/v3/assestsWebp/learningServices/offCampus.webp";
const Banner = S3_URL + "/v3/assestsWebp/ConsultingServices/banner.webp";

function LearningServicesPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const cardData = [
    {
      image: Corporate,
      heading: "Corporate",
      description:
        "Our Corporate Learning Program is a meticulously curated skill development initiative aligned with business objectives. Crafted by industry experts, it empowers our workforce to cross-skill or upskill, preparing them to tackle future business challenges effectively.",
      link: "/contact-us",
      id: "corporate",
    },
    {
      image: Campus,
      heading: "Campus",
      description:
        "Our campus programs offer a comprehensive experience, featuring tech-enabled learning, industry-relevant projects, holistic skill development, and personalized career development to prepare students for success in today's dynamic workforce.",
      link: "/contact-us",
      id: "campus",
    },
    {
      image: OffCampus,
      heading: "Off Campus",
      description:
        "Dyashin offers unique solutions catering to the retail segment, providing candidates with technology proficiency, confidence, and immediate project productivity from day one.",
      link: "contact-us",
      id: "off-campus",
    },
  ];

  useEffect(() => window.scrollTo(0, 0), []);
  const { section } = useParams();
  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);
  return (
    <>
      <Helmet>
        <title>Dyashin : Learning Services</title>
        <meta property="og:title" content="Enhance Your Skills with Dyashin's Learning Services - Empower Your Career" />
        <meta property="og:description" content="Dyashin's Learning Services are designed to foster professional growth and skill enhancement. Join our programs to advance your career with the latest knowledge." />
        <meta name="description" content="Dyashin's Learning Services are designed to foster professional growth and skill enhancement. Join our programs to advance your career with the latest knowledge." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Learning Services">
        <ReusableBackground1>
          <Grid
            container
            className="mt-5 d-flex justify-content-center"
            sx={{ marginTop: 5 }}
          >
            <Grid
              container
              item
              xs={10}
              className="d-flex align-items-center justify-content-between"
            >
              {cardData.map((val, idx) =>
                isSmallScreen ? (
                  <Grid container key={idx} className="mb-5">
                    <Grid
                      item
                      xs={12}
                      className="d-flex justify-content-center mb-5"
                    >
                      <ConsultingImageSection imageURL={val.image} />
                    </Grid>
                    <Grid item xs={12}>
                      <ConsultingContentSection
                        link={`/learning-services/${val.link}`}
                        footer={true}
                        badgetext="Services"
                        heading={val.heading}
                        description={val.description}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    key={idx}
                    className="d-flex align-items-center justify-content-between"
                    marginBottom={cardData.length - 1 !== idx && 5}
                  >
                    {idx % 2 === 0 ? (
                      <Grid item sm={5} xs={12}>
                        <ConsultingImageSection imageURL={val.image} />
                      </Grid>
                    ) : (
                      <Grid item sm={6.5} xs={12}>
                        <div id={val.id}>
                          <ConsultingContentSection
                            link={`/learning-services/${val.link}`}
                            footer={true}
                            badgetext="Services"
                            heading={val.heading}
                            description={val.description}
                          />
                        </div>
                      </Grid>
                    )}

                    {idx % 2 === 0 ? (
                      <Grid item sm={6.5} xs={12}>
                        <div id={val.id}>
                          <ConsultingContentSection
                            link={`/learning-services/${val.link}`}
                            footer={true}
                            badgetext="Services"
                            heading={val.heading}
                            description={val.description}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <Grid item sm={5} xs={12}>
                        <ConsultingImageSection imageURL={val.image} />
                      </Grid>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
}

export default LearningServicesPage;
