import React from "react";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ConsultingImageSection from "../ConsultingImageSection";
import CaseStudySubContent from "../CaseStudySubContent";

function Casestudy1({ cardData = [] }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container paddingBottom={10}>
      <Grid container item xs={12}>
        {cardData.map((val, idx) =>
          isSmallScreen ? (
            <Grid
              container
              key={idx}
              className="mb-5"
              display={"flex"}
              flexWrap={"wrap"}
            >
              <Grid item xs={12} className="d-flex justify-content-center mb-5">
                <ConsultingImageSection imageURL={val.image} />
              </Grid>
              <Grid item xs={12}>
                <CaseStudySubContent
                  lists={val.lists}
                  heading={val.heading}
                  maindescription={val.description}
                  footer={false}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container className={idx === 0 ? "my-3" : "my-3"}>
              <Grid item xs={12}>
                {idx % 2 ? (
                  <div
                    style={{
                      height: "80%",
                      float: "right",
                      width: "40%",
                      marginLeft: "40px",
                    }}
                  >
                    <img src={val.image} alt="" className="w-100 h-100" />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "80%",
                      float: "left",
                      width: "40%",
                      marginRight: "50px",
                    }}
                  >
                    <img src={val.image} alt="" className="w-100 h-100" />
                  </div>
                )}
                <div>
                  <Typography className="fw-700 fs-24">
                    {val.heading}
                  </Typography>
                  <Typography
                    className="fw-400 fs-12"
                    textAlign={"start"}
                    lineHeight={"160%"}
                  >
                    {val.description}
                  </Typography>
                  <List
                  className="fs-12"
                    sx={{
                      listStyleType: "number",
                      listStyle: "2px",
                    }}
                  >
                    {val.lists.map((list, index) => (
                      <ListItemText key={index} sx={{ display: "list-item" }}>
                        <Typography
                          className="fw-400 fs-12 txt-description"
                          textAlign={"start"}
                        >
                          <span className="chip-title-blue">
                            {list.heading}
                          </span>
                          {list.subheading}
                        </Typography>
                      </ListItemText>
                    ))}
                  </List>
                </div>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
}

export default Casestudy1;
