import { Avatar, Chip } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import COLORS from "../../../constants/colorConstants";

const {BLUE2, BLUE5, WHITE1} = COLORS

function ChipComponent({
  label = "",
  avatar = <Avatar></Avatar>,
  showAvatar = false,
  avatarPosition = "start",
  variant = "contained",
  size = "small",
  chipMuiProps = "",
  borderRadius = "20px",
  fullWidth = true,
  disabled = false,
  children,
  iconPosition = "start",
  icon = <EastIcon />,
  showIcon = false,
  selected = false,
  sx = {},
  index = 0,
  onBtnClick = () => {},
  handleDelete = () => {},
  onMouseLeave = () => {},
  onMouseEnter = () => {},
}) {

  let IconProp = showIcon
    ? iconPosition === "start"
      ? {
          icon: icon,
        }
      : iconPosition === "center"
      ? { label: icon }
      : { deleteIcon: icon, onDelete: handleDelete }
    : {};

  let AvatarProp = showAvatar
    ? avatarPosition === "start"
      ? {
          avatar: avatar,
        }
      : {
          deleteIcon: avatar,
          onDelete: handleDelete,
        }
    : {};
   

  return (
    <Chip
      key={index}
      label={label}
      {...IconProp}
      {...AvatarProp}
      variant={variant}
      onClick={() => {
        onBtnClick(index);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      size={size}
      className={`px-3 py-2 fw-700 fs-10 text-uppercase ${chipMuiProps}`}
      sx={{
        textTransform: "capitalize",
        letterSpacing: 2.4,
        bgcolor: selected ? BLUE2 : BLUE5,
        borderRadius: { borderRadius },
        color: selected ? WHITE1 : BLUE2,
        ...sx,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {children}
    </Chip>
  );
}

export default ChipComponent;
