const COLORS = {
  WHITE1: "#ffffff",
  WHITE2: "#FFFFFFB2",
  WHITE3: "#f1f1f1",
  WHITE4: "rgba(255, 255, 255, 0.7)",
  WHITE5: "#BDBDC1B2",
  WHITE6: "#e0e0e0",
  WHITE7: "#D3D2AE",
  WHITE8: "#DDEDF4",

  BLACK1: "#000",
  BLACK2:"rgba(0, 0, 0, 0.4)",
  BLACK3: "#050505",
  BLACK4: "rgba(0, 0, 0, 0.1)",
  BLACK5: " rgba(25, 24, 37, 0.08)",


  BLUE1:"#191825",
  BLUE2:"#24ABE3",
  BLUE3: "rgba(141, 171, 225, 0.26)" ,
  BLUE4: "#d8eaf2",
  BLUE5: "#8DABE142",
  BLUE6: "#081d44",
  BLUE7: "#1f3763",
  BLUE8: "#229cd0",
  BLUE9: "#00008B",
  BLUE10: "#29b6f6",
  BLUE11: "rgba(36, 171, 227, 0.6)",
  BLUE12: "#555664",
  BLUE13: "#70C4EB",
  BLUE14: "#1181b2",
  BLUE15: "#9FCCE066",
  BLUE16:  "#9FCCE0",
  

  ORANGE1: "#FA921F",


  PURPLE1: "#d1d0d6",
  PURPLE2: "#81808C",
  PURPLE3: "#BDBDC1",
  PURPLE4: "#C9D0DE",
  PURPLE5: "#A6A6A6",
  PURPLE6: "#B3B3B3",

  RED1: "#FF0000",

  GREY1: "#808080",
  GREY2: "#ccc",
  GREY3: "#706c61",
  GREY4: "#4a4a4a",

  

};

export default COLORS