import { Box, Stack } from "@mui/material";
import React from "react";
const S3_URL = process.env.REACT_APP_S3_URL;
const ChatBotIcon = S3_URL + "/v3/assestsWebp/common/ChatBotIcon.webp";

function Helplinebutton({
  onChatClick = () => { },
  height = '70px',
  bottom = "",
  right = "",
  width = "70px",
  sx = {},
  className = "",
  zIndex = 100,
}) {
  return (
    <Box onClick={onChatClick}
      className={`position-fixed${className}`}
      sx={{
        zIndex: { zIndex },
        bottom: { bottom },
        right: { right },
        cursor: 'pointer',
        ...sx,
      }}
    >
      <Stack>
        <Box
          className={
            " d-flex align-items-center justify-content-center"
          }
          
        >
          <img src={ChatBotIcon} alt="helpIcon" width={width} height={height} />
        </Box>
      </Stack>
    </Box>
  );
}

export default Helplinebutton;
