import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const card1 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>In today's rapidly evolving world, staying ahead in your career means continuously learning and growing. At Dyashin Technosoft, we believe that leveraging technology for professional development is crucial. It allows individuals to enhance their skills, stay updated with industry trends, and maintain a competitive edge. Let's explore how technology can boost your professional development journey. </>
  );
  const cardData = [
    {
      image: card1,
      heading: "Online Learning Platforms",
      description:"Online learning platforms have revolutionised professional development. These platforms offer a wide range of software development courses on various subjects, including digital marketing, and project management. Our campus programs At Dyashin Technosoft provide a well-rounded experience, incorporating technology-driven education, real-world industry projects, comprehensive skill-building, and tailored career development to equip students for success in today's ever-changing job market.",
      link: "",
    },
    {
      image: card2,
      heading: "Interactive Learning Tools",
      description:"Technology has introduced interactive tools that make learning more engaging and effective. For example, virtual labs and coding simulators allow learners to practice their skills in a hands-on environment. These tools provide instant feedback, helping you learn from your mistakes and improve quickly. Whether you are new to a field or looking to sharpen your skills, interactive tools are invaluable for professional growth.",
      link: "",
    },
    {
      image: card3,
      heading: "Webinars and Online Workshops",
      description:"Webinars and online workshops have become popular for professional development. These live sessions feature industry experts who share their knowledge and insights on various topics. Attending webinars allows you to learn about the latest trends and best practices in your field. You can also ask questions and participate in discussions, making the learning experience interactive and enriching. Corporate Learning Program at Dyashin Technosoft is a thoughtfully designed skill enhancement initiative that is aligned with our business goals. Developed by industry specialists, it equips our workforce with the ability to cross-skill or upskill, ensuring they are ready to effectively address future business challenges. This flexibility makes it easy for busy professionals to gain new skills without disrupting their work-life balance.",
      link: "",
    },
    {
      image: card4,
      heading: "Mobile Learning",
      description:"The rise of smartphones and tablets has made learning more accessible than ever. Mobile learning apps enable you to learn on the go. Whether you're commuting, waiting for an appointment, or taking a lunch break, you can access course materials and learn new skills anytime, anywhere. This convenience ensures that continuous learning fits seamlessly into your daily routine. Virtual Reality and Augmented Reality : Virtual reality (VR) and augmented reality (AR) are cutting-edge technologies transforming professional development. VR creates immersive learning experiences that simulate real-world scenarios. For instance, software developers can practice coding in a virtual environment, gaining practical experience without any risk .AR superimposes digital information onto the physical world ,creating interactive and immersive learning experiences . These technologies make learning more dynamic and effective.",
      link: "",
    },
    {
      image: card5,
      heading: "Personalized Learning Paths",
      description:"Technology enables personalized learning experiences tailored to individual needs and goals. Online platforms use algorithms to recommend courses based on your interests and skill levels. This personalization ensures that you spend time on the most relevant and beneficial content for your professional development.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Conclusion",
      description: (
        <>
          In conclusion, technology plays a vital role in professional development. It offers flexible, engaging, and personalized learning opportunities that help you stay ahead in your career. At Dyashine Technosoft, we encourage professionals to embrace technology for their learning and growth. Whether you're exploring online learning platforms, attending webinars, or using interactive tools, technology can significantly enhance your professional journey. Embrace these technological advancements and unlock your full potential in your career.
        </>
      ),
      link: "",
    }
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : </title>
        <meta
          property="og:title"
          content="Leveraging Technology for Enhanced Professional Development"
        />
        <meta
          name="description"
          content="Explore how leveraging technology in professional development boosts learning efficiency, personal growth, and career advancement in the digital age."
        />
        <meta
          property="og:description"
          content="Explore how leveraging technology in professional development boosts learning efficiency, personal growth, and career advancement in the digital age."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;