import { Grid } from "@mui/material";
import React from "react";

import ContentSection from "../../../molecules/ContentSection";
import COLORS from "../../../../constants/colorConstants";

const {BLUE2, BLUE5} = COLORS

const S3_URL = process.env.REACT_APP_S3_URL;

const jodcard =
  S3_URL+"/v3/assestsWebp/jobVacancy/cardImg.webp";

function JoinOurTeamCard() {
  return (
    <>
      <Grid container className="mt-5">
        <Grid item xs={12} md={7} padding={2} alignContent={"center"} sx={{}}>
          <ContentSection
            title={"Join Our Team At Dyashin"}
            textAlign="left"
            badgeLabel={"CAREERS OPENING"}
            sxChip={{
              backgroundColor: BLUE5,
              color: BLUE2,
              marginBottom: 1,
            }}
            chipMuiProps="content-chip1"
            description={(<>At <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Dyashin</a>, we believe in fostering a dynamic and inclusive work
                environment where innovation thrives and talents are nurtured.
                As we continue to grow and expand our horizons, we are
                constantly seeking passionate individuals who share our
                commitment to excellence. Whether you're an experienced
                professional or just starting your career journey, <a href={process.env.REACT_APP_LINKEDIN_URL} target="blank" className="content-links fw-600">Dyashin </a>
                offers exciting opportunities to collaborate on impactful
                projects and develop your skills alongside a talented team. Join
                us in shaping the future of technology and discover a
                rewarding career path with ample room for growth and
                development.</>)}
            descriptionMuiProp="fs-14"
            titleMuiProp="mb-2"
            sxDescription={{ fontSize: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={5} padding={2} className="d-flex justify-content-center align-items-center" >
          <img src={jodcard} alt="" className="w-65p" />
        </Grid>
      </Grid>
    </>
  );
}

export default JoinOurTeamCard;
