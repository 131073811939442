import React, { useEffect } from "react";
import PageLayout from "../../organisms/PageLayout";
import SoftwareDevelopment from "./SoftwareDevelopment";
import SoftwareTesting from "./SoftwareTesting";
import EngineeringServices from "./EngineeringServices";
import Semiconductor from "./Semiconductor";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner =
  S3_URL + "/v3/assestsWebp/solutions/banner.webp";

function SolutionPage() {
  const { section } = useParams();

  console.log("section", section);

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -55;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);
  return (
    <>
      <Helmet>
        <title>Dyashin : Solutions</title>
        <meta property="og:title" content="Tailored Solutions by Dyashin - Driving Business Innovation" />
        <meta name="description" content="Dyashin specializes in bespoke solutions that drive innovation and growth for businesses. Explore our custom-tailored services to meet your unique needs" />
        <meta property="og:description" content="Dyashin specializes in bespoke solutions that drive innovation and growth for businesses. Explore our custom-tailored services to meet your unique needs" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Solutions">
        <SoftwareDevelopment />
        <SoftwareTesting />
        <EngineeringServices />
        <Semiconductor />
      </PageLayout>
    </>
  );
}

export default SolutionPage;
