import ImageSectionComponent from "../../molecules/ImageSectionComponent";
import TwoSectionGridComponent from "../../organisms/TwoSectionGridComponent";
import ContentSection from "../../molecules/ContentSection";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ServicesConsultingCards from "../ServicesConsultingCards";

function ServicesConsultingServices({
  children,
  VerticalImg = "",
  HorizontalImg = "",
  description = ``,
  heading = "",
  badgeText = "",
  textAlignment = "left",
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        paddingY={1}
        position={"relative"}
      >
        <Grid xs={12}>
          {isSmallScreen ? (
            <TwoSectionGridComponent textAlign="left">
              <ImageSectionComponent
                verticalImg={VerticalImg}
                horizontalImg={HorizontalImg}
              />
              <ContentSection
                badgeLabel={badgeText}
                title={heading}
                textAlign={textAlignment}
                chipMuiProps="content-chip1"
              >
                <Typography
                  className="fs-12 fw-400 txt-description"
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </ContentSection>
            </TwoSectionGridComponent>
          ) : (
            <TwoSectionGridComponent textAlign="left">
              <ContentSection
                badgeLabel={badgeText}
                title={heading}
                textAlign={textAlignment}
                chipMuiProps="content-chip1"
              >
                <Typography
                  className="fs-12 fw-400 txt-description text-start"
                  
                >
                  {children}
                </Typography>
              </ContentSection>
              <ImageSectionComponent
                verticalImg={VerticalImg}
                horizontalImg={HorizontalImg}
              />
            </TwoSectionGridComponent>
          )}

          <ServicesConsultingCards />
        </Grid>
      </Grid>
    </>
  );
}

export default ServicesConsultingServices;
