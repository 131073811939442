import React from "react";
import COLORS from "../../../../constants/colorConstants";

const {BLUE1} = COLORS
function Quora() {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2854_3530"
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="17"
          height="16"
        >
          <path d="M17.4902 1H1.49023V17H17.4902V1Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2854_3530)">
          <path
            d="M9.70419 13.4507C9.15091 12.3618 8.50188 11.2619 7.23589 11.2619C6.99387 11.2619 6.75215 11.302 6.53033 11.4032L6.10025 10.5425C6.62437 10.0928 7.47126 9.7363 8.55987 9.7363C10.2534 9.7363 11.1225 10.552 11.8124 11.5933C12.222 10.7044 12.4168 9.50379 12.4168 8.01572C12.4168 4.29959 11.2546 2.39149 8.53996 2.39149C5.86488 2.39149 4.70906 4.29959 4.70906 8.01572C4.70906 11.7122 5.86488 13.6007 8.53996 13.6007C8.96512 13.6007 9.3502 13.554 9.70419 13.4507ZM10.3673 14.7476C9.78086 14.9047 9.15782 14.9916 8.53996 14.9916C4.97789 14.9916 1.49023 12.1492 1.49023 8.01572C1.49023 3.84297 4.97789 1 8.53996 1C12.1618 1 15.6159 3.82249 15.6159 8.01572C15.6159 10.3481 14.5276 12.2435 12.9458 13.4686C13.4569 14.2344 13.983 14.743 14.7157 14.743C15.5153 14.743 15.8378 14.1251 15.8917 13.6405H16.933C16.9939 14.2857 16.6711 16.9675 13.761 16.9675C11.9982 16.9675 11.0662 15.9458 10.3673 14.7476Z"
            fill={BLUE1}
          />
        </g>
      </svg>
    </>
  );
}

export default Quora;
