import React from "react";

function PerformanceTestingIcon({ svgcolor = "white" }) {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.90654 23.8609H8.90663C9.36202 23.8609 9.75364 23.5148 9.80829 23.0594C9.97223 21.6933 10.3274 20.3545 10.8557 19.0976C11.3748 17.8499 12.0579 16.675 12.8867 15.6003C13.2145 15.2451 13.1963 14.6895 12.8502 14.3525L9.31648 10.8279L9.30737 10.8188C9.28916 10.8005 9.27094 10.7823 9.25272 10.7732C9.07057 10.6184 8.83377 10.5546 8.59697 10.5728C8.35107 10.5911 8.13248 10.7095 7.97765 10.9007C6.56597 12.5947 5.42752 14.4891 4.5805 16.5201L4.56229 16.5657C3.7426 18.5511 3.22347 20.655 3.01399 22.8044C3.00488 22.8591 3.00488 22.9046 3.00488 22.9592C3.00488 23.4602 3.40562 23.8609 3.90654 23.8609ZM4.9357 22.0485C5.1725 20.3909 5.61878 18.7606 6.26542 17.2123C6.91206 15.664 7.74996 14.1977 8.76091 12.8407L11.0105 15.0902C10.2728 16.1285 9.66257 17.2396 9.17076 18.4145C8.68805 19.5894 8.33285 20.8098 8.11427 22.0485H4.9357Z"
          fill={svgcolor}
        />
        <path
          d="M46.0933 26.0286H41.0932H40.8655V26.056C40.5103 26.147 40.237 26.4476 40.1915 26.8301C39.7361 30.5278 37.9419 33.925 35.1368 36.4022C32.3407 38.8704 28.7432 40.2275 25.0091 40.2275C21.2749 40.2275 17.6774 38.8704 14.8814 36.4022C12.0671 33.925 10.2729 30.5369 9.80842 26.8483C9.76288 26.3747 9.37125 26.0195 8.90676 26.0195H3.90667H3.67898V26.0469C3.49683 26.0924 3.33289 26.1926 3.20538 26.3474C3.05055 26.5296 2.97769 26.7664 2.99591 27.0123C3.50593 32.486 6.03786 37.5407 10.1272 41.2475C14.1983 44.9452 19.4807 46.9853 24.9909 46.9853C30.501 46.9853 35.7834 44.9452 39.8545 41.2475C43.9165 37.5589 46.4485 32.5315 46.9767 27.0851C46.9858 27.0396 46.9858 26.9849 46.9858 26.9303C47.004 26.4294 46.5942 26.0286 46.0933 26.0286ZM8.12351 27.8411C8.77926 31.6572 10.7465 35.1727 13.6701 37.7502C16.8031 40.5098 20.8287 42.0308 25 42.0308C29.1804 42.0308 33.2059 40.5098 36.3299 37.7502C39.2534 35.1727 41.2207 31.6572 41.8764 27.8411H45.0641C44.3992 32.4677 42.1223 36.7483 38.6341 39.9087C34.9 43.2967 30.0547 45.1729 25 45.1729C19.9452 45.1729 15.1 43.3058 11.3658 39.9087C7.8776 36.7483 5.60069 32.4677 4.93583 27.8411H8.12351Z"
          fill={svgcolor}
        />
        <path
          d="M36.9676 14.5348L36.9949 14.5531C36.8218 14.8627 36.8401 15.2635 37.0769 15.5549C37.9239 16.6387 38.6252 17.8318 39.1534 19.1069L39.1989 19.2162C39.6999 20.4366 40.0277 21.7299 40.1917 23.0414C40.2372 23.515 40.6288 23.8702 41.1024 23.8702H46.1025H46.3302V23.8429C46.5124 23.7973 46.6763 23.6971 46.7947 23.5423C46.9495 23.3601 47.0224 23.1233 47.0042 22.8774C46.7947 20.6825 46.2665 18.5422 45.4286 16.5203C44.5907 14.4984 43.4522 12.6131 42.0587 10.9282C42.0314 10.8918 42.0041 10.8645 41.9768 10.8371C41.6216 10.4819 41.0478 10.4819 40.6926 10.8371L37.1588 14.3618L36.9676 14.5348ZM40.8292 18.4056C40.3465 17.2398 39.7272 16.1287 38.9895 15.0813L41.2391 12.8317C42.25 14.1796 43.097 15.6551 43.7345 17.2034C44.3812 18.7608 44.8275 20.3819 45.0643 22.0395H41.8766C41.6853 20.9102 41.3757 19.7899 40.9476 18.7152H40.9567L40.8292 18.4056Z"
          fill={svgcolor}
        />
        <path
          d="M26.111 9.1158C26.2021 9.471 26.5026 9.74423 26.8851 9.78977C28.2422 9.95371 29.5628 10.2998 30.8196 10.8189L30.8561 10.8371C32.1129 11.3563 33.2969 12.0394 34.3625 12.8682C34.5356 13.023 34.7541 13.105 34.9727 13.105C35.2095 13.105 35.4372 13.0139 35.6194 12.8408L39.144 9.31617C39.1622 9.29796 39.1896 9.27063 39.2078 9.25242C39.3626 9.06116 39.4355 8.83347 39.4081 8.58756C39.3808 8.34166 39.2715 8.12307 39.0803 7.96824C37.3954 6.57477 35.5101 5.43632 33.4882 4.59841L33.4791 4.58931C33.47 4.58931 33.4609 4.5802 33.4517 4.5802C31.4481 3.7514 29.326 3.22316 27.1584 3.01369C27.1128 3.00458 27.0582 3.00458 27.0035 3.00458C26.5026 3.00458 26.0928 3.41442 26.0928 3.91534V8.89722V9.12491H26.111V9.1158ZM37.1312 8.74239L34.8816 10.992C33.8434 10.2543 32.7231 9.63494 31.5574 9.15224C31.5483 9.14313 31.53 9.14313 31.5118 9.13402C30.346 8.65132 29.1347 8.30522 27.9052 8.09575V4.92629C29.5719 5.16309 31.1931 5.60936 32.7505 6.25601C32.7596 6.26511 32.7778 6.26511 32.796 6.27422C34.3352 6.90265 35.7924 7.74055 37.1312 8.74239Z"
          fill={svgcolor}
        />
        <path
          d="M14.3895 12.8226L14.5352 12.9683L14.5535 12.9501C14.6992 13.0412 14.8631 13.0867 15.0362 13.0867C15.2365 13.0867 15.4278 13.023 15.5917 12.8955C16.6846 12.0484 17.8777 11.3472 19.1437 10.828C20.4097 10.3089 21.7303 9.95369 23.0964 9.78975C23.5609 9.74422 23.9161 9.35259 23.9161 8.8881V3.90622V3.67853H23.8888C23.7795 3.25957 23.3788 2.95902 22.9234 3.00456C20.7375 3.21404 18.6064 3.73317 16.5936 4.56197C16.5753 4.57108 16.5662 4.57108 16.548 4.58018C14.5261 5.41809 12.6408 6.54743 10.9468 7.9409C10.9104 7.96823 10.874 7.99555 10.8466 8.02287C10.6736 8.19592 10.5825 8.42361 10.5825 8.66951C10.5825 8.91542 10.6736 9.14311 10.8466 9.31616L14.3895 12.8226ZM12.8685 8.74237C14.2165 7.73143 15.6919 6.89352 17.2584 6.24688C17.2675 6.24688 17.2766 6.23777 17.2857 6.23777C18.8249 5.60024 20.4461 5.16307 22.1037 4.92627V8.10484C20.8559 8.31432 19.6355 8.66951 18.4606 9.15222C17.2857 9.63492 16.1655 10.2542 15.1272 10.992L12.8685 8.74237Z"
          fill={svgcolor}
        />
        <path
          d="M38.0425 20.8284C38.1427 20.4914 38.1245 20.118 37.9879 19.781C37.8513 19.444 37.6145 19.1617 37.3048 18.9795C36.9769 18.7883 36.6035 18.7245 36.2392 18.7974L27.9513 20.4914C27.6871 20.3184 27.4139 20.1635 27.1133 20.036C25.811 19.4714 24.3537 19.4531 23.0331 19.9814C21.7034 20.5096 20.6651 21.5206 20.1005 22.823C19.5358 24.1254 19.5176 25.5735 20.0458 26.8941C20.5741 28.2147 21.585 29.253 22.8965 29.8176C23.5705 30.1091 24.29 30.2548 25.0004 30.2548C25.6652 30.2548 26.3392 30.1273 26.9767 29.8723C28.3064 29.344 29.3447 28.3331 29.9094 27.0307C30.0369 26.7392 30.1371 26.4387 30.2008 26.129L37.3959 21.6754C37.7055 21.5024 37.9332 21.1927 38.0425 20.8284ZM30.2282 23.8886C30.2191 23.8339 30.2099 23.7793 30.1917 23.7246C30.128 23.4696 30.0551 23.2237 29.9549 22.9778C29.9094 22.8594 29.8547 22.7501 29.8001 22.6408C29.6999 22.4313 29.5906 22.2401 29.4722 22.0488L35.3011 20.8557L30.2464 23.9887C30.2464 23.9523 30.2373 23.9159 30.2282 23.8886ZM27.1771 22.1945C27.4139 22.3858 27.6325 22.6044 27.8146 22.8503C27.9968 23.0871 28.1425 23.3512 28.2609 23.6335L28.27 23.6518C28.2791 23.6791 28.2882 23.7064 28.2973 23.7337C28.3064 23.7702 28.3247 23.7975 28.3338 23.8339C28.3429 23.8612 28.352 23.8886 28.3611 23.9159C28.3702 23.9614 28.3884 23.9978 28.3975 24.0434C28.4066 24.0616 28.4066 24.0798 28.4157 24.098C28.4522 24.2346 28.4795 24.3804 28.4977 24.5261C28.4977 24.5352 28.4977 24.5352 28.4977 24.5443C28.5341 24.8449 28.525 25.1545 28.4795 25.4551C28.4795 25.4551 28.4795 25.4551 28.4795 25.4642C28.434 25.7556 28.352 26.0471 28.2336 26.3294C27.8602 27.1946 27.1771 27.8595 26.3028 28.2056C25.4284 28.5517 24.4721 28.5335 23.6069 28.1691C22.7417 27.7957 22.0768 27.1127 21.7307 26.2474C21.3846 25.3822 21.3937 24.4259 21.7672 23.5607C22.1406 22.6955 22.8236 22.0306 23.698 21.6845C24.1169 21.5206 24.5541 21.4386 25.0004 21.4386C25.474 21.4386 25.9476 21.5388 26.3938 21.73C26.6762 21.8484 26.9403 22.0033 27.1771 22.1945Z"
          fill={svgcolor}
        />
        <path
          d="M24.062 22.577L24.0438 22.5861C23.4154 22.8411 22.9145 23.3238 22.6504 23.9431C22.3862 24.5624 22.368 25.2546 22.623 25.8921C22.8781 26.5297 23.3608 27.0215 23.9801 27.2947C24.2988 27.4313 24.6449 27.5042 24.9819 27.5042C25.3007 27.5042 25.6195 27.4404 25.9291 27.322C26.5666 27.067 27.0676 26.5843 27.3317 25.965C27.5958 25.3366 27.6049 24.6444 27.359 24.016C27.104 23.3784 26.6213 22.8866 26.002 22.6134C25.3918 22.3402 24.6996 22.3219 24.062 22.577ZM25.6741 25.2364C25.5921 25.4185 25.4555 25.5551 25.2734 25.628C25.0912 25.7009 24.8908 25.7009 24.7087 25.6189C24.5448 25.546 24.4172 25.4276 24.3353 25.2637C24.3262 25.2273 24.308 25.2 24.2988 25.1726C24.2442 25.0087 24.2533 24.8265 24.3262 24.6626C24.399 24.4987 24.5265 24.362 24.6814 24.2892C24.7087 24.2801 24.7451 24.271 24.7724 24.2528C24.9455 24.1981 25.1276 24.2072 25.2916 24.2801C25.4737 24.362 25.6103 24.4987 25.6832 24.6808C25.7561 24.8539 25.7561 25.0542 25.6741 25.2364Z"
          fill={svgcolor}
        />
        <path
          d="M14.271 20.1544C14.0889 20.5643 13.9249 20.9832 13.7974 21.4022C13.6608 21.8393 13.5515 22.2674 13.4604 22.6955C13.4149 22.9323 13.4604 23.1782 13.5971 23.3785C13.7337 23.5789 13.9431 23.7155 14.1799 23.7611C14.2346 23.7702 14.2983 23.7793 14.353 23.7793C14.5351 23.7793 14.7082 23.7246 14.863 23.6244C15.0634 23.4878 15.2 23.2875 15.2455 23.0415C15.3184 22.6681 15.4095 22.2947 15.5188 21.9486C15.6281 21.5934 15.7647 21.2382 15.9195 20.9013C16.1199 20.4459 15.9195 19.9085 15.4641 19.699C15.0178 19.4987 14.4805 19.699 14.271 20.1544Z"
          fill={svgcolor}
        />
        <path
          d="M18.8429 14.9537C18.2691 15.3089 17.7227 15.7096 17.2218 16.1559C17.0396 16.3198 16.9303 16.5384 16.9212 16.7752C16.903 17.0211 16.985 17.2488 17.1489 17.431C17.4768 17.8044 18.0597 17.8408 18.4331 17.5129C18.852 17.1395 19.3074 16.8025 19.7992 16.502C20.2819 16.2014 20.8011 15.9464 21.3293 15.7369C23.1964 14.9992 25.2183 14.8353 27.1673 15.2725C29.1345 15.7096 30.9014 16.7206 32.2676 18.1869C32.4497 18.3782 32.6865 18.4783 32.9324 18.4783C33.151 18.4783 33.3787 18.3964 33.5517 18.2324C33.9161 17.8955 33.9434 17.3126 33.5973 16.9483C31.9761 15.2178 29.8905 14.0247 27.5589 13.5056C25.2456 12.9864 22.8594 13.1777 20.6553 14.0611C20.0087 14.307 19.3985 14.6076 18.8429 14.9537Z"
          fill={svgcolor}
        />
        <path
          d="M29.8907 32.7957C29.408 32.313 28.7613 32.0488 28.0873 32.0488H21.9215C21.2657 32.0488 20.6464 32.2947 20.1728 32.7501C20.1546 32.7683 20.1364 32.7774 20.1182 32.8048C19.6355 33.2875 19.3713 33.925 19.3713 34.6081C19.3713 35.282 19.6355 35.9196 20.1091 36.3932L20.1182 36.4023C20.6009 36.885 21.2384 37.1491 21.9215 37.1491H28.0873C28.7704 37.1491 29.408 36.885 29.8907 36.4023L30.0728 36.2201L30.0637 36.211C30.4371 35.7556 30.6466 35.191 30.6466 34.599C30.6375 33.925 30.3734 33.2784 29.8907 32.7957ZM21.3932 34.0889C21.4023 34.0798 21.4206 34.0707 21.4297 34.0525C21.5663 33.9341 21.7393 33.8612 21.9215 33.8612H28.0873C28.2786 33.8612 28.4699 33.9341 28.6065 34.0798C28.7431 34.2164 28.8251 34.4077 28.8251 34.599C28.8251 34.772 28.7613 34.9451 28.652 35.0726L28.6156 35.109C28.479 35.2456 28.2877 35.3276 28.0965 35.3276H21.9306C21.7393 35.3276 21.5481 35.2547 21.4115 35.1181C21.2748 34.9815 21.1929 34.7902 21.1929 34.599C21.1838 34.4077 21.2566 34.2256 21.3932 34.0889Z"
          fill={svgcolor}
        />
      </svg>
    </>
  );
}

export default PerformanceTestingIcon;
