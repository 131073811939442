
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;


const Banner =
  S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const ExpertLed =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img1.webp";
const card2 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage22/img3.webp";
const card3 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card4 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card5 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const card6 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img1.webp";
const card7 =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const mainPost =  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img1.webp";

const BlogsArticlesPage21 = () => {
  const description = (<>There are many digital platforms and organizations that provide top-notch software development training programs. For professionals in the tech industry, staying updated with the latest advancements and trends is not just an option but a necessity. At Dyashin Technosoft, we recognize the essential importance of continuous learning in staying ahead of the competition and fostering creativity. This article delves into the significance of ongoing education in the technology field and showcases the top software development courses and training programs available to assist you in maintaining a competitive edge.</>);
  const cardData = [
    {
      image: ExpertLed,
      heading: "Embracing change and innovation",
      description:"The tech industry is recognized for its constantly changing characteristics. Often, new programming languages, frameworks, tools, and approaches are introduced, altering the landscape of software development. To remain current with these shifts, professionals in technology must embrace a mindset of ongoing education. By consistently improving their abilities, they can effectively leverage emerging technologies and methodologies to create innovative solutions. At Dyashin Technosoft, we stress the importance of keeping abreast of industry trends through continuous learning and professional growth.",
      link:"",
    },
    {
      image: card2,
      heading: "Improving problem-solving capabilities",
      description:"Ongoing education not only aids technology professionals in staying informed but also enhances their problem-solving skills. The more knowledge and abilities you acquire, the better prepared you are to address complex issues. Taking the best software development courses can offer a comprehensive understanding of various programming styles, design strategies, and software structures. This insight enables you to create more effective and efficient solutions, contributing to the success of your projects and the advancement of your organization.",
      link:"",
    },
    {
      image: card3,
      heading: "Boosting job stability and career progression",
      description:"In a sector where expertise can rapidly become obsolete, ongoing learning is essential for maintaining employment and advancing in one's career. Employers are more inclined to keep and promote individuals who show a dedication to professional improvement. By investing in software developer training, you not only enhance your present skills but also open the door to new career prospects. At Dyashin Technosoft, we provide a variety of training programs aimed at helping our employees stay ahead of the curve and achieve their career goals.",
      link:"",
    },
    {
      image: card4,
      heading: "Creating a community of like-minded individuals",
      description:"A significant benefit of ongoing education is the chance to connect with individuals who share your enthusiasm for technology and innovation. By participating in courses, workshops, and seminars, you can establish a network of peers who are passionate about the same things. These relationships can result in advantageous partnerships, guidance from experienced individuals, and a more thorough understanding of best practices in the field. Dyashin Technosoft encourages our team members to engage in continuous learning activities, promoting a collaborative and supportive workplace culture.",
      link:"",
    },
    {
      image: card5,
      heading: "Seeking the best software development courses",
      description:"To support continuous learning, it's crucial to select the appropriate educational resources. There are many digital platforms and organizations that provide top-notch software development training programs. Some of the top choices include Coursera, Udacity, and Pluralsight, which provide courses on various subjects such as web development, mobile app creation, data analysis, and artificial intelligence. Moreover, many universities and colleges provide specialized programs and certifications in software development.",
      link:"",
    },
    {
      image: card6,
      heading: "Paying for software developer training",
      description:(<>At Dyashin Technosoft, we are dedicated to the professional development of our team. We offer access to premier software developer training programs that span a broad spectrum of skills and technologies. Our training efforts include practical workshops, online courses, and mentorship programs, ensuring that our employees have the necessary tools to excel. By investing in continuous learning, we enable our team to deliver outstanding results and propel the success of our organization.</>),
      link:"",
    },
    {
      image: card7,
      heading: "Conclusion",
      description:"Upgrading with an uninterrupted education is crucial for attaining success in the tech industry. It enables professionals to stay current with emerging technologies, enhance their problem-solving skills, secure their careers, and build valuable networks. At Dyashin Technosoft, we recognize the importance of ongoing education and are dedicated to providing the best software development courses and software developer training programs to our team. By adopting an attitude of ongoing education, we guarantee that we stay leading in innovation and quality in the tech sector.",
      link:"",
    },
    
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin :  Learn-Tech</title>
        <meta property="og:title" content="The Role of Continuous Learning in Achieving Tech Success" />
        <meta name="description" content="Discover how continuous learning drives tech success, keeping professionals up-to-date with trends, enhancing skills, and fostering innovation in a fast-evolving industry." />
        <meta property="og:description" content="Discover how continuous learning drives tech success, keeping professionals up-to-date with trends, enhancing skills, and fostering innovation in a fast-evolving industry." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={
              "The Role of Continuous Learning in Tech Success"
            }
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={<Grid item xs={12} className="d-flex flex-column align-items-start  justify-content-center " >
              <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
              <FarwarButton icon={<WestArrow />} onBtnClick={() => navigate("/resources/blogs")} />
            </Grid>}
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage21;