import { Box } from "@mui/material";
import React from "react";
import COLORS from "../../../../constants/colorConstants";

const {BLUE1} = COLORS
function Facebook() {
  return (
    <Box>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.334 1H3.64627C2.45555 1 1.49023 1.96527 1.49023 3.15603V14.8439C1.49023 16.0346 2.45551 16.9999 3.64627 16.9999H9.41062L9.42045 11.2824H7.93504C7.742 11.2824 7.58533 11.1263 7.58458 10.9333L7.57746 9.0903C7.57671 8.8962 7.73386 8.73847 7.92796 8.73847H9.41066V6.95767C9.41066 4.89107 10.6728 3.7658 12.5164 3.7658H14.0291C14.2227 3.7658 14.3796 3.92271 14.3796 4.1163V5.67031C14.3796 5.86382 14.2228 6.0207 14.0293 6.02081L13.1009 6.02124C12.0984 6.02124 11.9042 6.49766 11.9042 7.19682V8.73851H14.1072C14.3171 8.73851 14.48 8.9218 14.4553 9.13026L14.2368 10.9732C14.2159 11.1496 14.0664 11.2825 13.8888 11.2825H11.9141L11.9042 17H15.3341C16.5248 17 17.4901 16.0347 17.4901 14.844V3.15603C17.4901 1.96527 16.5248 1 15.334 1Z"
          fill={BLUE1}
        />
      </svg>
    </Box>
  );
}

export default Facebook;
