import HomePageServices from "./HomePageServices";
import CaseStudyCarousel from "../../organisms/CaseStudyCarousel";
import HomePageBlogsAndArticles from "./HomePageBlogsAndArticles";
import PageLayout from "../../organisms/PageLayout";
import HomePageCarousel from "./HomePageCarousel";
import HomePageAboutUs from "./HomePageAboutUs";
import OurProducts from "./OurProducts";
import HomePageYoutube from "./HomePageYoutube";
import TestimonialsCarousel from "../../molecules/TestimonialsCarousel";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function HomePage() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <Helmet>
        <title>Dyashin : Home</title>
        <meta property="og:title" content="Dyashin - Innovative Tech Solutions for Modern Businesses" />
        <meta name="description" content="Dyashin offers cutting-edge technology solutions tailored to empower businesses with efficiency, innovation, and growth. Explore our services today." />
        <meta property="og:description" content="Dyashin offers cutting-edge technology solutions tailored to empower businesses with efficiency, innovation, and growth. Explore our services today." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout>
        <HomePageCarousel />
        <HomePageAboutUs />
        <HomePageServices />
        <OurProducts />
        <HomePageYoutube />
        <TestimonialsCarousel />
        <HomePageBlogsAndArticles />
        <CaseStudyCarousel />
      </PageLayout>
    </>
  );
}
export default HomePage;
