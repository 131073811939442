import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import COLORS from "../../../constants/colorConstants";

const {WHITE3, BLACK4, WHITE1} = COLORS

const ITEM_HEIGHT = 33;
const ITEM_PADDING_TOP = 5;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
      overflowY: "auto",
    },
    sx: {
      "&::-webkit-scrollbar": {
        display: "block",
        backgroundColor: WHITE3,
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: BLACK4,
      },
    },
  },
};

function FilteredDropDown({
  dropDowmName = "",
  handleChange = () => {},
  isGroupSelected = () => {},
  handleGroupSelect = () => {},
  handleItemClick = () => {},
  initialValues = {},
  checkedValues = [],
  isdatapresent = true,
}) {
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="dependent-nested-multiple-checkbox-label">
        {dropDowmName}
      </InputLabel>
      <Select
        labelId="dependent-nested-multiple-checkbox-label"
        id="dependent-nested-multiple-checkbox"
        multiple
        value={checkedValues}
        onChange={handleChange}
        input={<OutlinedInput label={dropDowmName} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {isdatapresent ? (
          <>
            {Object.keys(initialValues).map((group) => (
              <React.Fragment key={group}>
                <MenuItem className="p-0">
                  <Checkbox
                    checked={isGroupSelected(group)}
                    onClick={(event) => handleGroupSelect(event, group)}
                    className="p-0"
                  />
                  <ListItemText primary={group} className="p-0" />
                </MenuItem>
                {initialValues[group].map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{ paddingLeft: 20 }}
                    onClick={(event) => handleItemClick(event, name)}
                    className="p-0 "
                  >
                    <Checkbox
                      checked={checkedValues.includes(name)}
                      className="p-0"
                    />
                    <ListItemText primary={name} className="p-0" />
                  </MenuItem>
                ))}
              </React.Fragment>
            ))}
          </>
        ) : (
          <MenuItem className="p-0">
            <ListItemText
              sx={{
                textAlign: isdatapresent ? "start" : "center",
                bgcolor: WHITE1,
              }}
              primary={"please select soluction"}
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default FilteredDropDown;
