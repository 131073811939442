import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import COLORS from "../../../constants/colorConstants";

const {BLUE2, WHITE1, BLUE11} = COLORS

function SoluctionSoftwareTesting({
  title = "Manual Testing",
  icon = "",
  sxTitle = {},
  onCardclick = () => {},
  index = 0,
  selected = false,
}) {
  const [hover, setHover] = useState(false);

  const cardStyles = {
    cursor: "pointer",
    backgroundColor: selected
      ? BLUE2
      : hover
      ? BLUE11
      : WHITE1,
    transition: "all 0.3s ease",
  };

  const iconColor = selected || hover ? WHITE1 : BLUE2;

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        key={index}
        className="d-flex justify-content-center"
        onClick={() => onCardclick(index)}
      >
        <Grid item xs={12} className="d-flex justify-content-center">
          <Box
            padding={"12px"}
            style={cardStyles}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {icon &&
              React.cloneElement(icon, {
                svgcolor: iconColor,
              })}
          </Box>
        </Grid>
        <Grid item xs={10} className="d-flex justify-content-center">
          <Typography
            className="fw-700 fs-12 py-1 text-center d-flex justify-content-center"
            sx={{ ...sxTitle }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SoluctionSoftwareTesting;
